import {useMutation} from "@apollo/client";
import type {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {
  faBell,
  faFileInvoice,
  faGear,
  faCreditCard,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Center,
  Checkbox,
  Container,
  Fieldset,
  Group,
  PasswordInput,
  SimpleGrid,
  Space,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {ContextModalProps, modals} from "@mantine/modals";
import classNames from "classnames";
import {Formik, FormikErrors, getIn} from "formik";
import {isEmpty} from "lodash";
import Link from "next/link";
import pluralize from "pluralize";
import React, {ReactNode, useState} from "react";

import classes from "./Settings.module.scss";
import config from "../../../config";
import * as mutations from "../../../graphql/mutations";
import type {UserSettings} from "../../../lib/types";
import {getUserSettings} from "../../../lib/user";
import Avatar from "../../Avatar";
import {useAuth} from "../../contexts/AuthContext";
import ErrorAlert from "../../ErrorAlert";

// Available tabs
type Tab = "account" | "communications" | "payment_method" | "invoices";

interface Settings {
  firstName: string;
  lastName: string;
  email: string;
  password?: string;
  passwordConfirmation?: string;
  oldPassword?: string;
  settings: UserSettings;
}

/**
 * User settings dialog
 */
export default function Settings({context, id, innerProps}: ContextModalProps) {
  const {authentication, refresh: refreshAuthState} = useAuth();
  const isSSO = !!authentication.user?.isSso;

  const [lastError, setLastError] = useState<string | null>(null);
  const [updateUserSettings, _updateUserSettingsResult] = useMutation(
    mutations.UPDATE_USER_SETTINGS
  );
  const [tab, setTab] = useState<Tab>("account");

  const tabs: {
    tab: Tab;
    label: string;
    icon: IconDefinition;
    visible: boolean;
  }[] = [
    {tab: "account", label: "Account", icon: faGear, visible: true},
    {
      tab: "communications",
      label: "Communications",
      icon: faBell,
      visible: true,
    },
    {
      tab: "payment_method",
      label: "Payment Method",
      icon: faCreditCard,
      visible: false,
    },
    {tab: "invoices", label: "Invoices", icon: faFileInvoice, visible: false},
  ];

  let tabContent: ReactNode;
  if (tab === "account") {
    tabContent = (
      <Formik<Settings>
        initialValues={{
          firstName: authentication.user?.firstName || "",
          lastName: authentication.user?.lastName || "",
          email: authentication.user?.email || "",
          settings: getUserSettings(authentication.user),
        }}
        validate={values => {
          const errors: FormikErrors<Settings> = {};

          if (!values.firstName) {
            errors.firstName = "First name is required";
          }

          if (!values.lastName) {
            errors.lastName = "Last name is required";
          }

          if (!values.email) {
            errors.email = "Email is required";
          }

          if (values.password) {
            if (values.password !== values.passwordConfirmation) {
              errors.passwordConfirmation = "Passwords don't match";
            }

            if (!values.oldPassword) {
              errors.oldPassword =
                "Current password is required to make changes";
            }
          }

          return errors;
        }}
        onSubmit={async (values, {setSubmitting}) => {
          setLastError(null);

          try {
            await updateUserSettings({
              variables: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                oldPassword: values.oldPassword,
                newPassword: values.password,
                partialSettingsJson: JSON.stringify(values.settings),
              },
            });
            await refreshAuthState();
            context.closeModal(id);
          } catch (e: any) {
            setLastError(e.message);
            setSubmitting(false);
          }
        }}>
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          dirty,
          isValidating,
          isSubmitting,
          submitCount,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container>
              <ErrorAlert message={lastError} />

              {authentication.user && (
                <Group>
                  <Avatar user={authentication.user} size="lg" editable />
                  <Link href="https://gravatar.com" passHref legacyBehavior>
                    <a target="_blank">Change avatar</a>
                  </Link>
                </Group>
              )}

              <br />

              {isSSO && (
                <>
                  <Alert title="SSO Account" color="blue">
                    Some settings here are disabled because this single sign on
                    account is{" "}
                    <a
                      href={`${config.sso.keycloak.serverUrl}/realms/${config.sso.keycloak.realm}/account`}
                      target="_blank">
                      externally managed
                    </a>
                    .
                  </Alert>
                  <br />
                </>
              )}

              <SimpleGrid cols={2}>
                <TextInput
                  name="firstName"
                  label="First name"
                  required
                  disabled={isSSO}
                  error={submitCount > 0 && errors.firstName}
                  value={values.firstName}
                  onChange={handleChange}
                />
                <TextInput
                  name="lastName"
                  label="Last name"
                  required
                  disabled={isSSO}
                  error={submitCount > 0 && errors.lastName}
                  value={values.lastName}
                  onChange={handleChange}
                />
              </SimpleGrid>

              <br />

              <TextInput
                name="email"
                label="Email"
                placeholder="Enter email address"
                required
                disabled={isSSO}
                error={submitCount > 0 && errors.email}
                value={values.email}
                onChange={handleChange}
              />

              <Space h="lg" />

              <Tooltip
                withArrow
                label="If you would like to change your password, you can optionally enter a new one here.">
                <PasswordInput
                  name="password"
                  label="Change password"
                  placeholder="Enter new password"
                  disabled={isSSO}
                  error={submitCount > 0 && errors.password}
                  value={values.password}
                  onChange={handleChange}
                />
              </Tooltip>

              <Space h="xs" />

              {!!values.password && (
                <>
                  <PasswordInput
                    name="passwordConfirmation"
                    label="Confirm new password"
                    placeholder="Enter new password again"
                    error={submitCount > 0 && errors.passwordConfirmation}
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                  />
                  <Space h="xs" />
                </>
              )}

              {!!values.passwordConfirmation && (
                <PasswordInput
                  name="oldPassword"
                  placeholder="Confirm it's really you by entering your old password"
                  label={
                    <span>
                      <strong>Old</strong> password
                    </span>
                  }
                  error={submitCount > 0 && errors.oldPassword}
                  value={values.oldPassword}
                  onChange={handleChange}
                />
              )}

              <Space h="lg" />

              <Fieldset legend="Misc">
                <Group>
                  <Tooltip withArrow label="Skip all the tutorials">
                    <Checkbox
                      name="settings.disable_tutorial"
                      label="Disable tutorials"
                      error={
                        submitCount > 0 &&
                        getIn(errors, "settings.disable_tutorial")
                      }
                      checked={getIn(values, "settings.disable_tutorial")}
                      onChange={async event => {
                        await setFieldValue(
                          "settings.disable_tutorial",
                          event.target.checked
                        );
                        return;
                      }}
                    />
                  </Tooltip>
                  <Button
                    size="xs"
                    variant="default"
                    title="Show all completed tutorials"
                    disabled={
                      getIn(values, "settings.disable_tutorial") ||
                      isEmpty(
                        getIn(values, "settings.completed_tutorials") || []
                      )
                    }
                    onClick={() =>
                      setFieldValue("settings.completed_tutorials", [])
                    }>
                    {`Show ${
                      (getIn(values, "settings.completed_tutorials") || [])
                        .length || ""
                    } hidden ${pluralize(
                      "tutorial",
                      (getIn(values, "settings.completed_tutorials") || [])
                        .length
                    )}`}
                  </Button>
                </Group>

                <Space h="xs" />

                <Tooltip
                  withArrow
                  label="Use the old search engine. May give more accurate results, but it's slower and not recommended.">
                  <Checkbox
                    name="settings.legacy_search"
                    label="Use legacy search engine (slower)"
                    disabled
                    error={submitCount > 0 && errors.settings?.legacy_search}
                    checked={values.settings?.legacy_search}
                    onChange={async event => {
                      if (!event.target.checked) {
                        await setFieldValue("settings.legacy_search", false);
                        return;
                      }

                      modals.openConfirmModal({
                        title: "Legacy Search",
                        children: (
                          <>
                            <Text size="sm">
                              Enabling the legacy search engine will{" "}
                              <strong>drastically slow down</strong> your search
                              speed and increase the load on our systems. Please
                              avoid using this unless you were specifically
                              advised to do so.
                            </Text>
                            <Space h="md" />
                            <Text size="md">
                              <strong>
                                Are you sure you want to enable this option?
                              </strong>
                            </Text>
                          </>
                        ),
                        labels: {confirm: "Yes", cancel: "No"},
                        onConfirm: async () => {
                          await setFieldValue("settings.legacy_search", true);
                        },
                      });
                    }}
                  />
                </Tooltip>
              </Fieldset>
            </Container>

            <Space h="lg" />

            <Center>
              <Button.Group>
                <Tooltip
                  withArrow
                  label={
                    isEmpty(errors) ? "Save settings" : "Please correct errors"
                  }
                  color={isEmpty(errors) ? undefined : "red"}>
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={!dirty || isValidating || isSubmitting}
                    loading={isSubmitting}
                    leftSection={
                      !isSubmitting &&
                      !isEmpty(errors) &&
                      submitCount > 0 && (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          beatFade
                        />
                      )
                    }>
                    Save
                  </Button>
                </Tooltip>
                <Button
                  variant="default"
                  onClick={() => context.closeModal(id)}>
                  Cancel
                </Button>
              </Button.Group>
            </Center>
          </form>
        )}
      </Formik>
    );
  } else if (tab === "communications") {
    tabContent = (
      <Formik<Partial<Settings>>
        initialValues={{
          settings: getUserSettings(authentication.user),
        }}
        onSubmit={async (values, {setSubmitting}) => {
          setLastError(null);

          try {
            await updateUserSettings({
              variables: {
                partialSettingsJson: JSON.stringify(values.settings),
              },
            });
            await refreshAuthState();
            context.closeModal(id);
          } catch (e: any) {
            setLastError(e.message);
            setSubmitting(false);
          }
        }}>
        {({
          handleSubmit,
          values,
          errors,
          dirty,
          isValidating,
          isSubmitting,
          submitCount,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container>
              <ErrorAlert message={lastError} />

              <Checkbox
                name="settings.disable_email_alerts"
                label="Disable email alerts"
                error={
                  submitCount > 0 &&
                  getIn(errors, "settings.disable_email_alerts")
                }
                checked={getIn(values, "settings.disable_email_alerts")}
                onChange={event =>
                  setFieldValue(
                    "settings.disable_email_alerts",
                    event.target.checked
                  )
                }
              />

              <Space h="xs" />

              <Checkbox
                name="settings.unsubscribe"
                label="Unsubscribe from all marketing emails"
                error={submitCount > 0 && getIn(errors, "settings.unsubscribe")}
                checked={getIn(values, "settings.unsubscribe")}
                onChange={async event =>
                  await setFieldValue(
                    "settings.unsubscribe",
                    event.target.checked
                  )
                }
              />
            </Container>

            <Space h="lg" />

            <Center>
              <Button.Group>
                <Tooltip
                  withArrow
                  label={
                    isEmpty(errors) ? "Save settings" : "Please correct errors"
                  }
                  color={isEmpty(errors) ? undefined : "red"}>
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={!dirty || isValidating || isSubmitting}
                    loading={isSubmitting}
                    leftSection={
                      !isSubmitting &&
                      !isEmpty(errors) &&
                      submitCount > 0 && (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          beatFade
                        />
                      )
                    }>
                    Save
                  </Button>
                </Tooltip>
                <Button
                  variant="default"
                  onClick={() => context.closeModal(id)}>
                  Cancel
                </Button>
              </Button.Group>
            </Center>
          </form>
        )}
      </Formik>
    );
  } else if (tab === "payment_method") {
    tabContent = <div>TODO: Payment method</div>;
  } else {
    tabContent = <div>Not implemented</div>;
  }

  return (
    <div className={classes.tabContainer}>
      <nav className={classes.tabNav}>
        {tabs
          .filter(item => item.visible)
          .map(item => (
            <a
              key={item.tab}
              className={classNames(classes.tabItem, {
                [classes.active]: item.tab === tab,
              })}
              onClick={(event: any) => {
                event.preventDefault();
                setTab(item.tab);
              }}>
              {item.icon && (
                <FontAwesomeIcon
                  icon={item.icon}
                  className={classes.tabIcon}
                  title={item.label}
                />
              )}
              <span className={classes.tabLabel}>{item.label}</span>
            </a>
          ))}
      </nav>

      <div className={classes.tabContent}>{tabContent}</div>
    </div>
  );
}

/**
 * Show the settings dialog
 */
export function show() {
  modals.openContextModal({
    modal: "settings",
    title: "Settings",
    size: "lg",
    innerProps: {},
  });
}
