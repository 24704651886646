import {ColorSchemeScript} from "@mantine/core";
import classNames from "classnames";
import Head from "next/head";
import React from "react";

import {APP_NAME} from "../../constants";
import Header from "../Header";
import InstallBanner from "../InstallBanner";
import classes from "./Layout.module.scss";
import {isPWA} from "../../lib/utils";

interface LayoutProps {
  children: React.ReactNode; // Items to render within layout
}

/**
 * Whole site layout
 */
export default function Layout({children}: LayoutProps) {
  return (
    <>
      <Head>
        <title key="title">{APP_NAME}</title>

        <meta httpEquiv="content-type" content="text/html;charset=utf-8" />
        <meta httpEquiv="content-language" content="en" />
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        <meta name="google" content="notranslate" />

        <meta name="twitter:site" content="@campobserver" />

        <link rel="shortcut icon" href="/images/logo.png" />
        <link rel="manifest" href="/manifest.json" />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={APP_NAME} />

        <ColorSchemeScript />
      </Head>

      <Header />

      <main
        className={classNames("content", classes.content)}
        id="content-pane">
        {children}
      </main>

      <InstallBanner />
    </>
  );
}

// Layout.whyDidYouRender = true;
