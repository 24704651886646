import {Alert, Button, Text} from "@mantine/core";
import {useEffect, useMemo, useState} from "react";

import classes from "./InstallBanner.module.scss";
import {useLocalStorage} from "../../lib/storage";

export default function InstallBanner() {
  const [installPromptEvent, setInstallPromptEvent] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [dismissed, setDismissed] = useLocalStorage<boolean>(
    "dismissedInstall",
    false
  );

  const handleBeforeInstallPrompt = (event: any) => {
    // event.preventDefault();
    if (dismissed) {
      return;
    }

    setInstallPromptEvent(event);
    setShow(true);
    event.userChoice.then((_choice: string) => setShow(false));
  };

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    return () =>
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
  }, []);

  if (!show || dismissed) {
    return null;
  }

  // TODO: instructions for firefox: https://addons.mozilla.org/en-US/firefox/addon/pwas-for-firefox/
  // TODO: instructions for ios

  return (
    <Alert
      variant="filled"
      color="white"
      autoContrast
      withCloseButton
      classNames={{
        root: classes.installBanner,
      }}
      onClose={() => setDismissed(true)}>
      <Button
        className="float-right"
        variant="default"
        size="sm"
        onClick={async () => {
          await installPromptEvent.prompt();
          setDismissed(true);
        }}>
        Install
      </Button>
      <Text size="sm">
        Installing provides a quick way to return to this app.
      </Text>
    </Alert>
  );
}
