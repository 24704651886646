import * as amplitude from "@amplitude/analytics-browser";
import {sessionReplayPlugin} from "@amplitude/plugin-session-replay-browser";
import {ApolloProvider} from "@apollo/client";
import {config as fontAwesomeConfig} from "@fortawesome/fontawesome-svg-core";
import {MantineProvider} from "@mantine/core";
import {ModalsProvider} from "@mantine/modals";
import {Notifications} from "@mantine/notifications";
import {NavigationProgress, nprogress} from "@mantine/nprogress";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import type {AppProps} from "next/app";
import App from "next/app";
import {useRouter} from "next/router";
import {useEffect, useState} from "react";
import {CookiesProvider} from "react-cookie";

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);

import "../styles/globals.scss";

fontAwesomeConfig.autoAddCss = false;

import {
  AuthProvider,
  getAuthenticationState,
} from "../components/contexts/AuthContext";
import type {AuthenticationState} from "../components/contexts/AuthContext";
import {MenuProvider} from "../components/contexts/MenuContext";
import {PositionProvider} from "../components/contexts/PositionContext";
import Layout from "../components/Layout";
import AddWatchCredits from "../components/modals/AddWatchCredits/AddWatchCredits";
import Chat from "../components/modals/Chat/Chat";
import CombinedSignIn from "../components/modals/CombinedSignIn/CombinedSignIn";
import Invite from "../components/modals/Invite";
import Settings from "../components/modals/Settings/Settings";
import Wait from "../components/modals/Wait";
import config from "../config";
import client from "../graphql/client";
import {getLogger} from "../lib/logging";
import {useLocalStorage} from "../lib/storage";
import {getParamValue} from "../lib/utils";
import {theme} from "../theme";

const LOG = getLogger(__filename);

LOG.info(
  `Initializing ${process.env.PACKAGE_NAME} v${process.env.PACKAGE_VERSION} (${process.env.ENV})...`,
  {version: process.env.PACKAGE_VERSION, environment: process.env.ENV}
);

// Amplitude
if (
  typeof window !== "undefined" &&
  // @ts-ignore
  typeof window._DATADOG_SYNTHETICS_BROWSER === "undefined" &&
  config.amplitude.apiKey
) {
  const sessionReplayTracking = sessionReplayPlugin();
  amplitude.add(sessionReplayTracking);

  // Initialize Amplitude (but ignore Datadog Synthetics bot and ignore running it on SSR)
  amplitude.init(config.amplitude.apiKey, {
    autocapture: true,
    serverUrl: "/client_events/analytics",
    // logLevel: amplitude.Types.LogLevel.Debug,
  });
}

const MainApp = ({
  Component,
  pageProps,
  authentication,
}: AppProps & {authentication: AuthenticationState}) => {
  const [referral, setReferral] = useLocalStorage<string | null>(
    "referral",
    null
  );
  const router = useRouter();

  useEffect(() => {
    router.events.on("routeChangeStart", _url => nprogress.start());
    router.events.on("routeChangeComplete", () => nprogress.complete());
    router.events.on("routeChangeError", () => nprogress.reset());
  }, [router]);

  // Register service worker (client-only)
  useEffect(() => {
    if (typeof navigator !== "undefined" && "serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/serviceWorker.js")
        .then(() => LOG.debug("Service worker registered"))
        .catch(error => LOG.error(error, "Service worker registration failed"));
    }
  }, []);

  // Referral tracking
  if (!referral && (router.query.utm_source || "").length > 0) {
    const referralCode = getParamValue(router.query.utm_source);
    if (referralCode) {
      setReferral(referralCode);
    }
  }

  return (
    <ApolloProvider client={client}>
      <MantineProvider theme={theme} defaultColorScheme="light">
        <CookiesProvider>
          <AuthProvider client={client} authentication={authentication}>
            <MenuProvider targetId="menu">
              <ModalsProvider
                modals={{
                  chat: Chat,
                  settings: Settings,
                  combinedSignIn: CombinedSignIn,
                  invite: Invite,
                  addWatchCredits: AddWatchCredits,
                  wait: Wait,
                }}>
                <PositionProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </PositionProvider>
              </ModalsProvider>
            </MenuProvider>
            <div id="menu"></div>
            <Notifications />
            <NavigationProgress />
          </AuthProvider>
        </CookiesProvider>
      </MantineProvider>
    </ApolloProvider>
  );
};

MainApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext);
  const authentication = await getAuthenticationState(client, appContext.ctx);

  return {
    ...appProps,
    authentication,
  };
};

// MainApp.whyDidYouRender = true;

export default MainApp;
