import {gql} from "@apollo/client";
import * as Apollo from "@apollo/client";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {[P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  Date: {input: any; output: any};
  DateTime: {input: any; output: any};
  Upload: {input: any; output: any};
};

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  street: Array<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
  zip?: Maybe<Scalars["String"]["output"]>;
};

export type BoundingBox = {
  __typename?: "BoundingBox";
  xmax?: Maybe<Scalars["Float"]["output"]>;
  xmin?: Maybe<Scalars["Float"]["output"]>;
  ymax?: Maybe<Scalars["Float"]["output"]>;
  ymin?: Maybe<Scalars["Float"]["output"]>;
};

export type BoundingBoxInput = {
  xmax?: InputMaybe<Scalars["Float"]["input"]>;
  xmin?: InputMaybe<Scalars["Float"]["input"]>;
  ymax?: InputMaybe<Scalars["Float"]["input"]>;
  ymin?: InputMaybe<Scalars["Float"]["input"]>;
};

export type CmsMutation = {
  __typename?: "CMSMutation";
  create: Content;
  destroy: Content;
  update: Content;
  uploadAttachment: ContentAttachment;
};

export type CmsMutationCreateArgs = {
  input: ContentInput;
};

export type CmsMutationDestroyArgs = {
  contentId: Scalars["Int"]["input"];
};

export type CmsMutationUpdateArgs = {
  input: ContentInput;
};

export type CmsMutationUploadAttachmentArgs = {
  input: ContentAttachmentInput;
};

export type CmsQuery = {
  __typename?: "CMSQuery";
  content?: Maybe<Content>;
  contentList: ContentList;
  slugAvailable: Scalars["Boolean"]["output"];
};

export type CmsQueryContentArgs = {
  contentType: Scalars["String"]["input"];
  slug: Scalars["String"]["input"];
};

export type CmsQueryContentListArgs = {
  input: ContentListInput;
};

export type CmsQuerySlugAvailableArgs = {
  contentType: Scalars["String"]["input"];
  slug: Scalars["String"]["input"];
};

export type ChatMessage = {
  __typename?: "ChatMessage";
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  recipient?: Maybe<User>;
  sender?: Maybe<User>;
  sentAt: Scalars["DateTime"]["output"];
};

export type ChatMessageInput = {
  message: Scalars["String"]["input"];
  recipientId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatMutation = {
  __typename?: "ChatMutation";
  sendMessage: ChatMessage;
};

export type ChatMutationSendMessageArgs = {
  input: ChatMessageInput;
};

export type CheckoutConfig = {
  __typename?: "CheckoutConfig";
  plans: Array<SubscriptionPlan>;
  publishableKey: Scalars["String"]["output"];
};

export type Closure = {
  __typename?: "Closure";
  beginsAt?: Maybe<Scalars["DateTime"]["output"]>;
  endsAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
};

export type Content = {
  __typename?: "Content";
  attachments: Array<ContentAttachment>;
  bodyMarkdown?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  creator: User;
  creatorId: Scalars["Int"]["output"];
  id: Scalars["Int"]["output"];
  isFeatured: Scalars["Boolean"]["output"];
  showCreator: Scalars["Boolean"]["output"];
  slug?: Maybe<Scalars["String"]["output"]>;
  sortOrder?: Maybe<Scalars["Int"]["output"]>;
  status: ContentStatus;
  tagline?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
};

export type ContentAttachment = {
  __typename?: "ContentAttachment";
  contentId: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  dataUrl: Scalars["String"]["output"];
  fileName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  mimeType: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ContentAttachmentInput = {
  contentId: Scalars["Int"]["input"];
  file: Scalars["Upload"]["input"];
  fileName: Scalars["String"]["input"];
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContentInput = {
  bodyMarkdown?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isFeatured?: InputMaybe<Scalars["Boolean"]["input"]>;
  showCreator?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  tagline?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  version?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ContentList = {
  __typename?: "ContentList";
  contentList: Array<Content>;
  pagination: PaginationResults;
};

export type ContentListInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  pagination?: InputMaybe<PaginationInput>;
};

export enum ContentStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
  Inactive = "INACTIVE",
}

export type CreateSubscriptionResult = {
  __typename?: "CreateSubscriptionResult";
  clientSecret: Scalars["String"]["output"];
  customerId: Scalars["String"]["output"];
  planId: Scalars["String"]["output"];
  subscriptionId: Scalars["String"]["output"];
};

export type DateRange = {
  __typename?: "DateRange";
  max?: Maybe<Scalars["Date"]["output"]>;
  min?: Maybe<Scalars["Date"]["output"]>;
};

export type DateRangeInput = {
  max?: InputMaybe<Scalars["Date"]["input"]>;
  min?: InputMaybe<Scalars["Date"]["input"]>;
};

export type EmailTakenInput = {
  captchaResponse: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
};

export type EmailTakenResult = EmailTakenSuccessResult | FormError;

export type EmailTakenSuccessResult = {
  __typename?: "EmailTakenSuccessResult";
  email: Scalars["String"]["output"];
  taken: Scalars["Boolean"]["output"];
};

export type EntitiesMutation = {
  __typename?: "EntitiesMutation";
  checkFacility: Facility;
  deleteReview: Review;
  refreshAddress: Address;
  refreshClosure: Closure;
  refreshFacility: Facility;
  refreshMedia: Media;
  refreshPark: Park;
  refreshUnit: Unit;
  review: ReviewResult;
  updateAddress: Address;
  updateClosure: Closure;
  updateFacility: Facility;
  updateMedia: Media;
  updatePark: Park;
  updateUnit: Unit;
};

export type EntitiesMutationCheckFacilityArgs = {
  beginDate: Scalars["Date"]["input"];
  endDate: Scalars["Date"]["input"];
  facilityId: Scalars["Int"]["input"];
};

export type EntitiesMutationDeleteReviewArgs = {
  reviewId: Scalars["String"]["input"];
};

export type EntitiesMutationRefreshAddressArgs = {
  addressId: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type EntitiesMutationRefreshClosureArgs = {
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
  closureId: Scalars["Int"]["input"];
};

export type EntitiesMutationRefreshFacilityArgs = {
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
  facilityId: Scalars["Int"]["input"];
};

export type EntitiesMutationRefreshMediaArgs = {
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
  mediaId: Scalars["Int"]["input"];
};

export type EntitiesMutationRefreshParkArgs = {
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
  parkId: Scalars["Int"]["input"];
};

export type EntitiesMutationRefreshUnitArgs = {
  clearOverrides?: InputMaybe<Array<Scalars["String"]["input"]>>;
  unitId: Scalars["Int"]["input"];
};

export type EntitiesMutationReviewArgs = {
  input: ReviewInput;
};

export type EntitiesMutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};

export type EntitiesMutationUpdateClosureArgs = {
  input: UpdateClosureInput;
};

export type EntitiesMutationUpdateFacilityArgs = {
  input: UpdateFacilityInput;
};

export type EntitiesMutationUpdateMediaArgs = {
  input: UpdateMediaInput;
};

export type EntitiesMutationUpdateParkArgs = {
  input: UpdateParkInput;
};

export type EntitiesMutationUpdateUnitArgs = {
  input: UpdateUnitInput;
};

export type EntitiesQuery = {
  __typename?: "EntitiesQuery";
  address?: Maybe<Address>;
  closure?: Maybe<Closure>;
  equipment?: Maybe<Equipment>;
  facility?: Maybe<Facility>;
  media?: Maybe<Media>;
  organization?: Maybe<Organization>;
  park?: Maybe<Park>;
  recentlyCheckedFacilities: Array<Facility>;
  slot?: Maybe<Slot>;
  unit?: Maybe<Unit>;
};

export type EntitiesQueryAddressArgs = {
  addressId: Scalars["Int"]["input"];
};

export type EntitiesQueryClosureArgs = {
  closureId: Scalars["Int"]["input"];
};

export type EntitiesQueryEquipmentArgs = {
  equipmentId: Scalars["Int"]["input"];
};

export type EntitiesQueryFacilityArgs = {
  facility: IdOrSlug;
};

export type EntitiesQueryMediaArgs = {
  mediaId: Scalars["Int"]["input"];
};

export type EntitiesQueryOrganizationArgs = {
  organizationId: Scalars["Int"]["input"];
};

export type EntitiesQueryParkArgs = {
  park: IdOrSlug;
};

export type EntitiesQuerySlotArgs = {
  slotId: Scalars["Int"]["input"];
};

export type EntitiesQueryUnitArgs = {
  unit: IdOrSlug;
};

export type Equipment = {
  __typename?: "Equipment";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type Facility = Mapable & {
  __typename?: "Facility";
  addresses: Array<Address>;
  bookingUrl?: Maybe<Scalars["String"]["output"]>;
  checkRequested: Scalars["Boolean"]["output"];
  checkinHour?: Maybe<Scalars["Int"]["output"]>;
  checkoutHour?: Maybe<Scalars["Int"]["output"]>;
  closures: Array<Closure>;
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionHtml?: Maybe<Scalars["String"]["output"]>;
  directions?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  favoritesCount?: Maybe<Scalars["Int"]["output"]>;
  feeDescription?: Maybe<Scalars["String"]["output"]>;
  geometryUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isAdaAccessible?: Maybe<Scalars["Boolean"]["output"]>;
  isReservable?: Maybe<Scalars["Boolean"]["output"]>;
  isShared: Scalars["Boolean"]["output"];
  isWatched: Scalars["Boolean"]["output"];
  lastCheckedAt?: Maybe<Scalars["DateTime"]["output"]>;
  location?: Maybe<Location>;
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  media: Array<Media>;
  name?: Maybe<Scalars["String"]["output"]>;
  nearbyFacilities: Array<FacilityWithDistance>;
  nextCheckRequestAvailableAt?: Maybe<Scalars["DateTime"]["output"]>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars["Int"]["output"]>;
  park?: Maybe<Park>;
  phone?: Maybe<Scalars["String"]["output"]>;
  primaryMedia?: Maybe<Media>;
  rating: Rating;
  reservationUrl?: Maybe<Scalars["String"]["output"]>;
  reviews: ReviewResults;
  rules?: Maybe<Scalars["String"]["output"]>;
  slug?: Maybe<Scalars["String"]["output"]>;
  stayLimit?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  unitCount: Scalars["Int"]["output"];
  units: Array<Unit>;
  updatedAt: Scalars["DateTime"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
};

export type FacilityNearbyFacilitiesArgs = {
  maxMiles?: Scalars["Int"]["input"];
};

export type FacilityReviewsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type FacilityUnitsArgs = {
  input?: InputMaybe<MapSearchInput>;
};

export type FacilityWithDistance = {
  __typename?: "FacilityWithDistance";
  distanceMiles: Scalars["Float"]["output"];
  facility: Facility;
};

export type FeaturesQuery = {
  __typename?: "FeaturesQuery";
  isEnabled: Scalars["Boolean"]["output"];
};

export type FeaturesQueryIsEnabledArgs = {
  flag: Scalars["String"]["input"];
};

export type Filter = {
  __typename?: "Filter";
  bounds?: Maybe<BoundingBox>;
  dates?: Maybe<DateRange>;
  drivewayLengthFeet?: Maybe<Scalars["Int"]["output"]>;
  electricityHookupAmps?: Maybe<IntRange>;
  excludeFCFS: Scalars["Boolean"]["output"];
  excludeGroup: Scalars["Boolean"]["output"];
  excludeMandatoryEquipment: Scalars["Boolean"]["output"];
  excludeUnreleased: Scalars["Boolean"]["output"];
  isStrict: Scalars["Boolean"]["output"];
  maxVehicleLengthFeet?: Maybe<Scalars["Int"]["output"]>;
  nights?: Maybe<Scalars["Int"]["output"]>;
  onlyAccessible: Scalars["Boolean"]["output"];
  onlyFirePit?: Maybe<Scalars["Boolean"]["output"]>;
  onlyFiresAllowed: Scalars["Boolean"]["output"];
  onlyGroup?: Maybe<Scalars["Boolean"]["output"]>;
  onlyPetsAllowed: Scalars["Boolean"]["output"];
  onlyPicnicTable?: Maybe<Scalars["Boolean"]["output"]>;
  onlySewerHookup?: Maybe<Scalars["Boolean"]["output"]>;
  onlyTypes?: Maybe<Array<Scalars["String"]["output"]>>;
  onlyWaterHookup?: Maybe<Scalars["Boolean"]["output"]>;
  people?: Maybe<IntRange>;
  vehicles?: Maybe<Scalars["Int"]["output"]>;
};

export type FilterInput = {
  bounds?: InputMaybe<BoundingBoxInput>;
  dates?: InputMaybe<DateRangeInput>;
  drivewayLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  electricityHookupAmps?: InputMaybe<IntRangeInput>;
  excludeFCFS?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeMandatoryEquipment?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeUnreleased?: InputMaybe<Scalars["Boolean"]["input"]>;
  isStrict?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxVehicleLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  nights?: InputMaybe<Scalars["Int"]["input"]>;
  onlyAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyFirePit?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyFiresAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyPetsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyPicnicTable?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlySewerHookup?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  onlyWaterHookup?: InputMaybe<Scalars["Boolean"]["input"]>;
  people?: InputMaybe<IntRangeInput>;
  vehicles?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FormError = {
  __typename?: "FormError";
  field?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type IdOrSlug = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type IntRange = {
  __typename?: "IntRange";
  max?: Maybe<Scalars["Int"]["output"]>;
  min?: Maybe<Scalars["Int"]["output"]>;
};

export type IntRangeInput = {
  max?: InputMaybe<Scalars["Int"]["input"]>;
  min?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InvitationDefaults = {
  __typename?: "InvitationDefaults";
  email: Scalars["String"]["output"];
};

export type InvitationInput = {
  captchaResponse: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type InvitationResult = FormError | InvitationSuccessResult;

export type InvitationSuccessResult = {
  __typename?: "InvitationSuccessResult";
  email: Scalars["String"]["output"];
  inviteeWatchCredits?: Maybe<Scalars["Int"]["output"]>;
  inviterWatchCredits?: Maybe<Scalars["Int"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type Location = {
  __typename?: "Location";
  accuracyMeters?: Maybe<Scalars["Float"]["output"]>;
  latitude?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
};

export type LocationInput = {
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
};

export type MapCluster = Mapable &
  SearchMapable & {
    __typename?: "MapCluster";
    count: Scalars["Int"]["output"];
    expansionZoom: Scalars["Int"]["output"];
    geometryUrl?: Maybe<Scalars["String"]["output"]>;
    isMatch: Scalars["Boolean"]["output"];
    isShared: Scalars["Boolean"]["output"];
    isWatched: Scalars["Boolean"]["output"];
    location?: Maybe<Location>;
    name?: Maybe<Scalars["String"]["output"]>;
    organizationId?: Maybe<Scalars["Int"]["output"]>;
  };

export type MapSearchInput = {
  cluster?: Scalars["Boolean"]["input"];
  filter?: InputMaybe<FilterInput>;
  includeBlmBoundaries?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeUsfsBoundaries?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyMatches?: InputMaybe<Scalars["Boolean"]["input"]>;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<SortOptionInput>;
  term?: InputMaybe<Scalars["String"]["input"]>;
  zoom?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MapSearchResults = {
  __typename?: "MapSearchResults";
  pagination: PaginationResults;
  results: Array<SearchResultMapCluster>;
};

export type Mapable = {
  geometryUrl?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Location>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationId?: Maybe<Scalars["Int"]["output"]>;
};

export type Media = {
  __typename?: "Media";
  credits?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  isActive: Scalars["Boolean"]["output"];
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  chat: ChatMutation;
  cms: CmsMutation;
  entities: EntitiesMutation;
  subscriptions: SubscriptionsMutation;
  uploads: UploadsMutation;
  users: UsersMutation;
  watches: WatchesMutation;
};

export type Organization = {
  __typename?: "Organization";
  addresses: Array<Address>;
  facilities: Array<Facility>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  parks: Array<Park>;
  updatedAt: Scalars["DateTime"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
};

export type PaginationInput = {
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaginationResults = {
  __typename?: "PaginationResults";
  page: Scalars["Int"]["output"];
  pageSize: Scalars["Int"]["output"];
  totalResults: Scalars["Int"]["output"];
};

export type Park = Mapable & {
  __typename?: "Park";
  addresses: Array<Address>;
  closures: Array<Closure>;
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionHtml?: Maybe<Scalars["String"]["output"]>;
  directions?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  facilities: Array<Facility>;
  favoritesCount?: Maybe<Scalars["Int"]["output"]>;
  feeDescription?: Maybe<Scalars["String"]["output"]>;
  geometryUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isReservable?: Maybe<Scalars["Boolean"]["output"]>;
  isShared: Scalars["Boolean"]["output"];
  isWatched: Scalars["Boolean"]["output"];
  location?: Maybe<Location>;
  mapUrl?: Maybe<Scalars["String"]["output"]>;
  media: Array<Media>;
  name?: Maybe<Scalars["String"]["output"]>;
  nearbyParks: Array<ParkWithDistance>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars["Int"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  primaryMedia?: Maybe<Media>;
  rating: Rating;
  reservationUrl?: Maybe<Scalars["String"]["output"]>;
  reviews: ReviewResults;
  slug?: Maybe<Scalars["String"]["output"]>;
  stayLimit?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
};

export type ParkNearbyParksArgs = {
  maxMiles?: Scalars["Int"]["input"];
};

export type ParkReviewsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type ParkWithDistance = {
  __typename?: "ParkWithDistance";
  distanceMiles: Scalars["Float"]["output"];
  park: Park;
};

export enum Permissions {
  Admin = "ADMIN",
}

export type Place = Mapable & {
  __typename?: "Place";
  geometryUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  isShared: Scalars["Boolean"]["output"];
  isWatched: Scalars["Boolean"]["output"];
  location?: Maybe<Location>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationId?: Maybe<Scalars["Int"]["output"]>;
  parent?: Maybe<Place>;
  placeType: Scalars["String"]["output"];
  polygon?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  cms: CmsQuery;
  entities: EntitiesQuery;
  features: FeaturesQuery;
  search: SearchQuery;
  subscriptions: SubscriptionsQuery;
  users: UsersQuery;
  watches: WatchesQuery;
};

export type Rate = {
  __typename?: "Rate";
  displayFees?: Maybe<Scalars["String"]["output"]>;
  displayRate?: Maybe<Scalars["String"]["output"]>;
  fees?: Maybe<Scalars["Float"]["output"]>;
  perNight?: Maybe<Scalars["Float"]["output"]>;
  perPerson?: Maybe<Scalars["Float"]["output"]>;
};

export type Rating = {
  __typename?: "Rating";
  average?: Maybe<Scalars["Float"]["output"]>;
  count: Scalars["Int"]["output"];
  distribution: Array<RatingCount>;
};

export type RatingCount = {
  __typename?: "RatingCount";
  count: Scalars["Int"]["output"];
  rating: Scalars["Int"]["output"];
};

export type ResetPasswordInput = {
  newPassword: Scalars["String"]["input"];
  newPasswordConfirmation: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type ResetPasswordRequestInput = {
  captchaResponse: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
};

export type ResetPasswordResult = FormError | ResetPasswordSuccessResult;

export type ResetPasswordSuccessResult = {
  __typename?: "ResetPasswordSuccessResult";
  email: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type Review = {
  __typename?: "Review";
  comment?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  facility?: Maybe<Facility>;
  facilityId?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["String"]["output"];
  park?: Maybe<Park>;
  parkId?: Maybe<Scalars["Int"]["output"]>;
  rating?: Maybe<Scalars["Int"]["output"]>;
  remoteReviewerId?: Maybe<Scalars["String"]["output"]>;
  remoteReviewerName?: Maybe<Scalars["String"]["output"]>;
  reviewer?: Maybe<User>;
  reviewerId?: Maybe<Scalars["Int"]["output"]>;
  reviewerName?: Maybe<Scalars["String"]["output"]>;
  unit?: Maybe<Unit>;
  unitId?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type ReviewInput = {
  comment?: InputMaybe<Scalars["String"]["input"]>;
  facilityId?: InputMaybe<Scalars["Int"]["input"]>;
  parkId?: InputMaybe<Scalars["Int"]["input"]>;
  rating?: InputMaybe<Scalars["Int"]["input"]>;
  unitId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ReviewResult = FormError | Review;

export type ReviewResults = {
  __typename?: "ReviewResults";
  pagination: PaginationResults;
  reviews: Array<Review>;
};

export type SearchMapable = {
  geometryUrl?: Maybe<Scalars["String"]["output"]>;
  isMatch: Scalars["Boolean"]["output"];
  isShared: Scalars["Boolean"]["output"];
  isWatched: Scalars["Boolean"]["output"];
  location?: Maybe<Location>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationId?: Maybe<Scalars["Int"]["output"]>;
};

export type SearchQuery = {
  __typename?: "SearchQuery";
  mapSearch: MapSearchResults;
  maxDate?: Maybe<Scalars["Date"]["output"]>;
  textSearch: TextSearchResults;
  textSearchResultLocation?: Maybe<Location>;
};

export type SearchQueryMapSearchArgs = {
  input: MapSearchInput;
};

export type SearchQueryTextSearchArgs = {
  input: TextSearchInput;
};

export type SearchQueryTextSearchResultLocationArgs = {
  correlationKey?: InputMaybe<Scalars["String"]["input"]>;
  mapableId: Scalars["String"]["input"];
  mapableType: Scalars["String"]["input"];
};

export type SearchResult = Mapable &
  SearchMapable & {
    __typename?: "SearchResult";
    context?: Maybe<Scalars["String"]["output"]>;
    geometryUrl?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["String"]["output"];
    isMatch: Scalars["Boolean"]["output"];
    isShared: Scalars["Boolean"]["output"];
    isWatched: Scalars["Boolean"]["output"];
    location?: Maybe<Location>;
    name?: Maybe<Scalars["String"]["output"]>;
    organizationId?: Maybe<Scalars["Int"]["output"]>;
    result?: Maybe<SearchResultType>;
    slug?: Maybe<Scalars["String"]["output"]>;
    subtype?: Maybe<Scalars["String"]["output"]>;
    type: Scalars["String"]["output"];
    updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  };

export type SearchResultMapCluster = MapCluster | SearchResult;

export type SearchResultType = Facility | Park | Place | Unit;

export type SignUpInput = {
  acceptedTerms: Scalars["Boolean"]["input"];
  captchaResponse: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  lastName: Scalars["String"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
  referral?: InputMaybe<Scalars["String"]["input"]>;
};

export type SignUpResult = FormError | SignUpSuccessResult;

export type SignUpSuccessResult = {
  __typename?: "SignUpSuccessResult";
  email: Scalars["String"]["output"];
  userId: Scalars["Int"]["output"];
};

export type Slot = {
  __typename?: "Slot";
  id?: Maybe<Scalars["Int"]["output"]>;
  rate: Rate;
  reservableAt?: Maybe<Scalars["DateTime"]["output"]>;
  slotDate?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<SlotStatus>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
};

export enum SlotStatus {
  Available = "AVAILABLE",
  Closed = "CLOSED",
  Lottery = "LOTTERY",
  NotReservable = "NOT_RESERVABLE",
  Reserved = "RESERVED",
  Unavailable = "UNAVAILABLE",
  Unreleased = "UNRELEASED",
}

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type SortOptionInput = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<Scalars["String"]["input"]>;
};

export type Subscription = {
  __typename?: "Subscription";
  chatMessages: ChatMessage;
  count: Scalars["Int"]["output"];
  updates: Update;
};

export type SubscriptionCountArgs = {
  target?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SubscriptionUpdatesArgs = {
  inputs: Array<UpdateInput>;
};

export type SubscriptionPlan = {
  __typename?: "SubscriptionPlan";
  active: Scalars["Boolean"]["output"];
  amount: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  interval: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  order?: Maybe<Scalars["Int"]["output"]>;
};

export type SubscriptionsMutation = {
  __typename?: "SubscriptionsMutation";
  cancel: Scalars["Boolean"]["output"];
  create: CreateSubscriptionResult;
};

export type SubscriptionsMutationCancelArgs = {
  subscriptionId: Scalars["String"]["input"];
};

export type SubscriptionsMutationCreateArgs = {
  planId: Scalars["String"]["input"];
};

export type SubscriptionsQuery = {
  __typename?: "SubscriptionsQuery";
  calculateUsedCredits: Scalars["Int"]["output"];
  checkoutConfig: CheckoutConfig;
};

export type SubscriptionsQueryCalculateUsedCreditsArgs = {
  facilityIds: Array<Scalars["Int"]["input"]>;
  parkIds: Array<Scalars["Int"]["input"]>;
  unitIds: Array<Scalars["Int"]["input"]>;
};

export type TextSearchInput = {
  correlationKey?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<FilterInput>;
  near?: InputMaybe<LocationInput>;
  pagination?: InputMaybe<PaginationInput>;
  radiusMeters?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<SortOptionInput>;
  term?: InputMaybe<Scalars["String"]["input"]>;
};

export type TextSearchResults = {
  __typename?: "TextSearchResults";
  correlationKey?: Maybe<Scalars["String"]["output"]>;
  pagination: PaginationResults;
  results: Array<SearchResult>;
};

export type Unit = Mapable & {
  __typename?: "Unit";
  allowedEquipment: Array<Equipment>;
  bookingUrl?: Maybe<Scalars["String"]["output"]>;
  closures: Array<Closure>;
  drivewayLengthFeet?: Maybe<Scalars["Int"]["output"]>;
  electricityHookupAmps?: Maybe<Scalars["Int"]["output"]>;
  facility?: Maybe<Facility>;
  favoritesCount?: Maybe<Scalars["Int"]["output"]>;
  firePit?: Maybe<Scalars["Boolean"]["output"]>;
  geometryUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive: Scalars["Boolean"]["output"];
  isAdaAccessible?: Maybe<Scalars["Boolean"]["output"]>;
  isEquipmentMandatory?: Maybe<Scalars["Boolean"]["output"]>;
  isGroup?: Maybe<Scalars["Boolean"]["output"]>;
  isReservable?: Maybe<Scalars["Boolean"]["output"]>;
  isShared: Scalars["Boolean"]["output"];
  isWatched: Scalars["Boolean"]["output"];
  location?: Maybe<Location>;
  loopName?: Maybe<Scalars["String"]["output"]>;
  maxVehicleLengthFeet?: Maybe<Scalars["Int"]["output"]>;
  maxVehicles?: Maybe<Scalars["Int"]["output"]>;
  media: Array<Media>;
  name?: Maybe<Scalars["String"]["output"]>;
  nearbyUnits: Array<Unit>;
  organizationId?: Maybe<Scalars["Int"]["output"]>;
  peopleCapacity?: Maybe<IntRange>;
  petsAllowed?: Maybe<Scalars["Boolean"]["output"]>;
  picnicTable?: Maybe<Scalars["Boolean"]["output"]>;
  primaryMedia?: Maybe<Media>;
  rating: Rating;
  reviews: ReviewResults;
  rules?: Maybe<Scalars["String"]["output"]>;
  sewerHookup?: Maybe<Scalars["Boolean"]["output"]>;
  slots: Array<Slot>;
  slug?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  useType?: Maybe<Scalars["String"]["output"]>;
  version: Scalars["Int"]["output"];
  waterHookup?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UnitReviewsArgs = {
  pagination?: InputMaybe<PaginationInput>;
};

export type UnitSlotsArgs = {
  input?: InputMaybe<UnitSlotsInput>;
};

export type UnitSlotsInput = {
  beginDate: Scalars["Date"]["input"];
  endDate: Scalars["Date"]["input"];
};

export type Update = {
  __typename?: "Update";
  model?: Maybe<SearchResultType>;
  modelClass: Scalars["String"]["output"];
  modelId: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type UpdateAddressInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  street?: InputMaybe<Array<Scalars["String"]["input"]>>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateClosureInput = {
  beginsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UpdateFacilityInput = {
  bookingUrl?: InputMaybe<Scalars["String"]["input"]>;
  checkinHour?: InputMaybe<Scalars["Int"]["input"]>;
  checkoutHour?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  feeDescription?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAdaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<LocationInput>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  numSites?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  parkId?: InputMaybe<Scalars["Int"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  reservationUrl?: InputMaybe<Scalars["String"]["input"]>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  stayLimit?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateInput = {
  modelClass: Scalars["String"]["input"];
  modelId: Scalars["String"]["input"];
};

export type UpdateMediaInput = {
  credits?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UpdateParkInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  feeDescription?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<LocationInput>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  reservationUrl?: InputMaybe<Scalars["String"]["input"]>;
  stayLimit?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UpdateUnitInput = {
  allowedEquipmentIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  facilityId?: InputMaybe<Scalars["Int"]["input"]>;
  id: Scalars["Int"]["input"];
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAdaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEquipmentMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<LocationInput>;
  loopName?: InputMaybe<Scalars["String"]["input"]>;
  maxVehicleLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  maxVehicles?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  peopleCapacity?: InputMaybe<IntRangeInput>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  useType?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserSettingsInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  newPassword?: InputMaybe<Scalars["String"]["input"]>;
  oldPassword?: InputMaybe<Scalars["String"]["input"]>;
  partialSettingsJson?: InputMaybe<Scalars["String"]["input"]>;
};

export type UploadMappingResult = {
  __typename?: "UploadMappingResult";
  fileFormat?: Maybe<Scalars["String"]["output"]>;
  fileName: Scalars["String"]["output"];
  rows: Array<UploadMappingRow>;
  uploadId: Scalars["String"]["output"];
};

export type UploadMappingRow = Mapable & {
  __typename?: "UploadMappingRow";
  description?: Maybe<Scalars["String"]["output"]>;
  geometryUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  location?: Maybe<Location>;
  name?: Maybe<Scalars["String"]["output"]>;
  organizationId?: Maybe<Scalars["Int"]["output"]>;
  unit?: Maybe<Unit>;
};

export type UploadsMutation = {
  __typename?: "UploadsMutation";
  uploadMapping: UploadMappingResult;
};

export type UploadsMutationUploadMappingArgs = {
  file: Scalars["Upload"]["input"];
  name: Scalars["String"]["input"];
};

export type User = {
  __typename?: "User";
  avatarImageUrl?: Maybe<Scalars["String"]["output"]>;
  displayName: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  favorites?: Maybe<UserFavorites>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  isSso?: Maybe<Scalars["Boolean"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  maxWatchCredits: Scalars["Int"]["output"];
  permissions?: Maybe<Array<Permissions>>;
  roles?: Maybe<Array<Scalars["String"]["output"]>>;
  settingsJson?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<UserStatus>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  usedWatchCredits: Scalars["Int"]["output"];
  version?: Maybe<Scalars["Int"]["output"]>;
};

export type UserAvatarImageUrlArgs = {
  size?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserFavorites = {
  __typename?: "UserFavorites";
  facilities: Array<Facility>;
  parks: Array<Park>;
  units: Array<Unit>;
};

export enum UserStatus {
  Active = "ACTIVE",
  Pending = "PENDING",
  Suspended = "SUSPENDED",
}

export type UsersMutation = {
  __typename?: "UsersMutation";
  completeTutorial: User;
  invite: InvitationResult;
  resetPassword: ResetPasswordResult;
  resetPasswordRequest: ResetPasswordResult;
  signUp: SignUpResult;
  updateSettings: User;
};

export type UsersMutationCompleteTutorialArgs = {
  tutorialId: Scalars["String"]["input"];
};

export type UsersMutationInviteArgs = {
  input: InvitationInput;
};

export type UsersMutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type UsersMutationResetPasswordRequestArgs = {
  input: ResetPasswordRequestInput;
};

export type UsersMutationSignUpArgs = {
  input: SignUpInput;
};

export type UsersMutationUpdateSettingsArgs = {
  input: UpdateUserSettingsInput;
};

export type UsersQuery = {
  __typename?: "UsersQuery";
  allUsers: Array<User>;
  currentUser?: Maybe<User>;
  emailTaken: EmailTakenResult;
  invitationDefaults: InvitationDefaults;
  location?: Maybe<Location>;
  user?: Maybe<User>;
};

export type UsersQueryEmailTakenArgs = {
  input: EmailTakenInput;
};

export type UsersQueryInvitationDefaultsArgs = {
  token: Scalars["String"]["input"];
};

export type UsersQueryUserArgs = {
  userId: Scalars["Int"]["input"];
};

export type Watch = {
  __typename?: "Watch";
  alertsEnabled: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  creator: User;
  displayName: Scalars["String"]["output"];
  effectiveFacilities: Array<Facility>;
  effectiveFacilityIds: Array<Scalars["Int"]["output"]>;
  effectiveUnitIds: Array<Scalars["Int"]["output"]>;
  effectiveUnits: Array<Unit>;
  expiresAt?: Maybe<Scalars["DateTime"]["output"]>;
  facilities: Array<Facility>;
  filter: Filter;
  foundFacilities: Array<Facility>;
  foundUnits: Array<Unit>;
  id: Scalars["Int"]["output"];
  lastScannedAt?: Maybe<Scalars["DateTime"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  parks: Array<Park>;
  scheduleDescription: Scalars["String"]["output"];
  status: WatchStatus;
  units: Array<Unit>;
  updatedAt: Scalars["DateTime"]["output"];
  version: Scalars["Int"]["output"];
};

export type WatchInput = {
  alertsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  facilityIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  filter: FilterInput;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parkIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  unitIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export enum WatchStatus {
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Inactive = "INACTIVE",
  Paused = "PAUSED",
  Triggered = "TRIGGERED",
}

export type WatchesMutation = {
  __typename?: "WatchesMutation";
  cancel: Watch;
  create: Watch;
  update: Watch;
};

export type WatchesMutationCancelArgs = {
  watchId: Scalars["Int"]["input"];
};

export type WatchesMutationCreateArgs = {
  input: WatchInput;
};

export type WatchesMutationUpdateArgs = {
  input: WatchInput;
  unsetFields?: InputMaybe<Array<Scalars["String"]["input"]>>;
  watchId: Scalars["Int"]["input"];
};

export type WatchesQuery = {
  __typename?: "WatchesQuery";
  nameAvailable: Scalars["Boolean"]["output"];
  watch: Watch;
  watchList: Array<Watch>;
};

export type WatchesQueryNameAvailableArgs = {
  name: Scalars["String"]["input"];
};

export type WatchesQueryWatchArgs = {
  watchId: Scalars["Int"]["input"];
};

export type WatchesQueryWatchListArgs = {
  status?: InputMaybe<WatchStatus>;
};

export type PaginationFieldsFragment = {
  __typename?: "PaginationResults";
  page: number;
  pageSize: number;
  totalResults: number;
};

export type AddressFieldsFragment = {
  __typename?: "Address";
  id: number;
  type?: string | null;
  street: Array<string>;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
};

export type RatingFieldsFragment = {
  __typename?: "Rating";
  average?: number | null;
  count: number;
  distribution: Array<{
    __typename?: "RatingCount";
    rating: number;
    count: number;
  }>;
};

export type ClosureFieldsFragment = {
  __typename?: "Closure";
  id: number;
  beginsAt?: any | null;
  endsAt?: any | null;
  reason?: string | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
};

export type EquipmentFieldsFragment = {
  __typename?: "Equipment";
  id: number;
  name: string;
  description?: string | null;
  isActive: boolean;
  updatedAt: any;
};

export type MediaFieldsFragment = {
  __typename?: "Media";
  id?: number | null;
  type?: string | null;
  title?: string | null;
  subtitle?: string | null;
  description?: string | null;
  credits?: string | null;
  url?: string | null;
  width?: number | null;
  height?: number | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
};

export type MediaMarkerFieldsFragment = {
  __typename?: "Media";
  id?: number | null;
  title?: string | null;
  url?: string | null;
  width?: number | null;
  height?: number | null;
};

export type FacilityMarkerFieldsFragment = {
  __typename?: "Facility";
  id: number;
  slug?: string | null;
  name?: string | null;
  checkRequested: boolean;
  nextCheckRequestAvailableAt?: any | null;
  lastCheckedAt?: any | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    title?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
};

export type UnitMarkerFieldsFragment = {
  __typename?: "Unit";
  id: number;
  slug?: string | null;
  name?: string | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    title?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
  facility?: {
    __typename?: "Facility";
    id: number;
    slug?: string | null;
    name?: string | null;
    checkRequested: boolean;
    nextCheckRequestAvailableAt?: any | null;
    lastCheckedAt?: any | null;
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    primaryMedia?: {
      __typename?: "Media";
      id?: number | null;
      title?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
    } | null;
  } | null;
};

export type UnitFieldsFragment = {
  __typename?: "Unit";
  id: number;
  slug?: string | null;
  type?: string | null;
  useType?: string | null;
  name?: string | null;
  loopName?: string | null;
  bookingUrl?: string | null;
  rules?: string | null;
  isGroup?: boolean | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  petsAllowed?: boolean | null;
  firePit?: boolean | null;
  picnicTable?: boolean | null;
  waterHookup?: boolean | null;
  sewerHookup?: boolean | null;
  electricityHookupAmps?: number | null;
  drivewayLengthFeet?: number | null;
  maxVehicles?: number | null;
  maxVehicleLengthFeet?: number | null;
  favoritesCount?: number | null;
  isEquipmentMandatory?: boolean | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  peopleCapacity?: {
    __typename?: "IntRange";
    min?: number | null;
    max?: number | null;
  } | null;
  facility?: {
    __typename?: "Facility";
    id: number;
    slug?: string | null;
    name?: string | null;
    park?: {
      __typename?: "Park";
      id: number;
      slug?: string | null;
      name?: string | null;
    } | null;
  } | null;
  allowedEquipment: Array<{
    __typename?: "Equipment";
    id: number;
    name: string;
    description?: string | null;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
  rating: {
    __typename?: "Rating";
    average?: number | null;
    count: number;
    distribution: Array<{
      __typename?: "RatingCount";
      rating: number;
      count: number;
    }>;
  };
};

export type FacilityUnitFieldsFragment = {
  __typename?: "Unit";
  id: number;
  slug?: string | null;
  type?: string | null;
  useType?: string | null;
  name?: string | null;
  loopName?: string | null;
  bookingUrl?: string | null;
  rules?: string | null;
  isGroup?: boolean | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  petsAllowed?: boolean | null;
  maxVehicles?: number | null;
  maxVehicleLengthFeet?: number | null;
  isEquipmentMandatory?: boolean | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  peopleCapacity?: {
    __typename?: "IntRange";
    min?: number | null;
    max?: number | null;
  } | null;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
};

export type FacilityCommonFieldsFragment = {
  __typename?: "Facility";
  id: number;
  slug?: string | null;
  type?: string | null;
  name?: string | null;
  url?: string | null;
  bookingUrl?: string | null;
  reservationUrl?: string | null;
  mapUrl?: string | null;
  checkinHour?: number | null;
  checkoutHour?: number | null;
  description?: string | null;
  descriptionHtml?: string | null;
  feeDescription?: string | null;
  rules?: string | null;
  directions?: string | null;
  stayLimit?: string | null;
  phone?: string | null;
  email?: string | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  favoritesCount?: number | null;
  unitCount: number;
  isWatched: boolean;
  isShared: boolean;
  version: number;
  isActive: boolean;
  updatedAt: any;
  checkRequested: boolean;
  nextCheckRequestAvailableAt?: any | null;
  lastCheckedAt?: any | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
  rating: {
    __typename?: "Rating";
    average?: number | null;
    count: number;
    distribution: Array<{
      __typename?: "RatingCount";
      rating: number;
      count: number;
    }>;
  };
};

export type FacilityFieldsFragment = {
  __typename?: "Facility";
  id: number;
  slug?: string | null;
  type?: string | null;
  name?: string | null;
  url?: string | null;
  bookingUrl?: string | null;
  reservationUrl?: string | null;
  mapUrl?: string | null;
  checkinHour?: number | null;
  checkoutHour?: number | null;
  description?: string | null;
  descriptionHtml?: string | null;
  feeDescription?: string | null;
  rules?: string | null;
  directions?: string | null;
  stayLimit?: string | null;
  phone?: string | null;
  email?: string | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  favoritesCount?: number | null;
  unitCount: number;
  isWatched: boolean;
  isShared: boolean;
  version: number;
  isActive: boolean;
  updatedAt: any;
  checkRequested: boolean;
  nextCheckRequestAvailableAt?: any | null;
  lastCheckedAt?: any | null;
  units: Array<{
    __typename?: "Unit";
    id: number;
    slug?: string | null;
    type?: string | null;
    useType?: string | null;
    name?: string | null;
    loopName?: string | null;
    bookingUrl?: string | null;
    rules?: string | null;
    isGroup?: boolean | null;
    isReservable?: boolean | null;
    isAdaAccessible?: boolean | null;
    petsAllowed?: boolean | null;
    maxVehicles?: number | null;
    maxVehicleLengthFeet?: number | null;
    isEquipmentMandatory?: boolean | null;
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    peopleCapacity?: {
      __typename?: "IntRange";
      min?: number | null;
      max?: number | null;
    } | null;
    primaryMedia?: {
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    } | null;
  }>;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
  rating: {
    __typename?: "Rating";
    average?: number | null;
    count: number;
    distribution: Array<{
      __typename?: "RatingCount";
      rating: number;
      count: number;
    }>;
  };
};

export type FacilityWithoutUnitsFieldsFragment = {
  __typename?: "Facility";
  unitCount: number;
  id: number;
  slug?: string | null;
  type?: string | null;
  name?: string | null;
  url?: string | null;
  bookingUrl?: string | null;
  reservationUrl?: string | null;
  mapUrl?: string | null;
  checkinHour?: number | null;
  checkoutHour?: number | null;
  description?: string | null;
  descriptionHtml?: string | null;
  feeDescription?: string | null;
  rules?: string | null;
  directions?: string | null;
  stayLimit?: string | null;
  phone?: string | null;
  email?: string | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  favoritesCount?: number | null;
  isWatched: boolean;
  isShared: boolean;
  version: number;
  isActive: boolean;
  updatedAt: any;
  checkRequested: boolean;
  nextCheckRequestAvailableAt?: any | null;
  lastCheckedAt?: any | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
  rating: {
    __typename?: "Rating";
    average?: number | null;
    count: number;
    distribution: Array<{
      __typename?: "RatingCount";
      rating: number;
      count: number;
    }>;
  };
};

export type OrganizationFieldsFragment = {
  __typename?: "Organization";
  id: number;
  name: string;
  url?: string | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
};

export type ParkMarkerFieldsFragment = {
  __typename?: "Park";
  id: number;
  slug?: string | null;
  name?: string | null;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    title?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
  } | null;
};

export type ParkFieldsFragment = {
  __typename?: "Park";
  id: number;
  slug?: string | null;
  name?: string | null;
  description?: string | null;
  descriptionHtml?: string | null;
  feeDescription?: string | null;
  directions?: string | null;
  stayLimit?: string | null;
  phone?: string | null;
  email?: string | null;
  reservationUrl?: string | null;
  mapUrl?: string | null;
  isReservable?: boolean | null;
  geometryUrl?: string | null;
  organizationId?: number | null;
  favoritesCount?: number | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
  facilities: Array<{
    __typename?: "Facility";
    unitCount: number;
    id: number;
    slug?: string | null;
    type?: string | null;
    name?: string | null;
    url?: string | null;
    bookingUrl?: string | null;
    reservationUrl?: string | null;
    mapUrl?: string | null;
    checkinHour?: number | null;
    checkoutHour?: number | null;
    description?: string | null;
    descriptionHtml?: string | null;
    feeDescription?: string | null;
    rules?: string | null;
    directions?: string | null;
    stayLimit?: string | null;
    phone?: string | null;
    email?: string | null;
    isReservable?: boolean | null;
    isAdaAccessible?: boolean | null;
    favoritesCount?: number | null;
    isWatched: boolean;
    isShared: boolean;
    version: number;
    isActive: boolean;
    updatedAt: any;
    checkRequested: boolean;
    nextCheckRequestAvailableAt?: any | null;
    lastCheckedAt?: any | null;
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    addresses: Array<{
      __typename?: "Address";
      id: number;
      type?: string | null;
      street: Array<string>;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    }>;
    closures: Array<{
      __typename?: "Closure";
      id: number;
      beginsAt?: any | null;
      endsAt?: any | null;
      reason?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    }>;
    media: Array<{
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    }>;
    primaryMedia?: {
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    } | null;
    rating: {
      __typename?: "Rating";
      average?: number | null;
      count: number;
      distribution: Array<{
        __typename?: "RatingCount";
        rating: number;
        count: number;
      }>;
    };
  }>;
  rating: {
    __typename?: "Rating";
    average?: number | null;
    count: number;
    distribution: Array<{
      __typename?: "RatingCount";
      rating: number;
      count: number;
    }>;
  };
};

export type SlotFieldsFragment = {
  __typename?: "Slot";
  id?: number | null;
  slotDate?: any | null;
  status?: SlotStatus | null;
  version: number;
  updatedAt: any;
  rate: {
    __typename?: "Rate";
    perNight?: number | null;
    perPerson?: number | null;
    fees?: number | null;
    displayRate?: string | null;
    displayFees?: string | null;
  };
};

export type UserFieldsFragment = {
  __typename?: "User";
  id: number;
  displayName: string;
  avatarImageUrl?: string | null;
};

export type AuthenticatedUserFieldsFragment = {
  __typename?: "User";
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  settingsJson?: string | null;
  maxWatchCredits: number;
  usedWatchCredits: number;
  permissions?: Array<Permissions> | null;
  version?: number | null;
  isActive?: boolean | null;
  updatedAt?: any | null;
  isSso?: boolean | null;
  id: number;
  displayName: string;
  avatarImageUrl?: string | null;
};

export type AdminUserFieldsFragment = {
  __typename?: "User";
  roles?: Array<string> | null;
};

export type ChatMessageFieldsFragment = {
  __typename?: "ChatMessage";
  id?: string | null;
  message: string;
  sentAt: any;
  deletedAt?: any | null;
  sender?: {
    __typename?: "User";
    id: number;
    displayName: string;
    avatarImageUrl?: string | null;
  } | null;
  recipient?: {
    __typename?: "User";
    id: number;
    displayName: string;
    avatarImageUrl?: string | null;
  } | null;
};

export type FilterFieldsFragment = {
  __typename?: "Filter";
  nights?: number | null;
  excludeGroup: boolean;
  onlyPetsAllowed: boolean;
  onlyAccessible: boolean;
  excludeMandatoryEquipment: boolean;
  maxVehicleLengthFeet?: number | null;
  vehicles?: number | null;
  dates?: {__typename?: "DateRange"; min?: any | null; max?: any | null} | null;
  people?: {
    __typename?: "IntRange";
    min?: number | null;
    max?: number | null;
  } | null;
};

export type WatchFieldsFragment = {
  __typename?: "Watch";
  id: number;
  name?: string | null;
  displayName: string;
  scheduleDescription: string;
  status: WatchStatus;
  expiresAt?: any | null;
  lastScannedAt?: any | null;
  alertsEnabled: boolean;
  version: number;
  createdAt: any;
  updatedAt: any;
  creator: {__typename?: "User"; id: number};
  filter: {
    __typename?: "Filter";
    nights?: number | null;
    excludeGroup: boolean;
    onlyPetsAllowed: boolean;
    onlyAccessible: boolean;
    excludeMandatoryEquipment: boolean;
    maxVehicleLengthFeet?: number | null;
    vehicles?: number | null;
    dates?: {
      __typename?: "DateRange";
      min?: any | null;
      max?: any | null;
    } | null;
    people?: {
      __typename?: "IntRange";
      min?: number | null;
      max?: number | null;
    } | null;
  };
  parks: Array<{
    __typename?: "Park";
    id: number;
    slug?: string | null;
    name?: string | null;
  }>;
  facilities: Array<{
    __typename?: "Facility";
    id: number;
    name?: string | null;
    park?: {
      __typename?: "Park";
      id: number;
      slug?: string | null;
      name?: string | null;
    } | null;
  }>;
  units: Array<{
    __typename?: "Unit";
    id: number;
    slug?: string | null;
    name?: string | null;
    facility?: {
      __typename?: "Facility";
      id: number;
      slug?: string | null;
      name?: string | null;
      park?: {
        __typename?: "Park";
        id: number;
        slug?: string | null;
        name?: string | null;
      } | null;
    } | null;
  }>;
  effectiveFacilities: Array<{
    __typename?: "Facility";
    id: number;
    slug?: string | null;
    name?: string | null;
    park?: {
      __typename?: "Park";
      id: number;
      slug?: string | null;
      name?: string | null;
    } | null;
  }>;
  foundFacilities: Array<{
    __typename?: "Facility";
    id: number;
    slug?: string | null;
    name?: string | null;
    park?: {
      __typename?: "Park";
      id: number;
      slug?: string | null;
      name?: string | null;
    } | null;
  }>;
};

type SearchableFields_Facility_Fragment = {
  __typename?: "Facility";
  id: number;
  slug?: string | null;
  type?: string | null;
  name?: string | null;
  url?: string | null;
  bookingUrl?: string | null;
  reservationUrl?: string | null;
  mapUrl?: string | null;
  checkinHour?: number | null;
  checkoutHour?: number | null;
  description?: string | null;
  descriptionHtml?: string | null;
  feeDescription?: string | null;
  rules?: string | null;
  directions?: string | null;
  stayLimit?: string | null;
  phone?: string | null;
  email?: string | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  favoritesCount?: number | null;
  unitCount: number;
  isWatched: boolean;
  isShared: boolean;
  version: number;
  isActive: boolean;
  updatedAt: any;
  checkRequested: boolean;
  nextCheckRequestAvailableAt?: any | null;
  lastCheckedAt?: any | null;
  units: Array<{
    __typename?: "Unit";
    id: number;
    slug?: string | null;
    type?: string | null;
    useType?: string | null;
    name?: string | null;
    loopName?: string | null;
    bookingUrl?: string | null;
    rules?: string | null;
    isGroup?: boolean | null;
    isReservable?: boolean | null;
    isAdaAccessible?: boolean | null;
    petsAllowed?: boolean | null;
    maxVehicles?: number | null;
    maxVehicleLengthFeet?: number | null;
    isEquipmentMandatory?: boolean | null;
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    peopleCapacity?: {
      __typename?: "IntRange";
      min?: number | null;
      max?: number | null;
    } | null;
    primaryMedia?: {
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    } | null;
  }>;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
  rating: {
    __typename?: "Rating";
    average?: number | null;
    count: number;
    distribution: Array<{
      __typename?: "RatingCount";
      rating: number;
      count: number;
    }>;
  };
};

type SearchableFields_Park_Fragment = {
  __typename?: "Park";
  id: number;
  slug?: string | null;
  name?: string | null;
  description?: string | null;
  descriptionHtml?: string | null;
  feeDescription?: string | null;
  directions?: string | null;
  stayLimit?: string | null;
  phone?: string | null;
  email?: string | null;
  reservationUrl?: string | null;
  mapUrl?: string | null;
  isReservable?: boolean | null;
  geometryUrl?: string | null;
  organizationId?: number | null;
  favoritesCount?: number | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  addresses: Array<{
    __typename?: "Address";
    id: number;
    type?: string | null;
    street: Array<string>;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
  facilities: Array<{
    __typename?: "Facility";
    unitCount: number;
    id: number;
    slug?: string | null;
    type?: string | null;
    name?: string | null;
    url?: string | null;
    bookingUrl?: string | null;
    reservationUrl?: string | null;
    mapUrl?: string | null;
    checkinHour?: number | null;
    checkoutHour?: number | null;
    description?: string | null;
    descriptionHtml?: string | null;
    feeDescription?: string | null;
    rules?: string | null;
    directions?: string | null;
    stayLimit?: string | null;
    phone?: string | null;
    email?: string | null;
    isReservable?: boolean | null;
    isAdaAccessible?: boolean | null;
    favoritesCount?: number | null;
    isWatched: boolean;
    isShared: boolean;
    version: number;
    isActive: boolean;
    updatedAt: any;
    checkRequested: boolean;
    nextCheckRequestAvailableAt?: any | null;
    lastCheckedAt?: any | null;
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    addresses: Array<{
      __typename?: "Address";
      id: number;
      type?: string | null;
      street: Array<string>;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    }>;
    closures: Array<{
      __typename?: "Closure";
      id: number;
      beginsAt?: any | null;
      endsAt?: any | null;
      reason?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    }>;
    media: Array<{
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    }>;
    primaryMedia?: {
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    } | null;
    rating: {
      __typename?: "Rating";
      average?: number | null;
      count: number;
      distribution: Array<{
        __typename?: "RatingCount";
        rating: number;
        count: number;
      }>;
    };
  }>;
  rating: {
    __typename?: "Rating";
    average?: number | null;
    count: number;
    distribution: Array<{
      __typename?: "RatingCount";
      rating: number;
      count: number;
    }>;
  };
};

type SearchableFields_Place_Fragment = {__typename?: "Place"};

type SearchableFields_Unit_Fragment = {
  __typename?: "Unit";
  id: number;
  slug?: string | null;
  type?: string | null;
  useType?: string | null;
  name?: string | null;
  loopName?: string | null;
  bookingUrl?: string | null;
  rules?: string | null;
  isGroup?: boolean | null;
  isReservable?: boolean | null;
  isAdaAccessible?: boolean | null;
  petsAllowed?: boolean | null;
  firePit?: boolean | null;
  picnicTable?: boolean | null;
  waterHookup?: boolean | null;
  sewerHookup?: boolean | null;
  electricityHookupAmps?: number | null;
  drivewayLengthFeet?: number | null;
  maxVehicles?: number | null;
  maxVehicleLengthFeet?: number | null;
  favoritesCount?: number | null;
  isEquipmentMandatory?: boolean | null;
  version: number;
  isActive: boolean;
  updatedAt: any;
  location?: {
    __typename?: "Location";
    latitude?: number | null;
    longitude?: number | null;
  } | null;
  peopleCapacity?: {
    __typename?: "IntRange";
    min?: number | null;
    max?: number | null;
  } | null;
  facility?: {
    __typename?: "Facility";
    id: number;
    slug?: string | null;
    name?: string | null;
    park?: {
      __typename?: "Park";
      id: number;
      slug?: string | null;
      name?: string | null;
    } | null;
  } | null;
  allowedEquipment: Array<{
    __typename?: "Equipment";
    id: number;
    name: string;
    description?: string | null;
    isActive: boolean;
    updatedAt: any;
  }>;
  closures: Array<{
    __typename?: "Closure";
    id: number;
    beginsAt?: any | null;
    endsAt?: any | null;
    reason?: string | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  media: Array<{
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  }>;
  primaryMedia?: {
    __typename?: "Media";
    id?: number | null;
    type?: string | null;
    title?: string | null;
    subtitle?: string | null;
    description?: string | null;
    credits?: string | null;
    url?: string | null;
    width?: number | null;
    height?: number | null;
    version: number;
    isActive: boolean;
    updatedAt: any;
  } | null;
  rating: {
    __typename?: "Rating";
    average?: number | null;
    count: number;
    distribution: Array<{
      __typename?: "RatingCount";
      rating: number;
      count: number;
    }>;
  };
};

export type SearchableFieldsFragment =
  | SearchableFields_Facility_Fragment
  | SearchableFields_Park_Fragment
  | SearchableFields_Place_Fragment
  | SearchableFields_Unit_Fragment;

export type ContentFieldsFragment = {
  __typename?: "Content";
  type: string;
  id: number;
  slug?: string | null;
  status: ContentStatus;
  title?: string | null;
  tagline?: string | null;
  bodyMarkdown?: string | null;
  isFeatured: boolean;
  sortOrder?: number | null;
  showCreator: boolean;
  createdAt: any;
  updatedAt: any;
  version: number;
  creator: {
    __typename?: "User";
    id: number;
    displayName: string;
    avatarImageUrl?: string | null;
  };
};

export type ContentAttachmentFieldsFragment = {
  __typename?: "ContentAttachment";
  id: string;
  contentId: number;
  title?: string | null;
  fileName: string;
  mimeType: string;
  dataUrl: string;
  createdAt: any;
  updatedAt: any;
};

export type ReviewFieldsFragment = {
  __typename?: "Review";
  id: string;
  reviewerName?: string | null;
  rating?: number | null;
  comment?: string | null;
  createdAt: any;
  updatedAt: any;
  reviewer?: {
    __typename?: "User";
    id: number;
    displayName: string;
    avatarImageUrl?: string | null;
  } | null;
  facility?: {
    __typename?: "Facility";
    id: number;
    slug?: string | null;
    name?: string | null;
  } | null;
  unit?: {
    __typename?: "Unit";
    id: number;
    slug?: string | null;
    name?: string | null;
    loopName?: string | null;
  } | null;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  captchaResponse: Scalars["String"]["input"];
  reason?: InputMaybe<Scalars["String"]["input"]>;
  referral?: InputMaybe<Scalars["String"]["input"]>;
  invitationToken?: InputMaybe<Scalars["String"]["input"]>;
  acceptedTerms: Scalars["Boolean"]["input"];
}>;

export type SignUpMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    signUp:
      | {
          __typename?: "FormError";
          field?: string | null;
          message?: string | null;
        }
      | {__typename?: "SignUpSuccessResult"; userId: number; email: string};
  };
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
  newPasswordConfirmation: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    resetPassword:
      | {
          __typename?: "FormError";
          field?: string | null;
          message?: string | null;
        }
      | {
          __typename?: "ResetPasswordSuccessResult";
          success: boolean;
          email: string;
        };
  };
};

export type ResetPasswordRequestMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  captchaResponse: Scalars["String"]["input"];
}>;

export type ResetPasswordRequestMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    resetPasswordRequest:
      | {
          __typename?: "FormError";
          field?: string | null;
          message?: string | null;
        }
      | {__typename?: "ResetPasswordSuccessResult"; success: boolean};
  };
};

export type UpdateUserSettingsMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  oldPassword?: InputMaybe<Scalars["String"]["input"]>;
  newPassword?: InputMaybe<Scalars["String"]["input"]>;
  partialSettingsJson?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateUserSettingsMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    updateSettings: {
      __typename?: "User";
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      settingsJson?: string | null;
      maxWatchCredits: number;
      usedWatchCredits: number;
      permissions?: Array<Permissions> | null;
      version?: number | null;
      isActive?: boolean | null;
      updatedAt?: any | null;
      isSso?: boolean | null;
      id: number;
      displayName: string;
      avatarImageUrl?: string | null;
    };
  };
};

export type CompleteTutorialMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type CompleteTutorialMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    completeTutorial: {
      __typename?: "User";
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      settingsJson?: string | null;
      maxWatchCredits: number;
      usedWatchCredits: number;
      permissions?: Array<Permissions> | null;
      version?: number | null;
      isActive?: boolean | null;
      updatedAt?: any | null;
      isSso?: boolean | null;
      id: number;
      displayName: string;
      avatarImageUrl?: string | null;
    };
  };
};

export type UpdateUnitMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  type?: InputMaybe<Scalars["String"]["input"]>;
  useType?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  loopName?: InputMaybe<Scalars["String"]["input"]>;
  location?: InputMaybe<LocationInput>;
  peopleCapacity?: InputMaybe<IntRangeInput>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  isGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAdaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  petsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxVehicles?: InputMaybe<Scalars["Int"]["input"]>;
  maxVehicleLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  facilityId?: InputMaybe<Scalars["Int"]["input"]>;
  isEquipmentMandatory?: InputMaybe<Scalars["Boolean"]["input"]>;
  allowedEquipmentIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateUnitMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updateUnit: {
      __typename?: "Unit";
      id: number;
      slug?: string | null;
      type?: string | null;
      useType?: string | null;
      name?: string | null;
      loopName?: string | null;
      bookingUrl?: string | null;
      rules?: string | null;
      isGroup?: boolean | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      petsAllowed?: boolean | null;
      firePit?: boolean | null;
      picnicTable?: boolean | null;
      waterHookup?: boolean | null;
      sewerHookup?: boolean | null;
      electricityHookupAmps?: number | null;
      drivewayLengthFeet?: number | null;
      maxVehicles?: number | null;
      maxVehicleLengthFeet?: number | null;
      favoritesCount?: number | null;
      isEquipmentMandatory?: boolean | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      peopleCapacity?: {
        __typename?: "IntRange";
        min?: number | null;
        max?: number | null;
      } | null;
      facility?: {
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      } | null;
      allowedEquipment: Array<{
        __typename?: "Equipment";
        id: number;
        name: string;
        description?: string | null;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    };
  };
};

export type UpdateFacilityMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  type?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  bookingUrl?: InputMaybe<Scalars["String"]["input"]>;
  reservationUrl?: InputMaybe<Scalars["String"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  checkinHour?: InputMaybe<Scalars["Int"]["input"]>;
  checkoutHour?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  feeDescription?: InputMaybe<Scalars["String"]["input"]>;
  rules?: InputMaybe<Scalars["String"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  stayLimit?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  isAdaAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<LocationInput>;
  parkId?: InputMaybe<Scalars["Int"]["input"]>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateFacilityMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updateFacility: {
      __typename?: "Facility";
      id: number;
      slug?: string | null;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      checkRequested: boolean;
      nextCheckRequestAvailableAt?: any | null;
      lastCheckedAt?: any | null;
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        isEquipmentMandatory?: boolean | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    };
  };
};

export type UpdateParkMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  name?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  descriptionHtml?: InputMaybe<Scalars["String"]["input"]>;
  feeDescription?: InputMaybe<Scalars["String"]["input"]>;
  directions?: InputMaybe<Scalars["String"]["input"]>;
  stayLimit?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  reservationUrl?: InputMaybe<Scalars["String"]["input"]>;
  mapUrl?: InputMaybe<Scalars["String"]["input"]>;
  isReservable?: InputMaybe<Scalars["Boolean"]["input"]>;
  location?: InputMaybe<LocationInput>;
  organizationId?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateParkMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updatePark: {
      __typename?: "Park";
      id: number;
      slug?: string | null;
      name?: string | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      isReservable?: boolean | null;
      geometryUrl?: string | null;
      organizationId?: number | null;
      favoritesCount?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      facilities: Array<{
        __typename?: "Facility";
        unitCount: number;
        id: number;
        slug?: string | null;
        type?: string | null;
        name?: string | null;
        url?: string | null;
        bookingUrl?: string | null;
        reservationUrl?: string | null;
        mapUrl?: string | null;
        checkinHour?: number | null;
        checkoutHour?: number | null;
        description?: string | null;
        descriptionHtml?: string | null;
        feeDescription?: string | null;
        rules?: string | null;
        directions?: string | null;
        stayLimit?: string | null;
        phone?: string | null;
        email?: string | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        favoritesCount?: number | null;
        isWatched: boolean;
        isShared: boolean;
        version: number;
        isActive: boolean;
        updatedAt: any;
        checkRequested: boolean;
        nextCheckRequestAvailableAt?: any | null;
        lastCheckedAt?: any | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        addresses: Array<{
          __typename?: "Address";
          id: number;
          type?: string | null;
          street: Array<string>;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          country?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
        rating: {
          __typename?: "Rating";
          average?: number | null;
          count: number;
          distribution: Array<{
            __typename?: "RatingCount";
            rating: number;
            count: number;
          }>;
        };
      }>;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    };
  };
};

export type UpdateMediaMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  type?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  subtitle?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  credits?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
  height?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateMediaMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updateMedia: {
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  type?: InputMaybe<Scalars["String"]["input"]>;
  street?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  city?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  zip?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateAddressMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updateAddress: {
      __typename?: "Address";
      id: number;
      type?: string | null;
      street: Array<string>;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type UpdateClosureMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  beginsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  endsAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  reason?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UpdateClosureMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    updateClosure: {
      __typename?: "Closure";
      id: number;
      beginsAt?: any | null;
      endsAt?: any | null;
      reason?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type RefreshUnitMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshUnitMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshUnit: {
      __typename?: "Unit";
      id: number;
      slug?: string | null;
      type?: string | null;
      useType?: string | null;
      name?: string | null;
      loopName?: string | null;
      bookingUrl?: string | null;
      rules?: string | null;
      isGroup?: boolean | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      petsAllowed?: boolean | null;
      firePit?: boolean | null;
      picnicTable?: boolean | null;
      waterHookup?: boolean | null;
      sewerHookup?: boolean | null;
      electricityHookupAmps?: number | null;
      drivewayLengthFeet?: number | null;
      maxVehicles?: number | null;
      maxVehicleLengthFeet?: number | null;
      favoritesCount?: number | null;
      isEquipmentMandatory?: boolean | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      peopleCapacity?: {
        __typename?: "IntRange";
        min?: number | null;
        max?: number | null;
      } | null;
      facility?: {
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      } | null;
      allowedEquipment: Array<{
        __typename?: "Equipment";
        id: number;
        name: string;
        description?: string | null;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    };
  };
};

export type RefreshFacilityMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshFacilityMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshFacility: {
      __typename?: "Facility";
      id: number;
      slug?: string | null;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      checkRequested: boolean;
      nextCheckRequestAvailableAt?: any | null;
      lastCheckedAt?: any | null;
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        isEquipmentMandatory?: boolean | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    };
  };
};

export type RefreshParkMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshParkMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshPark: {
      __typename?: "Park";
      id: number;
      slug?: string | null;
      name?: string | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      isReservable?: boolean | null;
      geometryUrl?: string | null;
      organizationId?: number | null;
      favoritesCount?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      facilities: Array<{
        __typename?: "Facility";
        unitCount: number;
        id: number;
        slug?: string | null;
        type?: string | null;
        name?: string | null;
        url?: string | null;
        bookingUrl?: string | null;
        reservationUrl?: string | null;
        mapUrl?: string | null;
        checkinHour?: number | null;
        checkoutHour?: number | null;
        description?: string | null;
        descriptionHtml?: string | null;
        feeDescription?: string | null;
        rules?: string | null;
        directions?: string | null;
        stayLimit?: string | null;
        phone?: string | null;
        email?: string | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        favoritesCount?: number | null;
        isWatched: boolean;
        isShared: boolean;
        version: number;
        isActive: boolean;
        updatedAt: any;
        checkRequested: boolean;
        nextCheckRequestAvailableAt?: any | null;
        lastCheckedAt?: any | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        addresses: Array<{
          __typename?: "Address";
          id: number;
          type?: string | null;
          street: Array<string>;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          country?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
        rating: {
          __typename?: "Rating";
          average?: number | null;
          count: number;
          distribution: Array<{
            __typename?: "RatingCount";
            rating: number;
            count: number;
          }>;
        };
      }>;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    };
  };
};

export type RefreshMediaMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshMediaMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshMedia: {
      __typename?: "Media";
      id?: number | null;
      type?: string | null;
      title?: string | null;
      subtitle?: string | null;
      description?: string | null;
      credits?: string | null;
      url?: string | null;
      width?: number | null;
      height?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type RefreshAddressMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshAddressMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshAddress: {
      __typename?: "Address";
      id: number;
      type?: string | null;
      street: Array<string>;
      city?: string | null;
      state?: string | null;
      zip?: string | null;
      country?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type RefreshClosureMutationVariables = Exact<{
  id: Scalars["Int"]["input"];
  clearOverrides?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
}>;

export type RefreshClosureMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    refreshClosure: {
      __typename?: "Closure";
      id: number;
      beginsAt?: any | null;
      endsAt?: any | null;
      reason?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
    };
  };
};

export type SendChatMessageMutationVariables = Exact<{
  message: Scalars["String"]["input"];
}>;

export type SendChatMessageMutation = {
  __typename?: "Mutation";
  chat: {
    __typename?: "ChatMutation";
    sendMessage: {
      __typename?: "ChatMessage";
      id?: string | null;
      message: string;
      sentAt: any;
      deletedAt?: any | null;
      sender?: {
        __typename?: "User";
        id: number;
        displayName: string;
        avatarImageUrl?: string | null;
      } | null;
      recipient?: {
        __typename?: "User";
        id: number;
        displayName: string;
        avatarImageUrl?: string | null;
      } | null;
    };
  };
};

export type UploadMappingMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  file: Scalars["Upload"]["input"];
}>;

export type UploadMappingMutation = {
  __typename?: "Mutation";
  uploads: {
    __typename?: "UploadsMutation";
    uploadMapping: {
      __typename?: "UploadMappingResult";
      uploadId: string;
      fileName: string;
      fileFormat?: string | null;
      rows: Array<{
        __typename?: "UploadMappingRow";
        id: string;
        name?: string | null;
        description?: string | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        unit?: {
          __typename?: "Unit";
          id: number;
          slug?: string | null;
          name?: string | null;
          loopName?: string | null;
          facility?: {
            __typename?: "Facility";
            id: number;
            slug?: string | null;
            name?: string | null;
            park?: {
              __typename?: "Park";
              id: number;
              slug?: string | null;
              name?: string | null;
            } | null;
          } | null;
        } | null;
      }>;
    };
  };
};

export type CreateSubscriptionMutationVariables = Exact<{
  planId: Scalars["String"]["input"];
}>;

export type CreateSubscriptionMutation = {
  __typename?: "Mutation";
  subscriptions: {
    __typename?: "SubscriptionsMutation";
    create: {
      __typename?: "CreateSubscriptionResult";
      subscriptionId: string;
      clientSecret: string;
    };
  };
};

export type CancelSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars["String"]["input"];
}>;

export type CancelSubscriptionMutation = {
  __typename?: "Mutation";
  subscriptions: {__typename?: "SubscriptionsMutation"; cancel: boolean};
};

export type CreateWatchMutationVariables = Exact<{
  name?: InputMaybe<Scalars["String"]["input"]>;
  excludeGroup?: Scalars["Boolean"]["input"];
  minPeople?: InputMaybe<Scalars["Int"]["input"]>;
  maxPeople?: InputMaybe<Scalars["Int"]["input"]>;
  onlyPetsAllowed?: Scalars["Boolean"]["input"];
  onlyAccessible?: Scalars["Boolean"]["input"];
  excludeMandatoryEquipment?: Scalars["Boolean"]["input"];
  maxVehicleLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  vehicles?: InputMaybe<Scalars["Int"]["input"]>;
  minDate: Scalars["Date"]["input"];
  maxDate: Scalars["Date"]["input"];
  nights?: InputMaybe<Scalars["Int"]["input"]>;
  alertsEnabled: Scalars["Boolean"]["input"];
  parkIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
  facilityIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
  unitIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
}>;

export type CreateWatchMutation = {
  __typename?: "Mutation";
  watches: {
    __typename?: "WatchesMutation";
    create: {
      __typename?: "Watch";
      id: number;
      name?: string | null;
      displayName: string;
      scheduleDescription: string;
      status: WatchStatus;
      expiresAt?: any | null;
      lastScannedAt?: any | null;
      alertsEnabled: boolean;
      version: number;
      createdAt: any;
      updatedAt: any;
      creator: {__typename?: "User"; id: number};
      filter: {
        __typename?: "Filter";
        nights?: number | null;
        excludeGroup: boolean;
        onlyPetsAllowed: boolean;
        onlyAccessible: boolean;
        excludeMandatoryEquipment: boolean;
        maxVehicleLengthFeet?: number | null;
        vehicles?: number | null;
        dates?: {
          __typename?: "DateRange";
          min?: any | null;
          max?: any | null;
        } | null;
        people?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
      };
      parks: Array<{
        __typename?: "Park";
        id: number;
        slug?: string | null;
        name?: string | null;
      }>;
      facilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        name?: string | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          slug?: string | null;
          name?: string | null;
          park?: {
            __typename?: "Park";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
        } | null;
      }>;
      effectiveFacilities: Array<{
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
      foundFacilities: Array<{
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
    };
  };
};

export type UpdateWatchMutationVariables = Exact<{
  watchId: Scalars["Int"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  unsetFields?: InputMaybe<
    Array<Scalars["String"]["input"]> | Scalars["String"]["input"]
  >;
  excludeGroup?: InputMaybe<Scalars["Boolean"]["input"]>;
  minPeople?: InputMaybe<Scalars["Int"]["input"]>;
  maxPeople?: InputMaybe<Scalars["Int"]["input"]>;
  onlyPetsAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  onlyAccessible?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeMandatoryEquipment?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxVehicleLengthFeet?: InputMaybe<Scalars["Int"]["input"]>;
  vehicles?: InputMaybe<Scalars["Int"]["input"]>;
  minDate?: InputMaybe<Scalars["Date"]["input"]>;
  maxDate?: InputMaybe<Scalars["Date"]["input"]>;
  nights?: InputMaybe<Scalars["Int"]["input"]>;
  alertsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  parkIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
  facilityIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
  unitIds?: InputMaybe<
    Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"]
  >;
}>;

export type UpdateWatchMutation = {
  __typename?: "Mutation";
  watches: {
    __typename?: "WatchesMutation";
    update: {
      __typename?: "Watch";
      id: number;
      name?: string | null;
      displayName: string;
      scheduleDescription: string;
      status: WatchStatus;
      expiresAt?: any | null;
      lastScannedAt?: any | null;
      alertsEnabled: boolean;
      version: number;
      createdAt: any;
      updatedAt: any;
      creator: {__typename?: "User"; id: number};
      filter: {
        __typename?: "Filter";
        nights?: number | null;
        excludeGroup: boolean;
        onlyPetsAllowed: boolean;
        onlyAccessible: boolean;
        excludeMandatoryEquipment: boolean;
        maxVehicleLengthFeet?: number | null;
        vehicles?: number | null;
        dates?: {
          __typename?: "DateRange";
          min?: any | null;
          max?: any | null;
        } | null;
        people?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
      };
      parks: Array<{
        __typename?: "Park";
        id: number;
        slug?: string | null;
        name?: string | null;
      }>;
      facilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        name?: string | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          slug?: string | null;
          name?: string | null;
          park?: {
            __typename?: "Park";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
        } | null;
      }>;
      effectiveFacilities: Array<{
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
      foundFacilities: Array<{
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
    };
  };
};

export type CancelWatchMutationVariables = Exact<{
  watchId: Scalars["Int"]["input"];
}>;

export type CancelWatchMutation = {
  __typename?: "Mutation";
  watches: {
    __typename?: "WatchesMutation";
    cancel: {
      __typename?: "Watch";
      id: number;
      name?: string | null;
      displayName: string;
      scheduleDescription: string;
      status: WatchStatus;
      expiresAt?: any | null;
      lastScannedAt?: any | null;
      alertsEnabled: boolean;
      version: number;
      createdAt: any;
      updatedAt: any;
      creator: {__typename?: "User"; id: number};
      filter: {
        __typename?: "Filter";
        nights?: number | null;
        excludeGroup: boolean;
        onlyPetsAllowed: boolean;
        onlyAccessible: boolean;
        excludeMandatoryEquipment: boolean;
        maxVehicleLengthFeet?: number | null;
        vehicles?: number | null;
        dates?: {
          __typename?: "DateRange";
          min?: any | null;
          max?: any | null;
        } | null;
        people?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
      };
      parks: Array<{
        __typename?: "Park";
        id: number;
        slug?: string | null;
        name?: string | null;
      }>;
      facilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        name?: string | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          slug?: string | null;
          name?: string | null;
          park?: {
            __typename?: "Park";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
        } | null;
      }>;
      effectiveFacilities: Array<{
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
      foundFacilities: Array<{
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
    };
  };
};

export type CheckFacilityMutationVariables = Exact<{
  facilityId: Scalars["Int"]["input"];
  beginDate: Scalars["Date"]["input"];
  endDate: Scalars["Date"]["input"];
}>;

export type CheckFacilityMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    checkFacility: {
      __typename?: "Facility";
      id: number;
      slug?: string | null;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      checkRequested: boolean;
      nextCheckRequestAvailableAt?: any | null;
      lastCheckedAt?: any | null;
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        isEquipmentMandatory?: boolean | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    };
  };
};

export type CreateContentMutationVariables = Exact<{
  content: ContentInput;
}>;

export type CreateContentMutation = {
  __typename?: "Mutation";
  cms: {
    __typename?: "CMSMutation";
    create: {
      __typename?: "Content";
      type: string;
      id: number;
      slug?: string | null;
      status: ContentStatus;
      title?: string | null;
      tagline?: string | null;
      bodyMarkdown?: string | null;
      isFeatured: boolean;
      sortOrder?: number | null;
      showCreator: boolean;
      createdAt: any;
      updatedAt: any;
      version: number;
      creator: {
        __typename?: "User";
        id: number;
        displayName: string;
        avatarImageUrl?: string | null;
      };
    };
  };
};

export type UpdateContentMutationVariables = Exact<{
  content: ContentInput;
}>;

export type UpdateContentMutation = {
  __typename?: "Mutation";
  cms: {
    __typename?: "CMSMutation";
    update: {
      __typename?: "Content";
      type: string;
      id: number;
      slug?: string | null;
      status: ContentStatus;
      title?: string | null;
      tagline?: string | null;
      bodyMarkdown?: string | null;
      isFeatured: boolean;
      sortOrder?: number | null;
      showCreator: boolean;
      createdAt: any;
      updatedAt: any;
      version: number;
      creator: {
        __typename?: "User";
        id: number;
        displayName: string;
        avatarImageUrl?: string | null;
      };
    };
  };
};

export type UploadContentAttachmentMutationVariables = Exact<{
  attachment: ContentAttachmentInput;
}>;

export type UploadContentAttachmentMutation = {
  __typename?: "Mutation";
  cms: {
    __typename?: "CMSMutation";
    uploadAttachment: {
      __typename?: "ContentAttachment";
      id: string;
      contentId: number;
      title?: string | null;
      fileName: string;
      mimeType: string;
      dataUrl: string;
      createdAt: any;
      updatedAt: any;
    };
  };
};

export type DestroyContentMutationVariables = Exact<{
  contentId: Scalars["Int"]["input"];
}>;

export type DestroyContentMutation = {
  __typename?: "Mutation";
  cms: {
    __typename?: "CMSMutation";
    destroy: {
      __typename?: "Content";
      type: string;
      id: number;
      slug?: string | null;
      status: ContentStatus;
      title?: string | null;
      tagline?: string | null;
      bodyMarkdown?: string | null;
      isFeatured: boolean;
      sortOrder?: number | null;
      showCreator: boolean;
      createdAt: any;
      updatedAt: any;
      version: number;
      creator: {
        __typename?: "User";
        id: number;
        displayName: string;
        avatarImageUrl?: string | null;
      };
    };
  };
};

export type InviteMutationVariables = Exact<{
  invitation: InvitationInput;
}>;

export type InviteMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    invite:
      | {
          __typename?: "FormError";
          field?: string | null;
          message?: string | null;
        }
      | {
          __typename?: "InvitationSuccessResult";
          email: string;
          message?: string | null;
          inviterWatchCredits?: number | null;
          inviteeWatchCredits?: number | null;
        };
  };
};

export type ReviewMutationVariables = Exact<{
  input: ReviewInput;
}>;

export type ReviewMutation = {
  __typename?: "Mutation";
  entities: {
    __typename?: "EntitiesMutation";
    review:
      | {
          __typename?: "FormError";
          field?: string | null;
          message?: string | null;
        }
      | {
          __typename?: "Review";
          id: string;
          reviewerName?: string | null;
          rating?: number | null;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
          reviewer?: {
            __typename?: "User";
            id: number;
            displayName: string;
            avatarImageUrl?: string | null;
          } | null;
          facility?: {
            __typename?: "Facility";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
          unit?: {
            __typename?: "Unit";
            id: number;
            slug?: string | null;
            name?: string | null;
            loopName?: string | null;
          } | null;
        };
  };
};

export type MapSearchQueryVariables = Exact<{
  filter?: InputMaybe<FilterInput>;
  zoom?: InputMaybe<Scalars["Float"]["input"]>;
  onlyMatches?: InputMaybe<Scalars["Boolean"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortDirection?: InputMaybe<SortDirection>;
  cluster?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeUsfsBoundaries?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeBlmBoundaries?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type MapSearchQuery = {
  __typename?: "Query";
  search: {
    __typename?: "SearchQuery";
    mapSearch: {
      __typename?: "MapSearchResults";
      results: Array<
        | {
            __typename?: "MapCluster";
            name?: string | null;
            count: number;
            isMatch: boolean;
            isShared: boolean;
            isWatched: boolean;
            expansionZoom: number;
            location?: {
              __typename?: "Location";
              latitude?: number | null;
              longitude?: number | null;
            } | null;
          }
        | {
            __typename?: "SearchResult";
            type: string;
            id: string;
            slug?: string | null;
            name?: string | null;
            geometryUrl?: string | null;
            organizationId?: number | null;
            isMatch: boolean;
            isShared: boolean;
            isWatched: boolean;
            updatedAt?: any | null;
            location?: {
              __typename?: "Location";
              latitude?: number | null;
              longitude?: number | null;
            } | null;
          }
      >;
      pagination: {
        __typename?: "PaginationResults";
        page: number;
        pageSize: number;
        totalResults: number;
      };
    };
  };
};

export type PickerSearchQueryVariables = Exact<{
  term?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<FilterInput>;
}>;

export type PickerSearchQuery = {
  __typename?: "Query";
  search: {
    __typename?: "SearchQuery";
    mapSearch: {
      __typename?: "MapSearchResults";
      results: Array<
        | {__typename?: "MapCluster"}
        | {
            __typename?: "SearchResult";
            type: string;
            subtype?: string | null;
            id: string;
            slug?: string | null;
            name?: string | null;
            context?: string | null;
            isMatch: boolean;
            location?: {
              __typename?: "Location";
              latitude?: number | null;
              longitude?: number | null;
            } | null;
            result?:
              | {
                  __typename?: "Facility";
                  park?: {__typename?: "Park"; name?: string | null} | null;
                }
              | {__typename?: "Park"}
              | {__typename?: "Place"}
              | {
                  __typename?: "Unit";
                  facility?: {
                    __typename?: "Facility";
                    name?: string | null;
                  } | null;
                }
              | null;
          }
      >;
    };
  };
};

export type TextSearchQueryVariables = Exact<{
  term: Scalars["String"]["input"];
  filter?: InputMaybe<FilterInput>;
  near?: InputMaybe<LocationInput>;
  correlationKey?: InputMaybe<Scalars["String"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type TextSearchQuery = {
  __typename?: "Query";
  search: {
    __typename?: "SearchQuery";
    textSearch: {
      __typename?: "TextSearchResults";
      correlationKey?: string | null;
      results: Array<{
        __typename?: "SearchResult";
        id: string;
        slug?: string | null;
        type: string;
        subtype?: string | null;
        name?: string | null;
        context?: string | null;
      }>;
    };
  };
};

export type TextSearchResultLocationQueryVariables = Exact<{
  mapableType: Scalars["String"]["input"];
  mapableId: Scalars["String"]["input"];
  correlationKey?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TextSearchResultLocationQuery = {
  __typename?: "Query";
  search: {
    __typename?: "SearchQuery";
    textSearchResultLocation?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  };
};

export type GetOrganizationDetailsQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetOrganizationDetailsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    organization?: {
      __typename?: "Organization";
      id: number;
      name: string;
      url?: string | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
    } | null;
  };
};

export type GetParkDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetParkDetailsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    park?: {
      __typename?: "Park";
      id: number;
      slug?: string | null;
      name?: string | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      isReservable?: boolean | null;
      geometryUrl?: string | null;
      organizationId?: number | null;
      favoritesCount?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      facilities: Array<{
        __typename?: "Facility";
        unitCount: number;
        id: number;
        slug?: string | null;
        type?: string | null;
        name?: string | null;
        url?: string | null;
        bookingUrl?: string | null;
        reservationUrl?: string | null;
        mapUrl?: string | null;
        checkinHour?: number | null;
        checkoutHour?: number | null;
        description?: string | null;
        descriptionHtml?: string | null;
        feeDescription?: string | null;
        rules?: string | null;
        directions?: string | null;
        stayLimit?: string | null;
        phone?: string | null;
        email?: string | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        favoritesCount?: number | null;
        isWatched: boolean;
        isShared: boolean;
        version: number;
        isActive: boolean;
        updatedAt: any;
        checkRequested: boolean;
        nextCheckRequestAvailableAt?: any | null;
        lastCheckedAt?: any | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        addresses: Array<{
          __typename?: "Address";
          id: number;
          type?: string | null;
          street: Array<string>;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          country?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
        rating: {
          __typename?: "Rating";
          average?: number | null;
          count: number;
          distribution: Array<{
            __typename?: "RatingCount";
            rating: number;
            count: number;
          }>;
        };
      }>;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    } | null;
  };
};

export type GetParkDetailsPageQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  reviewPage?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetParkDetailsPageQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    park?: {
      __typename?: "Park";
      id: number;
      slug?: string | null;
      name?: string | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      isReservable?: boolean | null;
      geometryUrl?: string | null;
      organizationId?: number | null;
      favoritesCount?: number | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      nearbyParks: Array<{
        __typename?: "ParkWithDistance";
        distanceMiles: number;
        park: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
          description?: string | null;
          descriptionHtml?: string | null;
          feeDescription?: string | null;
          directions?: string | null;
          stayLimit?: string | null;
          phone?: string | null;
          email?: string | null;
          reservationUrl?: string | null;
          mapUrl?: string | null;
          isReservable?: boolean | null;
          geometryUrl?: string | null;
          organizationId?: number | null;
          favoritesCount?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
          location?: {
            __typename?: "Location";
            latitude?: number | null;
            longitude?: number | null;
          } | null;
          addresses: Array<{
            __typename?: "Address";
            id: number;
            type?: string | null;
            street: Array<string>;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
            country?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          closures: Array<{
            __typename?: "Closure";
            id: number;
            beginsAt?: any | null;
            endsAt?: any | null;
            reason?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          media: Array<{
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          primaryMedia?: {
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          } | null;
          facilities: Array<{
            __typename?: "Facility";
            unitCount: number;
            id: number;
            slug?: string | null;
            type?: string | null;
            name?: string | null;
            url?: string | null;
            bookingUrl?: string | null;
            reservationUrl?: string | null;
            mapUrl?: string | null;
            checkinHour?: number | null;
            checkoutHour?: number | null;
            description?: string | null;
            descriptionHtml?: string | null;
            feeDescription?: string | null;
            rules?: string | null;
            directions?: string | null;
            stayLimit?: string | null;
            phone?: string | null;
            email?: string | null;
            isReservable?: boolean | null;
            isAdaAccessible?: boolean | null;
            favoritesCount?: number | null;
            isWatched: boolean;
            isShared: boolean;
            version: number;
            isActive: boolean;
            updatedAt: any;
            checkRequested: boolean;
            nextCheckRequestAvailableAt?: any | null;
            lastCheckedAt?: any | null;
            location?: {
              __typename?: "Location";
              latitude?: number | null;
              longitude?: number | null;
            } | null;
            addresses: Array<{
              __typename?: "Address";
              id: number;
              type?: string | null;
              street: Array<string>;
              city?: string | null;
              state?: string | null;
              zip?: string | null;
              country?: string | null;
              version: number;
              isActive: boolean;
              updatedAt: any;
            }>;
            closures: Array<{
              __typename?: "Closure";
              id: number;
              beginsAt?: any | null;
              endsAt?: any | null;
              reason?: string | null;
              version: number;
              isActive: boolean;
              updatedAt: any;
            }>;
            media: Array<{
              __typename?: "Media";
              id?: number | null;
              type?: string | null;
              title?: string | null;
              subtitle?: string | null;
              description?: string | null;
              credits?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              version: number;
              isActive: boolean;
              updatedAt: any;
            }>;
            primaryMedia?: {
              __typename?: "Media";
              id?: number | null;
              type?: string | null;
              title?: string | null;
              subtitle?: string | null;
              description?: string | null;
              credits?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              version: number;
              isActive: boolean;
              updatedAt: any;
            } | null;
            rating: {
              __typename?: "Rating";
              average?: number | null;
              count: number;
              distribution: Array<{
                __typename?: "RatingCount";
                rating: number;
                count: number;
              }>;
            };
          }>;
          rating: {
            __typename?: "Rating";
            average?: number | null;
            count: number;
            distribution: Array<{
              __typename?: "RatingCount";
              rating: number;
              count: number;
            }>;
          };
        };
      }>;
      reviews: {
        __typename?: "ReviewResults";
        reviews: Array<{
          __typename?: "Review";
          id: string;
          reviewerName?: string | null;
          rating?: number | null;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
          reviewer?: {
            __typename?: "User";
            id: number;
            displayName: string;
            avatarImageUrl?: string | null;
          } | null;
          facility?: {
            __typename?: "Facility";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
          unit?: {
            __typename?: "Unit";
            id: number;
            slug?: string | null;
            name?: string | null;
            loopName?: string | null;
          } | null;
        }>;
        pagination: {
          __typename?: "PaginationResults";
          page: number;
          pageSize: number;
          totalResults: number;
        };
      };
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      facilities: Array<{
        __typename?: "Facility";
        unitCount: number;
        id: number;
        slug?: string | null;
        type?: string | null;
        name?: string | null;
        url?: string | null;
        bookingUrl?: string | null;
        reservationUrl?: string | null;
        mapUrl?: string | null;
        checkinHour?: number | null;
        checkoutHour?: number | null;
        description?: string | null;
        descriptionHtml?: string | null;
        feeDescription?: string | null;
        rules?: string | null;
        directions?: string | null;
        stayLimit?: string | null;
        phone?: string | null;
        email?: string | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        favoritesCount?: number | null;
        isWatched: boolean;
        isShared: boolean;
        version: number;
        isActive: boolean;
        updatedAt: any;
        checkRequested: boolean;
        nextCheckRequestAvailableAt?: any | null;
        lastCheckedAt?: any | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        addresses: Array<{
          __typename?: "Address";
          id: number;
          type?: string | null;
          street: Array<string>;
          city?: string | null;
          state?: string | null;
          zip?: string | null;
          country?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
        rating: {
          __typename?: "Rating";
          average?: number | null;
          count: number;
          distribution: Array<{
            __typename?: "RatingCount";
            rating: number;
            count: number;
          }>;
        };
      }>;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    } | null;
  };
};

export type GetFacilityDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  reviewPage?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetFacilityDetailsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    facility?: {
      __typename?: "Facility";
      id: number;
      slug?: string | null;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      checkRequested: boolean;
      nextCheckRequestAvailableAt?: any | null;
      lastCheckedAt?: any | null;
      park?: {
        __typename?: "Park";
        id: number;
        slug?: string | null;
        name?: string | null;
        description?: string | null;
        descriptionHtml?: string | null;
      } | null;
      reviews: {
        __typename?: "ReviewResults";
        reviews: Array<{
          __typename?: "Review";
          id: string;
          reviewerName?: string | null;
          rating?: number | null;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
          reviewer?: {
            __typename?: "User";
            id: number;
            displayName: string;
            avatarImageUrl?: string | null;
          } | null;
          facility?: {
            __typename?: "Facility";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
          unit?: {
            __typename?: "Unit";
            id: number;
            slug?: string | null;
            name?: string | null;
            loopName?: string | null;
          } | null;
        }>;
        pagination: {
          __typename?: "PaginationResults";
          page: number;
          pageSize: number;
          totalResults: number;
        };
      };
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        isEquipmentMandatory?: boolean | null;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
      }>;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    } | null;
  };
};

export type GetFacilityPopoverQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<FilterInput>;
  onlyMatches?: InputMaybe<Scalars["Boolean"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortDirection?: InputMaybe<SortDirection>;
}>;

export type GetFacilityPopoverQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    facility?: {
      __typename?: "Facility";
      id: number;
      slug?: string | null;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      checkRequested: boolean;
      nextCheckRequestAvailableAt?: any | null;
      lastCheckedAt?: any | null;
      park?: {
        __typename?: "Park";
        id: number;
        slug?: string | null;
        name?: string | null;
        description?: string | null;
        descriptionHtml?: string | null;
      } | null;
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        firePit?: boolean | null;
        picnicTable?: boolean | null;
        waterHookup?: boolean | null;
        sewerHookup?: boolean | null;
        electricityHookupAmps?: number | null;
        drivewayLengthFeet?: number | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        favoritesCount?: number | null;
        isEquipmentMandatory?: boolean | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
        slots: Array<{
          __typename?: "Slot";
          id?: number | null;
          slotDate?: any | null;
          status?: SlotStatus | null;
          version: number;
          updatedAt: any;
          rate: {
            __typename?: "Rate";
            perNight?: number | null;
            perPerson?: number | null;
            fees?: number | null;
            displayRate?: string | null;
            displayFees?: string | null;
          };
        }>;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          slug?: string | null;
          name?: string | null;
          park?: {
            __typename?: "Park";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
        } | null;
        allowedEquipment: Array<{
          __typename?: "Equipment";
          id: number;
          name: string;
          description?: string | null;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
        rating: {
          __typename?: "Rating";
          average?: number | null;
          count: number;
          distribution: Array<{
            __typename?: "RatingCount";
            rating: number;
            count: number;
          }>;
        };
      }>;
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    } | null;
  };
};

export type GetFacilityDetailsPageQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<FilterInput>;
  onlyMatches?: InputMaybe<Scalars["Boolean"]["input"]>;
  page?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  sortField?: InputMaybe<Scalars["String"]["input"]>;
  sortDirection?: InputMaybe<SortDirection>;
  reviewPage?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetFacilityDetailsPageQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    facility?: {
      __typename?: "Facility";
      id: number;
      slug?: string | null;
      type?: string | null;
      name?: string | null;
      url?: string | null;
      bookingUrl?: string | null;
      reservationUrl?: string | null;
      mapUrl?: string | null;
      checkinHour?: number | null;
      checkoutHour?: number | null;
      description?: string | null;
      descriptionHtml?: string | null;
      feeDescription?: string | null;
      rules?: string | null;
      directions?: string | null;
      stayLimit?: string | null;
      phone?: string | null;
      email?: string | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      favoritesCount?: number | null;
      unitCount: number;
      isWatched: boolean;
      isShared: boolean;
      version: number;
      isActive: boolean;
      updatedAt: any;
      checkRequested: boolean;
      nextCheckRequestAvailableAt?: any | null;
      lastCheckedAt?: any | null;
      park?: {
        __typename?: "Park";
        id: number;
        slug?: string | null;
        name?: string | null;
        description?: string | null;
        descriptionHtml?: string | null;
      } | null;
      nearbyFacilities: Array<{
        __typename?: "FacilityWithDistance";
        distanceMiles: number;
        facility: {
          __typename?: "Facility";
          id: number;
          slug?: string | null;
          type?: string | null;
          name?: string | null;
          url?: string | null;
          bookingUrl?: string | null;
          reservationUrl?: string | null;
          mapUrl?: string | null;
          checkinHour?: number | null;
          checkoutHour?: number | null;
          description?: string | null;
          descriptionHtml?: string | null;
          feeDescription?: string | null;
          rules?: string | null;
          directions?: string | null;
          stayLimit?: string | null;
          phone?: string | null;
          email?: string | null;
          isReservable?: boolean | null;
          isAdaAccessible?: boolean | null;
          favoritesCount?: number | null;
          unitCount: number;
          isWatched: boolean;
          isShared: boolean;
          version: number;
          isActive: boolean;
          updatedAt: any;
          checkRequested: boolean;
          nextCheckRequestAvailableAt?: any | null;
          lastCheckedAt?: any | null;
          location?: {
            __typename?: "Location";
            latitude?: number | null;
            longitude?: number | null;
          } | null;
          addresses: Array<{
            __typename?: "Address";
            id: number;
            type?: string | null;
            street: Array<string>;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
            country?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          closures: Array<{
            __typename?: "Closure";
            id: number;
            beginsAt?: any | null;
            endsAt?: any | null;
            reason?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          media: Array<{
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          primaryMedia?: {
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          } | null;
          rating: {
            __typename?: "Rating";
            average?: number | null;
            count: number;
            distribution: Array<{
              __typename?: "RatingCount";
              rating: number;
              count: number;
            }>;
          };
        };
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        firePit?: boolean | null;
        picnicTable?: boolean | null;
        waterHookup?: boolean | null;
        sewerHookup?: boolean | null;
        electricityHookupAmps?: number | null;
        drivewayLengthFeet?: number | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        favoritesCount?: number | null;
        isEquipmentMandatory?: boolean | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
        slots: Array<{
          __typename?: "Slot";
          id?: number | null;
          slotDate?: any | null;
          status?: SlotStatus | null;
          version: number;
          updatedAt: any;
          rate: {
            __typename?: "Rate";
            perNight?: number | null;
            perPerson?: number | null;
            fees?: number | null;
            displayRate?: string | null;
            displayFees?: string | null;
          };
        }>;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          slug?: string | null;
          name?: string | null;
          park?: {
            __typename?: "Park";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
        } | null;
        allowedEquipment: Array<{
          __typename?: "Equipment";
          id: number;
          name: string;
          description?: string | null;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
        rating: {
          __typename?: "Rating";
          average?: number | null;
          count: number;
          distribution: Array<{
            __typename?: "RatingCount";
            rating: number;
            count: number;
          }>;
        };
      }>;
      reviews: {
        __typename?: "ReviewResults";
        reviews: Array<{
          __typename?: "Review";
          id: string;
          reviewerName?: string | null;
          rating?: number | null;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
          reviewer?: {
            __typename?: "User";
            id: number;
            displayName: string;
            avatarImageUrl?: string | null;
          } | null;
          facility?: {
            __typename?: "Facility";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
          unit?: {
            __typename?: "Unit";
            id: number;
            slug?: string | null;
            name?: string | null;
            loopName?: string | null;
          } | null;
        }>;
        pagination: {
          __typename?: "PaginationResults";
          page: number;
          pageSize: number;
          totalResults: number;
        };
      };
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      addresses: Array<{
        __typename?: "Address";
        id: number;
        type?: string | null;
        street: Array<string>;
        city?: string | null;
        state?: string | null;
        zip?: string | null;
        country?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    } | null;
  };
};

export type GetUnitDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  reviewPage?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetUnitDetailsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    unit?: {
      __typename?: "Unit";
      id: number;
      slug?: string | null;
      type?: string | null;
      useType?: string | null;
      name?: string | null;
      loopName?: string | null;
      bookingUrl?: string | null;
      rules?: string | null;
      isGroup?: boolean | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      petsAllowed?: boolean | null;
      firePit?: boolean | null;
      picnicTable?: boolean | null;
      waterHookup?: boolean | null;
      sewerHookup?: boolean | null;
      electricityHookupAmps?: number | null;
      drivewayLengthFeet?: number | null;
      maxVehicles?: number | null;
      maxVehicleLengthFeet?: number | null;
      favoritesCount?: number | null;
      isEquipmentMandatory?: boolean | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      facility?: {
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        description?: string | null;
        descriptionHtml?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
          description?: string | null;
          descriptionHtml?: string | null;
        } | null;
      } | null;
      reviews: {
        __typename?: "ReviewResults";
        reviews: Array<{
          __typename?: "Review";
          id: string;
          reviewerName?: string | null;
          rating?: number | null;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
          reviewer?: {
            __typename?: "User";
            id: number;
            displayName: string;
            avatarImageUrl?: string | null;
          } | null;
          facility?: {
            __typename?: "Facility";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
          unit?: {
            __typename?: "Unit";
            id: number;
            slug?: string | null;
            name?: string | null;
            loopName?: string | null;
          } | null;
        }>;
        pagination: {
          __typename?: "PaginationResults";
          page: number;
          pageSize: number;
          totalResults: number;
        };
      };
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      peopleCapacity?: {
        __typename?: "IntRange";
        min?: number | null;
        max?: number | null;
      } | null;
      allowedEquipment: Array<{
        __typename?: "Equipment";
        id: number;
        name: string;
        description?: string | null;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    } | null;
  };
};

export type GetUnitDetailsWithSlotsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["Int"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  beginDate: Scalars["Date"]["input"];
  endDate: Scalars["Date"]["input"];
  reviewPage?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type GetUnitDetailsWithSlotsQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    unit?: {
      __typename?: "Unit";
      id: number;
      slug?: string | null;
      type?: string | null;
      useType?: string | null;
      name?: string | null;
      loopName?: string | null;
      bookingUrl?: string | null;
      rules?: string | null;
      isGroup?: boolean | null;
      isReservable?: boolean | null;
      isAdaAccessible?: boolean | null;
      petsAllowed?: boolean | null;
      firePit?: boolean | null;
      picnicTable?: boolean | null;
      waterHookup?: boolean | null;
      sewerHookup?: boolean | null;
      electricityHookupAmps?: number | null;
      drivewayLengthFeet?: number | null;
      maxVehicles?: number | null;
      maxVehicleLengthFeet?: number | null;
      favoritesCount?: number | null;
      isEquipmentMandatory?: boolean | null;
      version: number;
      isActive: boolean;
      updatedAt: any;
      slots: Array<{
        __typename?: "Slot";
        id?: number | null;
        slotDate?: any | null;
        status?: SlotStatus | null;
        version: number;
        updatedAt: any;
        rate: {
          __typename?: "Rate";
          perNight?: number | null;
          perPerson?: number | null;
          fees?: number | null;
          displayRate?: string | null;
          displayFees?: string | null;
        };
      }>;
      facility?: {
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        description?: string | null;
        descriptionHtml?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
          description?: string | null;
          descriptionHtml?: string | null;
        } | null;
      } | null;
      reviews: {
        __typename?: "ReviewResults";
        reviews: Array<{
          __typename?: "Review";
          id: string;
          reviewerName?: string | null;
          rating?: number | null;
          comment?: string | null;
          createdAt: any;
          updatedAt: any;
          reviewer?: {
            __typename?: "User";
            id: number;
            displayName: string;
            avatarImageUrl?: string | null;
          } | null;
          facility?: {
            __typename?: "Facility";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
          unit?: {
            __typename?: "Unit";
            id: number;
            slug?: string | null;
            name?: string | null;
            loopName?: string | null;
          } | null;
        }>;
        pagination: {
          __typename?: "PaginationResults";
          page: number;
          pageSize: number;
          totalResults: number;
        };
      };
      location?: {
        __typename?: "Location";
        latitude?: number | null;
        longitude?: number | null;
      } | null;
      peopleCapacity?: {
        __typename?: "IntRange";
        min?: number | null;
        max?: number | null;
      } | null;
      allowedEquipment: Array<{
        __typename?: "Equipment";
        id: number;
        name: string;
        description?: string | null;
        isActive: boolean;
        updatedAt: any;
      }>;
      closures: Array<{
        __typename?: "Closure";
        id: number;
        beginsAt?: any | null;
        endsAt?: any | null;
        reason?: string | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      media: Array<{
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      }>;
      primaryMedia?: {
        __typename?: "Media";
        id?: number | null;
        type?: string | null;
        title?: string | null;
        subtitle?: string | null;
        description?: string | null;
        credits?: string | null;
        url?: string | null;
        width?: number | null;
        height?: number | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
      } | null;
      rating: {
        __typename?: "Rating";
        average?: number | null;
        count: number;
        distribution: Array<{
          __typename?: "RatingCount";
          rating: number;
          count: number;
        }>;
      };
    } | null;
  };
};

export type GetCurrentUserQueryVariables = Exact<{[key: string]: never}>;

export type GetCurrentUserQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UsersQuery";
    currentUser?: {
      __typename?: "User";
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      settingsJson?: string | null;
      maxWatchCredits: number;
      usedWatchCredits: number;
      permissions?: Array<Permissions> | null;
      version?: number | null;
      isActive?: boolean | null;
      updatedAt?: any | null;
      isSso?: boolean | null;
      id: number;
      displayName: string;
      avatarImageUrl?: string | null;
    } | null;
  };
};

export type GetMaxDateQueryVariables = Exact<{[key: string]: never}>;

export type GetMaxDateQuery = {
  __typename?: "Query";
  search: {__typename?: "SearchQuery"; maxDate?: any | null};
};

export type GetLocationQueryVariables = Exact<{[key: string]: never}>;

export type GetLocationQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UsersQuery";
    location?: {
      __typename?: "Location";
      latitude?: number | null;
      longitude?: number | null;
    } | null;
  };
};

export type GetFeatureFlagQueryVariables = Exact<{
  flag: Scalars["String"]["input"];
}>;

export type GetFeatureFlagQuery = {
  __typename?: "Query";
  features: {__typename?: "FeaturesQuery"; isEnabled: boolean};
};

export type GetCheckoutConfigQueryVariables = Exact<{[key: string]: never}>;

export type GetCheckoutConfigQuery = {
  __typename?: "Query";
  subscriptions: {
    __typename?: "SubscriptionsQuery";
    checkoutConfig: {
      __typename?: "CheckoutConfig";
      publishableKey: string;
      plans: Array<{
        __typename?: "SubscriptionPlan";
        id: string;
        name?: string | null;
        description?: string | null;
        amount: number;
        currency: string;
        interval: string;
      }>;
    };
  };
};

export type GetWatchListQueryVariables = Exact<{
  status?: InputMaybe<WatchStatus>;
}>;

export type GetWatchListQuery = {
  __typename?: "Query";
  watches: {
    __typename?: "WatchesQuery";
    watchList: Array<{
      __typename?: "Watch";
      id: number;
      name?: string | null;
      displayName: string;
      scheduleDescription: string;
      status: WatchStatus;
      expiresAt?: any | null;
      lastScannedAt?: any | null;
      alertsEnabled: boolean;
      version: number;
      createdAt: any;
      updatedAt: any;
      creator: {__typename?: "User"; id: number};
      filter: {
        __typename?: "Filter";
        nights?: number | null;
        excludeGroup: boolean;
        onlyPetsAllowed: boolean;
        onlyAccessible: boolean;
        excludeMandatoryEquipment: boolean;
        maxVehicleLengthFeet?: number | null;
        vehicles?: number | null;
        dates?: {
          __typename?: "DateRange";
          min?: any | null;
          max?: any | null;
        } | null;
        people?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
      };
      parks: Array<{
        __typename?: "Park";
        id: number;
        slug?: string | null;
        name?: string | null;
      }>;
      facilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        name?: string | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          slug?: string | null;
          name?: string | null;
          park?: {
            __typename?: "Park";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
        } | null;
      }>;
      effectiveFacilities: Array<{
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
      foundFacilities: Array<{
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
    }>;
  };
};

export type GetWatchQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
}>;

export type GetWatchQuery = {
  __typename?: "Query";
  watches: {
    __typename?: "WatchesQuery";
    watch: {
      __typename?: "Watch";
      id: number;
      name?: string | null;
      displayName: string;
      scheduleDescription: string;
      status: WatchStatus;
      expiresAt?: any | null;
      lastScannedAt?: any | null;
      alertsEnabled: boolean;
      version: number;
      createdAt: any;
      updatedAt: any;
      creator: {__typename?: "User"; id: number};
      filter: {
        __typename?: "Filter";
        nights?: number | null;
        excludeGroup: boolean;
        onlyPetsAllowed: boolean;
        onlyAccessible: boolean;
        excludeMandatoryEquipment: boolean;
        maxVehicleLengthFeet?: number | null;
        vehicles?: number | null;
        dates?: {
          __typename?: "DateRange";
          min?: any | null;
          max?: any | null;
        } | null;
        people?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
      };
      parks: Array<{
        __typename?: "Park";
        id: number;
        slug?: string | null;
        name?: string | null;
      }>;
      facilities: Array<{
        __typename?: "Facility";
        id: number;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
      units: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        name?: string | null;
        facility?: {
          __typename?: "Facility";
          id: number;
          slug?: string | null;
          name?: string | null;
          park?: {
            __typename?: "Park";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
        } | null;
      }>;
      effectiveFacilities: Array<{
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
      foundFacilities: Array<{
        __typename?: "Facility";
        id: number;
        slug?: string | null;
        name?: string | null;
        park?: {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
        } | null;
      }>;
    };
  };
};

export type GetWatchFoundUnitsQueryVariables = Exact<{
  id: Scalars["Int"]["input"];
  beginDate: Scalars["Date"]["input"];
  endDate: Scalars["Date"]["input"];
}>;

export type GetWatchFoundUnitsQuery = {
  __typename?: "Query";
  watches: {
    __typename?: "WatchesQuery";
    watch: {
      __typename?: "Watch";
      id: number;
      foundUnits: Array<{
        __typename?: "Unit";
        id: number;
        slug?: string | null;
        type?: string | null;
        useType?: string | null;
        name?: string | null;
        loopName?: string | null;
        bookingUrl?: string | null;
        rules?: string | null;
        isGroup?: boolean | null;
        isReservable?: boolean | null;
        isAdaAccessible?: boolean | null;
        petsAllowed?: boolean | null;
        firePit?: boolean | null;
        picnicTable?: boolean | null;
        waterHookup?: boolean | null;
        sewerHookup?: boolean | null;
        electricityHookupAmps?: number | null;
        drivewayLengthFeet?: number | null;
        maxVehicles?: number | null;
        maxVehicleLengthFeet?: number | null;
        favoritesCount?: number | null;
        isEquipmentMandatory?: boolean | null;
        version: number;
        isActive: boolean;
        updatedAt: any;
        facility?: {
          __typename?: "Facility";
          id: number;
          slug?: string | null;
          name?: string | null;
          unitCount: number;
          type?: string | null;
          url?: string | null;
          bookingUrl?: string | null;
          reservationUrl?: string | null;
          mapUrl?: string | null;
          checkinHour?: number | null;
          checkoutHour?: number | null;
          description?: string | null;
          descriptionHtml?: string | null;
          feeDescription?: string | null;
          rules?: string | null;
          directions?: string | null;
          stayLimit?: string | null;
          phone?: string | null;
          email?: string | null;
          isReservable?: boolean | null;
          isAdaAccessible?: boolean | null;
          favoritesCount?: number | null;
          isWatched: boolean;
          isShared: boolean;
          version: number;
          isActive: boolean;
          updatedAt: any;
          checkRequested: boolean;
          nextCheckRequestAvailableAt?: any | null;
          lastCheckedAt?: any | null;
          park?: {
            __typename?: "Park";
            id: number;
            slug?: string | null;
            name?: string | null;
          } | null;
          location?: {
            __typename?: "Location";
            latitude?: number | null;
            longitude?: number | null;
          } | null;
          addresses: Array<{
            __typename?: "Address";
            id: number;
            type?: string | null;
            street: Array<string>;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
            country?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          closures: Array<{
            __typename?: "Closure";
            id: number;
            beginsAt?: any | null;
            endsAt?: any | null;
            reason?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          media: Array<{
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          primaryMedia?: {
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          } | null;
          rating: {
            __typename?: "Rating";
            average?: number | null;
            count: number;
            distribution: Array<{
              __typename?: "RatingCount";
              rating: number;
              count: number;
            }>;
          };
        } | null;
        slots: Array<{
          __typename?: "Slot";
          id?: number | null;
          slotDate?: any | null;
          status?: SlotStatus | null;
          version: number;
          updatedAt: any;
          rate: {
            __typename?: "Rate";
            perNight?: number | null;
            perPerson?: number | null;
            fees?: number | null;
            displayRate?: string | null;
            displayFees?: string | null;
          };
        }>;
        location?: {
          __typename?: "Location";
          latitude?: number | null;
          longitude?: number | null;
        } | null;
        peopleCapacity?: {
          __typename?: "IntRange";
          min?: number | null;
          max?: number | null;
        } | null;
        allowedEquipment: Array<{
          __typename?: "Equipment";
          id: number;
          name: string;
          description?: string | null;
          isActive: boolean;
          updatedAt: any;
        }>;
        closures: Array<{
          __typename?: "Closure";
          id: number;
          beginsAt?: any | null;
          endsAt?: any | null;
          reason?: string | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        media: Array<{
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        }>;
        primaryMedia?: {
          __typename?: "Media";
          id?: number | null;
          type?: string | null;
          title?: string | null;
          subtitle?: string | null;
          description?: string | null;
          credits?: string | null;
          url?: string | null;
          width?: number | null;
          height?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
        } | null;
        rating: {
          __typename?: "Rating";
          average?: number | null;
          count: number;
          distribution: Array<{
            __typename?: "RatingCount";
            rating: number;
            count: number;
          }>;
        };
      }>;
    };
  };
};

export type GetWatchNameAvailableQueryVariables = Exact<{
  name?: Scalars["String"]["input"];
}>;

export type GetWatchNameAvailableQuery = {
  __typename?: "Query";
  watches: {__typename?: "WatchesQuery"; nameAvailable: boolean};
};

export type GetRecentlyCheckedFacilitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetRecentlyCheckedFacilitiesQuery = {
  __typename?: "Query";
  entities: {
    __typename?: "EntitiesQuery";
    recentlyCheckedFacilities: Array<{
      __typename?: "Facility";
      id: number;
      slug?: string | null;
      name?: string | null;
      lastCheckedAt?: any | null;
      park?: {
        __typename?: "Park";
        id: number;
        slug?: string | null;
        name?: string | null;
      } | null;
    }>;
  };
};

export type CalculateUsedCreditsQueryVariables = Exact<{
  parkIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
  facilityIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
  unitIds: Array<Scalars["Int"]["input"]> | Scalars["Int"]["input"];
}>;

export type CalculateUsedCreditsQuery = {
  __typename?: "Query";
  subscriptions: {
    __typename?: "SubscriptionsQuery";
    calculateUsedCredits: number;
  };
};

export type EmailTakenQueryVariables = Exact<{
  email: Scalars["String"]["input"];
  captchaResponse: Scalars["String"]["input"];
}>;

export type EmailTakenQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UsersQuery";
    emailTaken:
      | {__typename?: "EmailTakenSuccessResult"; email: string; taken: boolean}
      | {
          __typename?: "FormError";
          field?: string | null;
          message?: string | null;
        };
  };
};

export type GetContentListQueryVariables = Exact<{
  type: Scalars["String"]["input"];
  page?: Scalars["Int"]["input"];
}>;

export type GetContentListQuery = {
  __typename?: "Query";
  cms: {
    __typename?: "CMSQuery";
    contentList: {
      __typename?: "ContentList";
      contentList: Array<{
        __typename?: "Content";
        type: string;
        slug?: string | null;
        title?: string | null;
        tagline?: string | null;
        updatedAt: any;
      }>;
      pagination: {
        __typename?: "PaginationResults";
        page: number;
        pageSize: number;
        totalResults: number;
      };
    };
  };
};

export type GetContentSummaryQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
}>;

export type GetContentSummaryQuery = {
  __typename?: "Query";
  cms: {
    __typename?: "CMSQuery";
    content?: {
      __typename?: "Content";
      type: string;
      id: number;
      slug?: string | null;
      status: ContentStatus;
      title?: string | null;
      tagline?: string | null;
      bodyMarkdown?: string | null;
      isFeatured: boolean;
      sortOrder?: number | null;
      showCreator: boolean;
      createdAt: any;
      updatedAt: any;
      version: number;
      creator: {
        __typename?: "User";
        id: number;
        displayName: string;
        avatarImageUrl?: string | null;
      };
    } | null;
  };
};

export type GetContentDetailsQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
}>;

export type GetContentDetailsQuery = {
  __typename?: "Query";
  cms: {
    __typename?: "CMSQuery";
    content?: {
      __typename?: "Content";
      type: string;
      id: number;
      slug?: string | null;
      status: ContentStatus;
      title?: string | null;
      tagline?: string | null;
      bodyMarkdown?: string | null;
      isFeatured: boolean;
      sortOrder?: number | null;
      showCreator: boolean;
      createdAt: any;
      updatedAt: any;
      version: number;
      attachments: Array<{
        __typename?: "ContentAttachment";
        id: string;
        contentId: number;
        title?: string | null;
        fileName: string;
        mimeType: string;
        dataUrl: string;
        createdAt: any;
        updatedAt: any;
      }>;
      creator: {
        __typename?: "User";
        id: number;
        displayName: string;
        avatarImageUrl?: string | null;
      };
    } | null;
  };
};

export type ContentSlugAvailableQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
}>;

export type ContentSlugAvailableQuery = {
  __typename?: "Query";
  cms: {__typename?: "CMSQuery"; slugAvailable: boolean};
};

export type GetInvitationDefaultsQueryVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type GetInvitationDefaultsQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UsersQuery";
    invitationDefaults: {__typename?: "InvitationDefaults"; email: string};
  };
};

export type CountSubscriptionVariables = Exact<{
  target?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CountSubscription = {__typename?: "Subscription"; count: number};

export type ChatMessagesSubscriptionVariables = Exact<{[key: string]: never}>;

export type ChatMessagesSubscription = {
  __typename?: "Subscription";
  chatMessages: {
    __typename?: "ChatMessage";
    id?: string | null;
    message: string;
    sentAt: any;
    deletedAt?: any | null;
    sender?: {
      __typename?: "User";
      id: number;
      displayName: string;
      avatarImageUrl?: string | null;
    } | null;
    recipient?: {
      __typename?: "User";
      id: number;
      displayName: string;
      avatarImageUrl?: string | null;
    } | null;
  };
};

export type UpdatesSubscriptionVariables = Exact<{
  inputs: Array<UpdateInput> | UpdateInput;
}>;

export type UpdatesSubscription = {
  __typename?: "Subscription";
  updates: {
    __typename?: "Update";
    updatedAt: any;
    modelClass: string;
    modelId: string;
    model?:
      | {
          __typename?: "Facility";
          id: number;
          slug?: string | null;
          type?: string | null;
          name?: string | null;
          url?: string | null;
          bookingUrl?: string | null;
          reservationUrl?: string | null;
          mapUrl?: string | null;
          checkinHour?: number | null;
          checkoutHour?: number | null;
          description?: string | null;
          descriptionHtml?: string | null;
          feeDescription?: string | null;
          rules?: string | null;
          directions?: string | null;
          stayLimit?: string | null;
          phone?: string | null;
          email?: string | null;
          isReservable?: boolean | null;
          isAdaAccessible?: boolean | null;
          favoritesCount?: number | null;
          unitCount: number;
          isWatched: boolean;
          isShared: boolean;
          version: number;
          isActive: boolean;
          updatedAt: any;
          checkRequested: boolean;
          nextCheckRequestAvailableAt?: any | null;
          lastCheckedAt?: any | null;
          units: Array<{
            __typename?: "Unit";
            id: number;
            slug?: string | null;
            type?: string | null;
            useType?: string | null;
            name?: string | null;
            loopName?: string | null;
            bookingUrl?: string | null;
            rules?: string | null;
            isGroup?: boolean | null;
            isReservable?: boolean | null;
            isAdaAccessible?: boolean | null;
            petsAllowed?: boolean | null;
            maxVehicles?: number | null;
            maxVehicleLengthFeet?: number | null;
            isEquipmentMandatory?: boolean | null;
            location?: {
              __typename?: "Location";
              latitude?: number | null;
              longitude?: number | null;
            } | null;
            peopleCapacity?: {
              __typename?: "IntRange";
              min?: number | null;
              max?: number | null;
            } | null;
            primaryMedia?: {
              __typename?: "Media";
              id?: number | null;
              type?: string | null;
              title?: string | null;
              subtitle?: string | null;
              description?: string | null;
              credits?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              version: number;
              isActive: boolean;
              updatedAt: any;
            } | null;
          }>;
          location?: {
            __typename?: "Location";
            latitude?: number | null;
            longitude?: number | null;
          } | null;
          addresses: Array<{
            __typename?: "Address";
            id: number;
            type?: string | null;
            street: Array<string>;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
            country?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          closures: Array<{
            __typename?: "Closure";
            id: number;
            beginsAt?: any | null;
            endsAt?: any | null;
            reason?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          media: Array<{
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          primaryMedia?: {
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          } | null;
          rating: {
            __typename?: "Rating";
            average?: number | null;
            count: number;
            distribution: Array<{
              __typename?: "RatingCount";
              rating: number;
              count: number;
            }>;
          };
        }
      | {
          __typename?: "Park";
          id: number;
          slug?: string | null;
          name?: string | null;
          description?: string | null;
          descriptionHtml?: string | null;
          feeDescription?: string | null;
          directions?: string | null;
          stayLimit?: string | null;
          phone?: string | null;
          email?: string | null;
          reservationUrl?: string | null;
          mapUrl?: string | null;
          isReservable?: boolean | null;
          geometryUrl?: string | null;
          organizationId?: number | null;
          favoritesCount?: number | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
          location?: {
            __typename?: "Location";
            latitude?: number | null;
            longitude?: number | null;
          } | null;
          addresses: Array<{
            __typename?: "Address";
            id: number;
            type?: string | null;
            street: Array<string>;
            city?: string | null;
            state?: string | null;
            zip?: string | null;
            country?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          closures: Array<{
            __typename?: "Closure";
            id: number;
            beginsAt?: any | null;
            endsAt?: any | null;
            reason?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          media: Array<{
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          primaryMedia?: {
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          } | null;
          facilities: Array<{
            __typename?: "Facility";
            unitCount: number;
            id: number;
            slug?: string | null;
            type?: string | null;
            name?: string | null;
            url?: string | null;
            bookingUrl?: string | null;
            reservationUrl?: string | null;
            mapUrl?: string | null;
            checkinHour?: number | null;
            checkoutHour?: number | null;
            description?: string | null;
            descriptionHtml?: string | null;
            feeDescription?: string | null;
            rules?: string | null;
            directions?: string | null;
            stayLimit?: string | null;
            phone?: string | null;
            email?: string | null;
            isReservable?: boolean | null;
            isAdaAccessible?: boolean | null;
            favoritesCount?: number | null;
            isWatched: boolean;
            isShared: boolean;
            version: number;
            isActive: boolean;
            updatedAt: any;
            checkRequested: boolean;
            nextCheckRequestAvailableAt?: any | null;
            lastCheckedAt?: any | null;
            location?: {
              __typename?: "Location";
              latitude?: number | null;
              longitude?: number | null;
            } | null;
            addresses: Array<{
              __typename?: "Address";
              id: number;
              type?: string | null;
              street: Array<string>;
              city?: string | null;
              state?: string | null;
              zip?: string | null;
              country?: string | null;
              version: number;
              isActive: boolean;
              updatedAt: any;
            }>;
            closures: Array<{
              __typename?: "Closure";
              id: number;
              beginsAt?: any | null;
              endsAt?: any | null;
              reason?: string | null;
              version: number;
              isActive: boolean;
              updatedAt: any;
            }>;
            media: Array<{
              __typename?: "Media";
              id?: number | null;
              type?: string | null;
              title?: string | null;
              subtitle?: string | null;
              description?: string | null;
              credits?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              version: number;
              isActive: boolean;
              updatedAt: any;
            }>;
            primaryMedia?: {
              __typename?: "Media";
              id?: number | null;
              type?: string | null;
              title?: string | null;
              subtitle?: string | null;
              description?: string | null;
              credits?: string | null;
              url?: string | null;
              width?: number | null;
              height?: number | null;
              version: number;
              isActive: boolean;
              updatedAt: any;
            } | null;
            rating: {
              __typename?: "Rating";
              average?: number | null;
              count: number;
              distribution: Array<{
                __typename?: "RatingCount";
                rating: number;
                count: number;
              }>;
            };
          }>;
          rating: {
            __typename?: "Rating";
            average?: number | null;
            count: number;
            distribution: Array<{
              __typename?: "RatingCount";
              rating: number;
              count: number;
            }>;
          };
        }
      | {__typename?: "Place"}
      | {
          __typename?: "Unit";
          id: number;
          slug?: string | null;
          type?: string | null;
          useType?: string | null;
          name?: string | null;
          loopName?: string | null;
          bookingUrl?: string | null;
          rules?: string | null;
          isGroup?: boolean | null;
          isReservable?: boolean | null;
          isAdaAccessible?: boolean | null;
          petsAllowed?: boolean | null;
          firePit?: boolean | null;
          picnicTable?: boolean | null;
          waterHookup?: boolean | null;
          sewerHookup?: boolean | null;
          electricityHookupAmps?: number | null;
          drivewayLengthFeet?: number | null;
          maxVehicles?: number | null;
          maxVehicleLengthFeet?: number | null;
          favoritesCount?: number | null;
          isEquipmentMandatory?: boolean | null;
          version: number;
          isActive: boolean;
          updatedAt: any;
          location?: {
            __typename?: "Location";
            latitude?: number | null;
            longitude?: number | null;
          } | null;
          peopleCapacity?: {
            __typename?: "IntRange";
            min?: number | null;
            max?: number | null;
          } | null;
          facility?: {
            __typename?: "Facility";
            id: number;
            slug?: string | null;
            name?: string | null;
            park?: {
              __typename?: "Park";
              id: number;
              slug?: string | null;
              name?: string | null;
            } | null;
          } | null;
          allowedEquipment: Array<{
            __typename?: "Equipment";
            id: number;
            name: string;
            description?: string | null;
            isActive: boolean;
            updatedAt: any;
          }>;
          closures: Array<{
            __typename?: "Closure";
            id: number;
            beginsAt?: any | null;
            endsAt?: any | null;
            reason?: string | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          media: Array<{
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          }>;
          primaryMedia?: {
            __typename?: "Media";
            id?: number | null;
            type?: string | null;
            title?: string | null;
            subtitle?: string | null;
            description?: string | null;
            credits?: string | null;
            url?: string | null;
            width?: number | null;
            height?: number | null;
            version: number;
            isActive: boolean;
            updatedAt: any;
          } | null;
          rating: {
            __typename?: "Rating";
            average?: number | null;
            count: number;
            distribution: Array<{
              __typename?: "RatingCount";
              rating: number;
              count: number;
            }>;
          };
        }
      | null;
  };
};

export const PaginationFieldsFragmentDoc = gql`
  fragment PaginationFields on PaginationResults {
    page
    pageSize
    totalResults
  }
`;
export const MediaMarkerFieldsFragmentDoc = gql`
  fragment MediaMarkerFields on Media {
    id
    title
    url
    width
    height
  }
`;
export const FacilityMarkerFieldsFragmentDoc = gql`
  fragment FacilityMarkerFields on Facility {
    location {
      latitude
      longitude
    }
    id
    slug
    name
    primaryMedia {
      ...MediaMarkerFields
    }
    checkRequested
    nextCheckRequestAvailableAt
    lastCheckedAt
  }
  ${MediaMarkerFieldsFragmentDoc}
`;
export const UnitMarkerFieldsFragmentDoc = gql`
  fragment UnitMarkerFields on Unit {
    location {
      latitude
      longitude
    }
    id
    slug
    name
    primaryMedia {
      ...MediaMarkerFields
    }
    facility {
      ...FacilityMarkerFields
    }
  }
  ${MediaMarkerFieldsFragmentDoc}
  ${FacilityMarkerFieldsFragmentDoc}
`;
export const AddressFieldsFragmentDoc = gql`
  fragment AddressFields on Address {
    id
    type
    street
    city
    state
    zip
    country
    version
    isActive
    updatedAt
  }
`;
export const OrganizationFieldsFragmentDoc = gql`
  fragment OrganizationFields on Organization {
    id
    name
    url
    addresses {
      ...AddressFields
    }
    version
    isActive
    updatedAt
  }
  ${AddressFieldsFragmentDoc}
`;
export const ParkMarkerFieldsFragmentDoc = gql`
  fragment ParkMarkerFields on Park {
    location {
      latitude
      longitude
    }
    id
    slug
    name
    primaryMedia {
      ...MediaMarkerFields
    }
  }
  ${MediaMarkerFieldsFragmentDoc}
`;
export const SlotFieldsFragmentDoc = gql`
  fragment SlotFields on Slot {
    id
    slotDate
    status
    version
    updatedAt
    rate {
      perNight
      perPerson
      fees
      displayRate
      displayFees
    }
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    displayName
    avatarImageUrl
  }
`;
export const AuthenticatedUserFieldsFragmentDoc = gql`
  fragment AuthenticatedUserFields on User {
    ...UserFields
    firstName
    lastName
    email
    settingsJson
    maxWatchCredits
    usedWatchCredits
    permissions
    version
    isActive
    updatedAt
    isSso
  }
  ${UserFieldsFragmentDoc}
`;
export const AdminUserFieldsFragmentDoc = gql`
  fragment AdminUserFields on User {
    roles
  }
`;
export const ChatMessageFieldsFragmentDoc = gql`
  fragment ChatMessageFields on ChatMessage {
    id
    sender {
      ...UserFields
    }
    recipient {
      ...UserFields
    }
    message
    sentAt
    deletedAt
  }
  ${UserFieldsFragmentDoc}
`;
export const FilterFieldsFragmentDoc = gql`
  fragment FilterFields on Filter {
    dates {
      min
      max
    }
    nights
    people {
      min
      max
    }
    excludeGroup
    onlyPetsAllowed
    onlyAccessible
    excludeMandatoryEquipment
    maxVehicleLengthFeet
    vehicles
  }
`;
export const WatchFieldsFragmentDoc = gql`
  fragment WatchFields on Watch {
    id
    name
    displayName
    creator {
      id
    }
    filter {
      ...FilterFields
    }
    scheduleDescription
    status
    expiresAt
    lastScannedAt
    alertsEnabled
    version
    createdAt
    updatedAt
    parks {
      id
      slug
      name
    }
    facilities {
      id
      name
      park {
        id
        slug
        name
      }
    }
    units {
      id
      slug
      name
      facility {
        id
        slug
        name
        park {
          id
          slug
          name
        }
      }
    }
    effectiveFacilities {
      id
      slug
      name
      park {
        id
        slug
        name
      }
    }
    foundFacilities {
      id
      slug
      name
      park {
        id
        slug
        name
      }
    }
  }
  ${FilterFieldsFragmentDoc}
`;
export const ClosureFieldsFragmentDoc = gql`
  fragment ClosureFields on Closure {
    id
    beginsAt
    endsAt
    reason
    version
    isActive
    updatedAt
  }
`;
export const MediaFieldsFragmentDoc = gql`
  fragment MediaFields on Media {
    id
    type
    title
    subtitle
    description
    credits
    url
    width
    height
    version
    isActive
    updatedAt
  }
`;
export const RatingFieldsFragmentDoc = gql`
  fragment RatingFields on Rating {
    average
    count
    distribution {
      rating
      count
    }
  }
`;
export const FacilityCommonFieldsFragmentDoc = gql`
  fragment FacilityCommonFields on Facility {
    id
    slug
    type
    name
    url
    bookingUrl
    reservationUrl
    mapUrl
    checkinHour
    checkoutHour
    description
    descriptionHtml
    feeDescription
    rules
    directions
    stayLimit
    phone
    email
    isReservable
    isAdaAccessible
    location {
      latitude
      longitude
    }
    favoritesCount
    unitCount
    addresses {
      ...AddressFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    rating {
      ...RatingFields
    }
    isWatched
    isShared
    version
    isActive
    updatedAt
    checkRequested
    nextCheckRequestAvailableAt
    lastCheckedAt
  }
  ${AddressFieldsFragmentDoc}
  ${ClosureFieldsFragmentDoc}
  ${MediaFieldsFragmentDoc}
  ${RatingFieldsFragmentDoc}
`;
export const FacilityWithoutUnitsFieldsFragmentDoc = gql`
  fragment FacilityWithoutUnitsFields on Facility {
    ...FacilityCommonFields
    unitCount
  }
  ${FacilityCommonFieldsFragmentDoc}
`;
export const ParkFieldsFragmentDoc = gql`
  fragment ParkFields on Park {
    id
    slug
    name
    description
    descriptionHtml
    feeDescription
    directions
    stayLimit
    phone
    email
    reservationUrl
    mapUrl
    isReservable
    location {
      latitude
      longitude
    }
    geometryUrl
    organizationId
    favoritesCount
    addresses {
      ...AddressFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    facilities {
      ...FacilityWithoutUnitsFields
    }
    rating {
      ...RatingFields
    }
    version
    isActive
    updatedAt
  }
  ${AddressFieldsFragmentDoc}
  ${ClosureFieldsFragmentDoc}
  ${MediaFieldsFragmentDoc}
  ${FacilityWithoutUnitsFieldsFragmentDoc}
  ${RatingFieldsFragmentDoc}
`;
export const FacilityUnitFieldsFragmentDoc = gql`
  fragment FacilityUnitFields on Unit {
    id
    slug
    type
    useType
    name
    loopName
    location {
      latitude
      longitude
    }
    bookingUrl
    peopleCapacity {
      min
      max
    }
    rules
    isGroup
    isReservable
    isAdaAccessible
    petsAllowed
    maxVehicles
    maxVehicleLengthFeet
    isEquipmentMandatory
    primaryMedia {
      ...MediaFields
    }
  }
  ${MediaFieldsFragmentDoc}
`;
export const FacilityFieldsFragmentDoc = gql`
  fragment FacilityFields on Facility {
    ...FacilityCommonFields
    units {
      ...FacilityUnitFields
    }
  }
  ${FacilityCommonFieldsFragmentDoc}
  ${FacilityUnitFieldsFragmentDoc}
`;
export const EquipmentFieldsFragmentDoc = gql`
  fragment EquipmentFields on Equipment {
    id
    name
    description
    isActive
    updatedAt
  }
`;
export const UnitFieldsFragmentDoc = gql`
  fragment UnitFields on Unit {
    id
    slug
    type
    useType
    name
    loopName
    location {
      latitude
      longitude
    }
    bookingUrl
    peopleCapacity {
      min
      max
    }
    rules
    isGroup
    isReservable
    isAdaAccessible
    petsAllowed
    firePit
    picnicTable
    waterHookup
    sewerHookup
    electricityHookupAmps
    drivewayLengthFeet
    maxVehicles
    maxVehicleLengthFeet
    favoritesCount
    isEquipmentMandatory
    facility {
      id
      slug
      name
      park {
        id
        slug
        name
      }
    }
    allowedEquipment {
      ...EquipmentFields
    }
    closures {
      ...ClosureFields
    }
    media {
      ...MediaFields
    }
    primaryMedia {
      ...MediaFields
    }
    rating {
      ...RatingFields
    }
    version
    isActive
    updatedAt
  }
  ${EquipmentFieldsFragmentDoc}
  ${ClosureFieldsFragmentDoc}
  ${MediaFieldsFragmentDoc}
  ${RatingFieldsFragmentDoc}
`;
export const SearchableFieldsFragmentDoc = gql`
  fragment SearchableFields on SearchResultType {
    ... on Park {
      ...ParkFields
    }
    ... on Facility {
      ...FacilityFields
    }
    ... on Unit {
      ...UnitFields
    }
  }
  ${ParkFieldsFragmentDoc}
  ${FacilityFieldsFragmentDoc}
  ${UnitFieldsFragmentDoc}
`;
export const ContentFieldsFragmentDoc = gql`
  fragment ContentFields on Content {
    type
    id
    slug
    status
    title
    tagline
    bodyMarkdown
    isFeatured
    sortOrder
    creator {
      ...UserFields
    }
    showCreator
    createdAt
    updatedAt
    version
  }
  ${UserFieldsFragmentDoc}
`;
export const ContentAttachmentFieldsFragmentDoc = gql`
  fragment ContentAttachmentFields on ContentAttachment {
    id
    contentId
    title
    fileName
    mimeType
    dataUrl
    createdAt
    updatedAt
  }
`;
export const ReviewFieldsFragmentDoc = gql`
  fragment ReviewFields on Review {
    id
    reviewer {
      id
      displayName
      avatarImageUrl
    }
    reviewerName
    rating
    comment
    facility {
      id
      slug
      name
    }
    unit {
      id
      slug
      name
      loopName
    }
    createdAt
    updatedAt
  }
`;
export const SignUpDocument = gql`
  mutation SignUp(
    $email: String!
    $firstName: String!
    $lastName: String!
    $captchaResponse: String!
    $reason: String
    $referral: String
    $invitationToken: String
    $acceptedTerms: Boolean!
  ) {
    users {
      signUp(
        input: {
          email: $email
          firstName: $firstName
          lastName: $lastName
          captchaResponse: $captchaResponse
          reason: $reason
          referral: $referral
          invitationToken: $invitationToken
          acceptedTerms: $acceptedTerms
        }
      ) {
        ... on SignUpSuccessResult {
          userId
          email
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      captchaResponse: // value for 'captchaResponse'
 *      reason: // value for 'reason'
 *      referral: // value for 'referral'
 *      invitationToken: // value for 'invitationToken'
 *      acceptedTerms: // value for 'acceptedTerms'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword(
    $token: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    users {
      resetPassword(
        input: {
          token: $token
          newPassword: $newPassword
          newPasswordConfirmation: $newPasswordConfirmation
        }
      ) {
        ... on ResetPasswordSuccessResult {
          success
          email
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirmation: // value for 'newPasswordConfirmation'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const ResetPasswordRequestDocument = gql`
  mutation ResetPasswordRequest($email: String!, $captchaResponse: String!) {
    users {
      resetPasswordRequest(
        input: {email: $email, captchaResponse: $captchaResponse}
      ) {
        ... on ResetPasswordSuccessResult {
          success
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;
export type ResetPasswordRequestMutationFn = Apollo.MutationFunction<
  ResetPasswordRequestMutation,
  ResetPasswordRequestMutationVariables
>;

/**
 * __useResetPasswordRequestMutation__
 *
 * To run a mutation, you first call `useResetPasswordRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordRequestMutation, { data, loading, error }] = useResetPasswordRequestMutation({
 *   variables: {
 *      email: // value for 'email'
 *      captchaResponse: // value for 'captchaResponse'
 *   },
 * });
 */
export function useResetPasswordRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordRequestMutation,
    ResetPasswordRequestMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    ResetPasswordRequestMutation,
    ResetPasswordRequestMutationVariables
  >(ResetPasswordRequestDocument, options);
}
export type ResetPasswordRequestMutationHookResult = ReturnType<
  typeof useResetPasswordRequestMutation
>;
export type ResetPasswordRequestMutationResult =
  Apollo.MutationResult<ResetPasswordRequestMutation>;
export type ResetPasswordRequestMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordRequestMutation,
  ResetPasswordRequestMutationVariables
>;
export const UpdateUserSettingsDocument = gql`
  mutation UpdateUserSettings(
    $firstName: String
    $lastName: String
    $email: String
    $oldPassword: String
    $newPassword: String
    $partialSettingsJson: String
  ) {
    users {
      updateSettings(
        input: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          oldPassword: $oldPassword
          newPassword: $newPassword
          partialSettingsJson: $partialSettingsJson
        }
      ) {
        ...AuthenticatedUserFields
      }
    }
  }
  ${AuthenticatedUserFieldsFragmentDoc}
`;
export type UpdateUserSettingsMutationFn = Apollo.MutationFunction<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;

/**
 * __useUpdateUserSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateUserSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSettingsMutation, { data, loading, error }] = useUpdateUserSettingsMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *      partialSettingsJson: // value for 'partialSettingsJson'
 *   },
 * });
 */
export function useUpdateUserSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateUserSettingsMutation,
    UpdateUserSettingsMutationVariables
  >(UpdateUserSettingsDocument, options);
}
export type UpdateUserSettingsMutationHookResult = ReturnType<
  typeof useUpdateUserSettingsMutation
>;
export type UpdateUserSettingsMutationResult =
  Apollo.MutationResult<UpdateUserSettingsMutation>;
export type UpdateUserSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables
>;
export const CompleteTutorialDocument = gql`
  mutation CompleteTutorial($id: String!) {
    users {
      completeTutorial(tutorialId: $id) {
        ...AuthenticatedUserFields
      }
    }
  }
  ${AuthenticatedUserFieldsFragmentDoc}
`;
export type CompleteTutorialMutationFn = Apollo.MutationFunction<
  CompleteTutorialMutation,
  CompleteTutorialMutationVariables
>;

/**
 * __useCompleteTutorialMutation__
 *
 * To run a mutation, you first call `useCompleteTutorialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTutorialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTutorialMutation, { data, loading, error }] = useCompleteTutorialMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteTutorialMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteTutorialMutation,
    CompleteTutorialMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CompleteTutorialMutation,
    CompleteTutorialMutationVariables
  >(CompleteTutorialDocument, options);
}
export type CompleteTutorialMutationHookResult = ReturnType<
  typeof useCompleteTutorialMutation
>;
export type CompleteTutorialMutationResult =
  Apollo.MutationResult<CompleteTutorialMutation>;
export type CompleteTutorialMutationOptions = Apollo.BaseMutationOptions<
  CompleteTutorialMutation,
  CompleteTutorialMutationVariables
>;
export const UpdateUnitDocument = gql`
  mutation UpdateUnit(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $useType: String
    $name: String
    $loopName: String
    $location: LocationInput
    $peopleCapacity: IntRangeInput
    $rules: String
    $isGroup: Boolean
    $isReservable: Boolean
    $isAdaAccessible: Boolean
    $petsAllowed: Boolean
    $maxVehicles: Int
    $maxVehicleLengthFeet: Int
    $facilityId: Int
    $isEquipmentMandatory: Boolean
    $allowedEquipmentIds: [Int!]
    $isActive: Boolean
  ) {
    entities {
      updateUnit(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          useType: $useType
          name: $name
          loopName: $loopName
          location: $location
          peopleCapacity: $peopleCapacity
          rules: $rules
          isGroup: $isGroup
          isReservable: $isReservable
          isAdaAccessible: $isAdaAccessible
          petsAllowed: $petsAllowed
          maxVehicles: $maxVehicles
          maxVehicleLengthFeet: $maxVehicleLengthFeet
          facilityId: $facilityId
          isEquipmentMandatory: $isEquipmentMandatory
          allowedEquipmentIds: $allowedEquipmentIds
          isActive: $isActive
        }
      ) {
        ...UnitFields
      }
    }
  }
  ${UnitFieldsFragmentDoc}
`;
export type UpdateUnitMutationFn = Apollo.MutationFunction<
  UpdateUnitMutation,
  UpdateUnitMutationVariables
>;

/**
 * __useUpdateUnitMutation__
 *
 * To run a mutation, you first call `useUpdateUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnitMutation, { data, loading, error }] = useUpdateUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      type: // value for 'type'
 *      useType: // value for 'useType'
 *      name: // value for 'name'
 *      loopName: // value for 'loopName'
 *      location: // value for 'location'
 *      peopleCapacity: // value for 'peopleCapacity'
 *      rules: // value for 'rules'
 *      isGroup: // value for 'isGroup'
 *      isReservable: // value for 'isReservable'
 *      isAdaAccessible: // value for 'isAdaAccessible'
 *      petsAllowed: // value for 'petsAllowed'
 *      maxVehicles: // value for 'maxVehicles'
 *      maxVehicleLengthFeet: // value for 'maxVehicleLengthFeet'
 *      facilityId: // value for 'facilityId'
 *      isEquipmentMandatory: // value for 'isEquipmentMandatory'
 *      allowedEquipmentIds: // value for 'allowedEquipmentIds'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUnitMutation,
    UpdateUnitMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(
    UpdateUnitDocument,
    options
  );
}
export type UpdateUnitMutationHookResult = ReturnType<
  typeof useUpdateUnitMutation
>;
export type UpdateUnitMutationResult =
  Apollo.MutationResult<UpdateUnitMutation>;
export type UpdateUnitMutationOptions = Apollo.BaseMutationOptions<
  UpdateUnitMutation,
  UpdateUnitMutationVariables
>;
export const UpdateFacilityDocument = gql`
  mutation UpdateFacility(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $name: String
    $url: String
    $bookingUrl: String
    $reservationUrl: String
    $mapUrl: String
    $checkinHour: Int
    $checkoutHour: Int
    $description: String
    $descriptionHtml: String
    $feeDescription: String
    $rules: String
    $directions: String
    $stayLimit: String
    $phone: String
    $email: String
    $isReservable: Boolean
    $isAdaAccessible: Boolean
    $location: LocationInput
    $parkId: Int
    $organizationId: Int
    $isActive: Boolean
  ) {
    entities {
      updateFacility(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          name: $name
          url: $url
          bookingUrl: $bookingUrl
          reservationUrl: $reservationUrl
          mapUrl: $mapUrl
          checkinHour: $checkinHour
          checkoutHour: $checkoutHour
          description: $description
          descriptionHtml: $descriptionHtml
          feeDescription: $feeDescription
          rules: $rules
          directions: $directions
          stayLimit: $stayLimit
          phone: $phone
          email: $email
          isReservable: $isReservable
          isAdaAccessible: $isAdaAccessible
          location: $location
          parkId: $parkId
          organizationId: $organizationId
          isActive: $isActive
        }
      ) {
        ...FacilityFields
      }
    }
  }
  ${FacilityFieldsFragmentDoc}
`;
export type UpdateFacilityMutationFn = Apollo.MutationFunction<
  UpdateFacilityMutation,
  UpdateFacilityMutationVariables
>;

/**
 * __useUpdateFacilityMutation__
 *
 * To run a mutation, you first call `useUpdateFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFacilityMutation, { data, loading, error }] = useUpdateFacilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *      bookingUrl: // value for 'bookingUrl'
 *      reservationUrl: // value for 'reservationUrl'
 *      mapUrl: // value for 'mapUrl'
 *      checkinHour: // value for 'checkinHour'
 *      checkoutHour: // value for 'checkoutHour'
 *      description: // value for 'description'
 *      descriptionHtml: // value for 'descriptionHtml'
 *      feeDescription: // value for 'feeDescription'
 *      rules: // value for 'rules'
 *      directions: // value for 'directions'
 *      stayLimit: // value for 'stayLimit'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      isReservable: // value for 'isReservable'
 *      isAdaAccessible: // value for 'isAdaAccessible'
 *      location: // value for 'location'
 *      parkId: // value for 'parkId'
 *      organizationId: // value for 'organizationId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateFacilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFacilityMutation,
    UpdateFacilityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateFacilityMutation,
    UpdateFacilityMutationVariables
  >(UpdateFacilityDocument, options);
}
export type UpdateFacilityMutationHookResult = ReturnType<
  typeof useUpdateFacilityMutation
>;
export type UpdateFacilityMutationResult =
  Apollo.MutationResult<UpdateFacilityMutation>;
export type UpdateFacilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateFacilityMutation,
  UpdateFacilityMutationVariables
>;
export const UpdateParkDocument = gql`
  mutation UpdatePark(
    $id: Int!
    $unsetFields: [String!]
    $name: String
    $description: String
    $descriptionHtml: String
    $feeDescription: String
    $directions: String
    $stayLimit: String
    $phone: String
    $email: String
    $reservationUrl: String
    $mapUrl: String
    $isReservable: Boolean
    $location: LocationInput
    $organizationId: Int
    $isActive: Boolean
  ) {
    entities {
      updatePark(
        input: {
          id: $id
          unsetFields: $unsetFields
          name: $name
          description: $description
          descriptionHtml: $descriptionHtml
          feeDescription: $feeDescription
          directions: $directions
          stayLimit: $stayLimit
          phone: $phone
          email: $email
          reservationUrl: $reservationUrl
          mapUrl: $mapUrl
          isReservable: $isReservable
          location: $location
          organizationId: $organizationId
          isActive: $isActive
        }
      ) {
        ...ParkFields
      }
    }
  }
  ${ParkFieldsFragmentDoc}
`;
export type UpdateParkMutationFn = Apollo.MutationFunction<
  UpdateParkMutation,
  UpdateParkMutationVariables
>;

/**
 * __useUpdateParkMutation__
 *
 * To run a mutation, you first call `useUpdateParkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParkMutation, { data, loading, error }] = useUpdateParkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      descriptionHtml: // value for 'descriptionHtml'
 *      feeDescription: // value for 'feeDescription'
 *      directions: // value for 'directions'
 *      stayLimit: // value for 'stayLimit'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      reservationUrl: // value for 'reservationUrl'
 *      mapUrl: // value for 'mapUrl'
 *      isReservable: // value for 'isReservable'
 *      location: // value for 'location'
 *      organizationId: // value for 'organizationId'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateParkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateParkMutation,
    UpdateParkMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateParkMutation, UpdateParkMutationVariables>(
    UpdateParkDocument,
    options
  );
}
export type UpdateParkMutationHookResult = ReturnType<
  typeof useUpdateParkMutation
>;
export type UpdateParkMutationResult =
  Apollo.MutationResult<UpdateParkMutation>;
export type UpdateParkMutationOptions = Apollo.BaseMutationOptions<
  UpdateParkMutation,
  UpdateParkMutationVariables
>;
export const UpdateMediaDocument = gql`
  mutation UpdateMedia(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $title: String
    $subtitle: String
    $description: String
    $credits: String
    $url: String
    $width: Int
    $height: Int
    $isActive: Boolean
  ) {
    entities {
      updateMedia(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          title: $title
          subtitle: $subtitle
          description: $description
          credits: $credits
          url: $url
          width: $width
          height: $height
          isActive: $isActive
        }
      ) {
        ...MediaFields
      }
    }
  }
  ${MediaFieldsFragmentDoc}
`;
export type UpdateMediaMutationFn = Apollo.MutationFunction<
  UpdateMediaMutation,
  UpdateMediaMutationVariables
>;

/**
 * __useUpdateMediaMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMutation, { data, loading, error }] = useUpdateMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      type: // value for 'type'
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *      description: // value for 'description'
 *      credits: // value for 'credits'
 *      url: // value for 'url'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMediaMutation,
    UpdateMediaMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateMediaMutation, UpdateMediaMutationVariables>(
    UpdateMediaDocument,
    options
  );
}
export type UpdateMediaMutationHookResult = ReturnType<
  typeof useUpdateMediaMutation
>;
export type UpdateMediaMutationResult =
  Apollo.MutationResult<UpdateMediaMutation>;
export type UpdateMediaMutationOptions = Apollo.BaseMutationOptions<
  UpdateMediaMutation,
  UpdateMediaMutationVariables
>;
export const UpdateAddressDocument = gql`
  mutation UpdateAddress(
    $id: Int!
    $unsetFields: [String!]
    $type: String
    $street: [String!]
    $city: String
    $state: String
    $zip: String
    $country: String
    $isActive: Boolean
  ) {
    entities {
      updateAddress(
        input: {
          id: $id
          unsetFields: $unsetFields
          type: $type
          street: $street
          city: $city
          state: $state
          zip: $zip
          country: $country
          isActive: $isActive
        }
      ) {
        ...AddressFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export type UpdateAddressMutationFn = Apollo.MutationFunction<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      type: // value for 'type'
 *      street: // value for 'street'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      zip: // value for 'zip'
 *      country: // value for 'country'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateAddressMutation,
    UpdateAddressMutationVariables
  >(UpdateAddressDocument, options);
}
export type UpdateAddressMutationHookResult = ReturnType<
  typeof useUpdateAddressMutation
>;
export type UpdateAddressMutationResult =
  Apollo.MutationResult<UpdateAddressMutation>;
export type UpdateAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateAddressMutation,
  UpdateAddressMutationVariables
>;
export const UpdateClosureDocument = gql`
  mutation UpdateClosure(
    $id: Int!
    $unsetFields: [String!]
    $beginsAt: DateTime
    $endsAt: DateTime
    $reason: String
    $isActive: Boolean
  ) {
    entities {
      updateClosure(
        input: {
          id: $id
          unsetFields: $unsetFields
          beginsAt: $beginsAt
          endsAt: $endsAt
          reason: $reason
          isActive: $isActive
        }
      ) {
        ...ClosureFields
      }
    }
  }
  ${ClosureFieldsFragmentDoc}
`;
export type UpdateClosureMutationFn = Apollo.MutationFunction<
  UpdateClosureMutation,
  UpdateClosureMutationVariables
>;

/**
 * __useUpdateClosureMutation__
 *
 * To run a mutation, you first call `useUpdateClosureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClosureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClosureMutation, { data, loading, error }] = useUpdateClosureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unsetFields: // value for 'unsetFields'
 *      beginsAt: // value for 'beginsAt'
 *      endsAt: // value for 'endsAt'
 *      reason: // value for 'reason'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateClosureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClosureMutation,
    UpdateClosureMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateClosureMutation,
    UpdateClosureMutationVariables
  >(UpdateClosureDocument, options);
}
export type UpdateClosureMutationHookResult = ReturnType<
  typeof useUpdateClosureMutation
>;
export type UpdateClosureMutationResult =
  Apollo.MutationResult<UpdateClosureMutation>;
export type UpdateClosureMutationOptions = Apollo.BaseMutationOptions<
  UpdateClosureMutation,
  UpdateClosureMutationVariables
>;
export const RefreshUnitDocument = gql`
  mutation RefreshUnit($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshUnit(unitId: $id, clearOverrides: $clearOverrides) {
        ...UnitFields
      }
    }
  }
  ${UnitFieldsFragmentDoc}
`;
export type RefreshUnitMutationFn = Apollo.MutationFunction<
  RefreshUnitMutation,
  RefreshUnitMutationVariables
>;

/**
 * __useRefreshUnitMutation__
 *
 * To run a mutation, you first call `useRefreshUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshUnitMutation, { data, loading, error }] = useRefreshUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshUnitMutation,
    RefreshUnitMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<RefreshUnitMutation, RefreshUnitMutationVariables>(
    RefreshUnitDocument,
    options
  );
}
export type RefreshUnitMutationHookResult = ReturnType<
  typeof useRefreshUnitMutation
>;
export type RefreshUnitMutationResult =
  Apollo.MutationResult<RefreshUnitMutation>;
export type RefreshUnitMutationOptions = Apollo.BaseMutationOptions<
  RefreshUnitMutation,
  RefreshUnitMutationVariables
>;
export const RefreshFacilityDocument = gql`
  mutation RefreshFacility($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshFacility(facilityId: $id, clearOverrides: $clearOverrides) {
        ...FacilityFields
      }
    }
  }
  ${FacilityFieldsFragmentDoc}
`;
export type RefreshFacilityMutationFn = Apollo.MutationFunction<
  RefreshFacilityMutation,
  RefreshFacilityMutationVariables
>;

/**
 * __useRefreshFacilityMutation__
 *
 * To run a mutation, you first call `useRefreshFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshFacilityMutation, { data, loading, error }] = useRefreshFacilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshFacilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshFacilityMutation,
    RefreshFacilityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RefreshFacilityMutation,
    RefreshFacilityMutationVariables
  >(RefreshFacilityDocument, options);
}
export type RefreshFacilityMutationHookResult = ReturnType<
  typeof useRefreshFacilityMutation
>;
export type RefreshFacilityMutationResult =
  Apollo.MutationResult<RefreshFacilityMutation>;
export type RefreshFacilityMutationOptions = Apollo.BaseMutationOptions<
  RefreshFacilityMutation,
  RefreshFacilityMutationVariables
>;
export const RefreshParkDocument = gql`
  mutation RefreshPark($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshPark(parkId: $id, clearOverrides: $clearOverrides) {
        ...ParkFields
      }
    }
  }
  ${ParkFieldsFragmentDoc}
`;
export type RefreshParkMutationFn = Apollo.MutationFunction<
  RefreshParkMutation,
  RefreshParkMutationVariables
>;

/**
 * __useRefreshParkMutation__
 *
 * To run a mutation, you first call `useRefreshParkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshParkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshParkMutation, { data, loading, error }] = useRefreshParkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshParkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshParkMutation,
    RefreshParkMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<RefreshParkMutation, RefreshParkMutationVariables>(
    RefreshParkDocument,
    options
  );
}
export type RefreshParkMutationHookResult = ReturnType<
  typeof useRefreshParkMutation
>;
export type RefreshParkMutationResult =
  Apollo.MutationResult<RefreshParkMutation>;
export type RefreshParkMutationOptions = Apollo.BaseMutationOptions<
  RefreshParkMutation,
  RefreshParkMutationVariables
>;
export const RefreshMediaDocument = gql`
  mutation RefreshMedia($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshMedia(mediaId: $id, clearOverrides: $clearOverrides) {
        ...MediaFields
      }
    }
  }
  ${MediaFieldsFragmentDoc}
`;
export type RefreshMediaMutationFn = Apollo.MutationFunction<
  RefreshMediaMutation,
  RefreshMediaMutationVariables
>;

/**
 * __useRefreshMediaMutation__
 *
 * To run a mutation, you first call `useRefreshMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMediaMutation, { data, loading, error }] = useRefreshMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshMediaMutation,
    RefreshMediaMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RefreshMediaMutation,
    RefreshMediaMutationVariables
  >(RefreshMediaDocument, options);
}
export type RefreshMediaMutationHookResult = ReturnType<
  typeof useRefreshMediaMutation
>;
export type RefreshMediaMutationResult =
  Apollo.MutationResult<RefreshMediaMutation>;
export type RefreshMediaMutationOptions = Apollo.BaseMutationOptions<
  RefreshMediaMutation,
  RefreshMediaMutationVariables
>;
export const RefreshAddressDocument = gql`
  mutation RefreshAddress($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshAddress(addressId: $id, clearOverrides: $clearOverrides) {
        ...AddressFields
      }
    }
  }
  ${AddressFieldsFragmentDoc}
`;
export type RefreshAddressMutationFn = Apollo.MutationFunction<
  RefreshAddressMutation,
  RefreshAddressMutationVariables
>;

/**
 * __useRefreshAddressMutation__
 *
 * To run a mutation, you first call `useRefreshAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAddressMutation, { data, loading, error }] = useRefreshAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshAddressMutation,
    RefreshAddressMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RefreshAddressMutation,
    RefreshAddressMutationVariables
  >(RefreshAddressDocument, options);
}
export type RefreshAddressMutationHookResult = ReturnType<
  typeof useRefreshAddressMutation
>;
export type RefreshAddressMutationResult =
  Apollo.MutationResult<RefreshAddressMutation>;
export type RefreshAddressMutationOptions = Apollo.BaseMutationOptions<
  RefreshAddressMutation,
  RefreshAddressMutationVariables
>;
export const RefreshClosureDocument = gql`
  mutation RefreshClosure($id: Int!, $clearOverrides: [String!]) {
    entities {
      refreshClosure(closureId: $id, clearOverrides: $clearOverrides) {
        ...ClosureFields
      }
    }
  }
  ${ClosureFieldsFragmentDoc}
`;
export type RefreshClosureMutationFn = Apollo.MutationFunction<
  RefreshClosureMutation,
  RefreshClosureMutationVariables
>;

/**
 * __useRefreshClosureMutation__
 *
 * To run a mutation, you first call `useRefreshClosureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshClosureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshClosureMutation, { data, loading, error }] = useRefreshClosureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clearOverrides: // value for 'clearOverrides'
 *   },
 * });
 */
export function useRefreshClosureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshClosureMutation,
    RefreshClosureMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    RefreshClosureMutation,
    RefreshClosureMutationVariables
  >(RefreshClosureDocument, options);
}
export type RefreshClosureMutationHookResult = ReturnType<
  typeof useRefreshClosureMutation
>;
export type RefreshClosureMutationResult =
  Apollo.MutationResult<RefreshClosureMutation>;
export type RefreshClosureMutationOptions = Apollo.BaseMutationOptions<
  RefreshClosureMutation,
  RefreshClosureMutationVariables
>;
export const SendChatMessageDocument = gql`
  mutation SendChatMessage($message: String!) {
    chat {
      sendMessage(input: {message: $message}) {
        ...ChatMessageFields
      }
    }
  }
  ${ChatMessageFieldsFragmentDoc}
`;
export type SendChatMessageMutationFn = Apollo.MutationFunction<
  SendChatMessageMutation,
  SendChatMessageMutationVariables
>;

/**
 * __useSendChatMessageMutation__
 *
 * To run a mutation, you first call `useSendChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMessageMutation, { data, loading, error }] = useSendChatMessageMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendChatMessageMutation,
    SendChatMessageMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    SendChatMessageMutation,
    SendChatMessageMutationVariables
  >(SendChatMessageDocument, options);
}
export type SendChatMessageMutationHookResult = ReturnType<
  typeof useSendChatMessageMutation
>;
export type SendChatMessageMutationResult =
  Apollo.MutationResult<SendChatMessageMutation>;
export type SendChatMessageMutationOptions = Apollo.BaseMutationOptions<
  SendChatMessageMutation,
  SendChatMessageMutationVariables
>;
export const UploadMappingDocument = gql`
  mutation UploadMapping($name: String!, $file: Upload!) {
    uploads {
      uploadMapping(name: $name, file: $file) {
        uploadId
        fileName
        fileFormat
        rows {
          id
          name
          description
          location {
            latitude
            longitude
          }
          unit {
            id
            slug
            name
            loopName
            facility {
              id
              slug
              name
              park {
                id
                slug
                name
              }
            }
          }
        }
      }
    }
  }
`;
export type UploadMappingMutationFn = Apollo.MutationFunction<
  UploadMappingMutation,
  UploadMappingMutationVariables
>;

/**
 * __useUploadMappingMutation__
 *
 * To run a mutation, you first call `useUploadMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMappingMutation, { data, loading, error }] = useUploadMappingMutation({
 *   variables: {
 *      name: // value for 'name'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadMappingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadMappingMutation,
    UploadMappingMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UploadMappingMutation,
    UploadMappingMutationVariables
  >(UploadMappingDocument, options);
}
export type UploadMappingMutationHookResult = ReturnType<
  typeof useUploadMappingMutation
>;
export type UploadMappingMutationResult =
  Apollo.MutationResult<UploadMappingMutation>;
export type UploadMappingMutationOptions = Apollo.BaseMutationOptions<
  UploadMappingMutation,
  UploadMappingMutationVariables
>;
export const CreateSubscriptionDocument = gql`
  mutation CreateSubscription($planId: String!) {
    subscriptions {
      create(planId: $planId) {
        subscriptionId
        clientSecret
      }
    }
  }
`;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateSubscriptionMutation,
    CreateSubscriptionMutationVariables
  >(CreateSubscriptionDocument, options);
}
export type CreateSubscriptionMutationHookResult = ReturnType<
  typeof useCreateSubscriptionMutation
>;
export type CreateSubscriptionMutationResult =
  Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateSubscriptionMutation,
  CreateSubscriptionMutationVariables
>;
export const CancelSubscriptionDocument = gql`
  mutation CancelSubscription($subscriptionId: String!) {
    subscriptions {
      cancel(subscriptionId: $subscriptionId)
    }
  }
`;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CancelSubscriptionMutation,
    CancelSubscriptionMutationVariables
  >(CancelSubscriptionDocument, options);
}
export type CancelSubscriptionMutationHookResult = ReturnType<
  typeof useCancelSubscriptionMutation
>;
export type CancelSubscriptionMutationResult =
  Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CancelSubscriptionMutation,
  CancelSubscriptionMutationVariables
>;
export const CreateWatchDocument = gql`
  mutation CreateWatch(
    $name: String
    $excludeGroup: Boolean! = false
    $minPeople: Int
    $maxPeople: Int
    $onlyPetsAllowed: Boolean! = false
    $onlyAccessible: Boolean! = false
    $excludeMandatoryEquipment: Boolean! = false
    $maxVehicleLengthFeet: Int
    $vehicles: Int
    $minDate: Date!
    $maxDate: Date!
    $nights: Int
    $alertsEnabled: Boolean!
    $parkIds: [Int!]
    $facilityIds: [Int!]
    $unitIds: [Int!]
  ) {
    watches {
      create(
        input: {
          name: $name
          filter: {
            dates: {min: $minDate, max: $maxDate}
            nights: $nights
            excludeGroup: $excludeGroup
            people: {min: $minPeople, max: $maxPeople}
            onlyPetsAllowed: $onlyPetsAllowed
            onlyAccessible: $onlyAccessible
            excludeMandatoryEquipment: $excludeMandatoryEquipment
            maxVehicleLengthFeet: $maxVehicleLengthFeet
            vehicles: $vehicles
          }
          alertsEnabled: $alertsEnabled
          parkIds: $parkIds
          facilityIds: $facilityIds
          unitIds: $unitIds
        }
      ) {
        ...WatchFields
      }
    }
  }
  ${WatchFieldsFragmentDoc}
`;
export type CreateWatchMutationFn = Apollo.MutationFunction<
  CreateWatchMutation,
  CreateWatchMutationVariables
>;

/**
 * __useCreateWatchMutation__
 *
 * To run a mutation, you first call `useCreateWatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWatchMutation, { data, loading, error }] = useCreateWatchMutation({
 *   variables: {
 *      name: // value for 'name'
 *      excludeGroup: // value for 'excludeGroup'
 *      minPeople: // value for 'minPeople'
 *      maxPeople: // value for 'maxPeople'
 *      onlyPetsAllowed: // value for 'onlyPetsAllowed'
 *      onlyAccessible: // value for 'onlyAccessible'
 *      excludeMandatoryEquipment: // value for 'excludeMandatoryEquipment'
 *      maxVehicleLengthFeet: // value for 'maxVehicleLengthFeet'
 *      vehicles: // value for 'vehicles'
 *      minDate: // value for 'minDate'
 *      maxDate: // value for 'maxDate'
 *      nights: // value for 'nights'
 *      alertsEnabled: // value for 'alertsEnabled'
 *      parkIds: // value for 'parkIds'
 *      facilityIds: // value for 'facilityIds'
 *      unitIds: // value for 'unitIds'
 *   },
 * });
 */
export function useCreateWatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWatchMutation,
    CreateWatchMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<CreateWatchMutation, CreateWatchMutationVariables>(
    CreateWatchDocument,
    options
  );
}
export type CreateWatchMutationHookResult = ReturnType<
  typeof useCreateWatchMutation
>;
export type CreateWatchMutationResult =
  Apollo.MutationResult<CreateWatchMutation>;
export type CreateWatchMutationOptions = Apollo.BaseMutationOptions<
  CreateWatchMutation,
  CreateWatchMutationVariables
>;
export const UpdateWatchDocument = gql`
  mutation UpdateWatch(
    $watchId: Int!
    $name: String
    $unsetFields: [String!]
    $excludeGroup: Boolean
    $minPeople: Int
    $maxPeople: Int
    $onlyPetsAllowed: Boolean
    $onlyAccessible: Boolean
    $excludeMandatoryEquipment: Boolean
    $maxVehicleLengthFeet: Int
    $vehicles: Int
    $minDate: Date
    $maxDate: Date
    $nights: Int
    $alertsEnabled: Boolean
    $parkIds: [Int!]
    $facilityIds: [Int!]
    $unitIds: [Int!]
  ) {
    watches {
      update(
        watchId: $watchId
        unsetFields: $unsetFields
        input: {
          name: $name
          filter: {
            dates: {min: $minDate, max: $maxDate}
            nights: $nights
            excludeGroup: $excludeGroup
            people: {min: $minPeople, max: $maxPeople}
            onlyPetsAllowed: $onlyPetsAllowed
            onlyAccessible: $onlyAccessible
            excludeMandatoryEquipment: $excludeMandatoryEquipment
            maxVehicleLengthFeet: $maxVehicleLengthFeet
            vehicles: $vehicles
          }
          alertsEnabled: $alertsEnabled
          parkIds: $parkIds
          facilityIds: $facilityIds
          unitIds: $unitIds
        }
      ) {
        ...WatchFields
      }
    }
  }
  ${WatchFieldsFragmentDoc}
`;
export type UpdateWatchMutationFn = Apollo.MutationFunction<
  UpdateWatchMutation,
  UpdateWatchMutationVariables
>;

/**
 * __useUpdateWatchMutation__
 *
 * To run a mutation, you first call `useUpdateWatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWatchMutation, { data, loading, error }] = useUpdateWatchMutation({
 *   variables: {
 *      watchId: // value for 'watchId'
 *      name: // value for 'name'
 *      unsetFields: // value for 'unsetFields'
 *      excludeGroup: // value for 'excludeGroup'
 *      minPeople: // value for 'minPeople'
 *      maxPeople: // value for 'maxPeople'
 *      onlyPetsAllowed: // value for 'onlyPetsAllowed'
 *      onlyAccessible: // value for 'onlyAccessible'
 *      excludeMandatoryEquipment: // value for 'excludeMandatoryEquipment'
 *      maxVehicleLengthFeet: // value for 'maxVehicleLengthFeet'
 *      vehicles: // value for 'vehicles'
 *      minDate: // value for 'minDate'
 *      maxDate: // value for 'maxDate'
 *      nights: // value for 'nights'
 *      alertsEnabled: // value for 'alertsEnabled'
 *      parkIds: // value for 'parkIds'
 *      facilityIds: // value for 'facilityIds'
 *      unitIds: // value for 'unitIds'
 *   },
 * });
 */
export function useUpdateWatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWatchMutation,
    UpdateWatchMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<UpdateWatchMutation, UpdateWatchMutationVariables>(
    UpdateWatchDocument,
    options
  );
}
export type UpdateWatchMutationHookResult = ReturnType<
  typeof useUpdateWatchMutation
>;
export type UpdateWatchMutationResult =
  Apollo.MutationResult<UpdateWatchMutation>;
export type UpdateWatchMutationOptions = Apollo.BaseMutationOptions<
  UpdateWatchMutation,
  UpdateWatchMutationVariables
>;
export const CancelWatchDocument = gql`
  mutation CancelWatch($watchId: Int!) {
    watches {
      cancel(watchId: $watchId) {
        ...WatchFields
      }
    }
  }
  ${WatchFieldsFragmentDoc}
`;
export type CancelWatchMutationFn = Apollo.MutationFunction<
  CancelWatchMutation,
  CancelWatchMutationVariables
>;

/**
 * __useCancelWatchMutation__
 *
 * To run a mutation, you first call `useCancelWatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelWatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelWatchMutation, { data, loading, error }] = useCancelWatchMutation({
 *   variables: {
 *      watchId: // value for 'watchId'
 *   },
 * });
 */
export function useCancelWatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelWatchMutation,
    CancelWatchMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<CancelWatchMutation, CancelWatchMutationVariables>(
    CancelWatchDocument,
    options
  );
}
export type CancelWatchMutationHookResult = ReturnType<
  typeof useCancelWatchMutation
>;
export type CancelWatchMutationResult =
  Apollo.MutationResult<CancelWatchMutation>;
export type CancelWatchMutationOptions = Apollo.BaseMutationOptions<
  CancelWatchMutation,
  CancelWatchMutationVariables
>;
export const CheckFacilityDocument = gql`
  mutation CheckFacility(
    $facilityId: Int!
    $beginDate: Date!
    $endDate: Date!
  ) {
    entities {
      checkFacility(
        facilityId: $facilityId
        beginDate: $beginDate
        endDate: $endDate
      ) {
        ...FacilityFields
      }
    }
  }
  ${FacilityFieldsFragmentDoc}
`;
export type CheckFacilityMutationFn = Apollo.MutationFunction<
  CheckFacilityMutation,
  CheckFacilityMutationVariables
>;

/**
 * __useCheckFacilityMutation__
 *
 * To run a mutation, you first call `useCheckFacilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckFacilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkFacilityMutation, { data, loading, error }] = useCheckFacilityMutation({
 *   variables: {
 *      facilityId: // value for 'facilityId'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCheckFacilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckFacilityMutation,
    CheckFacilityMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CheckFacilityMutation,
    CheckFacilityMutationVariables
  >(CheckFacilityDocument, options);
}
export type CheckFacilityMutationHookResult = ReturnType<
  typeof useCheckFacilityMutation
>;
export type CheckFacilityMutationResult =
  Apollo.MutationResult<CheckFacilityMutation>;
export type CheckFacilityMutationOptions = Apollo.BaseMutationOptions<
  CheckFacilityMutation,
  CheckFacilityMutationVariables
>;
export const CreateContentDocument = gql`
  mutation CreateContent($content: ContentInput!) {
    cms {
      create(input: $content) {
        ...ContentFields
      }
    }
  }
  ${ContentFieldsFragmentDoc}
`;
export type CreateContentMutationFn = Apollo.MutationFunction<
  CreateContentMutation,
  CreateContentMutationVariables
>;

/**
 * __useCreateContentMutation__
 *
 * To run a mutation, you first call `useCreateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContentMutation, { data, loading, error }] = useCreateContentMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateContentMutation,
    CreateContentMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    CreateContentMutation,
    CreateContentMutationVariables
  >(CreateContentDocument, options);
}
export type CreateContentMutationHookResult = ReturnType<
  typeof useCreateContentMutation
>;
export type CreateContentMutationResult =
  Apollo.MutationResult<CreateContentMutation>;
export type CreateContentMutationOptions = Apollo.BaseMutationOptions<
  CreateContentMutation,
  CreateContentMutationVariables
>;
export const UpdateContentDocument = gql`
  mutation UpdateContent($content: ContentInput!) {
    cms {
      update(input: $content) {
        ...ContentFields
      }
    }
  }
  ${ContentFieldsFragmentDoc}
`;
export type UpdateContentMutationFn = Apollo.MutationFunction<
  UpdateContentMutation,
  UpdateContentMutationVariables
>;

/**
 * __useUpdateContentMutation__
 *
 * To run a mutation, you first call `useUpdateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentMutation, { data, loading, error }] = useUpdateContentMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContentMutation,
    UpdateContentMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UpdateContentMutation,
    UpdateContentMutationVariables
  >(UpdateContentDocument, options);
}
export type UpdateContentMutationHookResult = ReturnType<
  typeof useUpdateContentMutation
>;
export type UpdateContentMutationResult =
  Apollo.MutationResult<UpdateContentMutation>;
export type UpdateContentMutationOptions = Apollo.BaseMutationOptions<
  UpdateContentMutation,
  UpdateContentMutationVariables
>;
export const UploadContentAttachmentDocument = gql`
  mutation UploadContentAttachment($attachment: ContentAttachmentInput!) {
    cms {
      uploadAttachment(input: $attachment) {
        ...ContentAttachmentFields
      }
    }
  }
  ${ContentAttachmentFieldsFragmentDoc}
`;
export type UploadContentAttachmentMutationFn = Apollo.MutationFunction<
  UploadContentAttachmentMutation,
  UploadContentAttachmentMutationVariables
>;

/**
 * __useUploadContentAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadContentAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadContentAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadContentAttachmentMutation, { data, loading, error }] = useUploadContentAttachmentMutation({
 *   variables: {
 *      attachment: // value for 'attachment'
 *   },
 * });
 */
export function useUploadContentAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadContentAttachmentMutation,
    UploadContentAttachmentMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    UploadContentAttachmentMutation,
    UploadContentAttachmentMutationVariables
  >(UploadContentAttachmentDocument, options);
}
export type UploadContentAttachmentMutationHookResult = ReturnType<
  typeof useUploadContentAttachmentMutation
>;
export type UploadContentAttachmentMutationResult =
  Apollo.MutationResult<UploadContentAttachmentMutation>;
export type UploadContentAttachmentMutationOptions = Apollo.BaseMutationOptions<
  UploadContentAttachmentMutation,
  UploadContentAttachmentMutationVariables
>;
export const DestroyContentDocument = gql`
  mutation DestroyContent($contentId: Int!) {
    cms {
      destroy(contentId: $contentId) {
        ...ContentFields
      }
    }
  }
  ${ContentFieldsFragmentDoc}
`;
export type DestroyContentMutationFn = Apollo.MutationFunction<
  DestroyContentMutation,
  DestroyContentMutationVariables
>;

/**
 * __useDestroyContentMutation__
 *
 * To run a mutation, you first call `useDestroyContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyContentMutation, { data, loading, error }] = useDestroyContentMutation({
 *   variables: {
 *      contentId: // value for 'contentId'
 *   },
 * });
 */
export function useDestroyContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DestroyContentMutation,
    DestroyContentMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<
    DestroyContentMutation,
    DestroyContentMutationVariables
  >(DestroyContentDocument, options);
}
export type DestroyContentMutationHookResult = ReturnType<
  typeof useDestroyContentMutation
>;
export type DestroyContentMutationResult =
  Apollo.MutationResult<DestroyContentMutation>;
export type DestroyContentMutationOptions = Apollo.BaseMutationOptions<
  DestroyContentMutation,
  DestroyContentMutationVariables
>;
export const InviteDocument = gql`
  mutation Invite($invitation: InvitationInput!) {
    users {
      invite(input: $invitation) {
        ... on InvitationSuccessResult {
          email
          message
          inviterWatchCredits
          inviteeWatchCredits
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;
export type InviteMutationFn = Apollo.MutationFunction<
  InviteMutation,
  InviteMutationVariables
>;

/**
 * __useInviteMutation__
 *
 * To run a mutation, you first call `useInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMutation, { data, loading, error }] = useInviteMutation({
 *   variables: {
 *      invitation: // value for 'invitation'
 *   },
 * });
 */
export function useInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteMutation,
    InviteMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<InviteMutation, InviteMutationVariables>(
    InviteDocument,
    options
  );
}
export type InviteMutationHookResult = ReturnType<typeof useInviteMutation>;
export type InviteMutationResult = Apollo.MutationResult<InviteMutation>;
export type InviteMutationOptions = Apollo.BaseMutationOptions<
  InviteMutation,
  InviteMutationVariables
>;
export const ReviewDocument = gql`
  mutation Review($input: ReviewInput!) {
    entities {
      review(input: $input) {
        ... on Review {
          ...ReviewFields
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
  ${ReviewFieldsFragmentDoc}
`;
export type ReviewMutationFn = Apollo.MutationFunction<
  ReviewMutation,
  ReviewMutationVariables
>;

/**
 * __useReviewMutation__
 *
 * To run a mutation, you first call `useReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewMutation, { data, loading, error }] = useReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewMutation,
    ReviewMutationVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useMutation<ReviewMutation, ReviewMutationVariables>(
    ReviewDocument,
    options
  );
}
export type ReviewMutationHookResult = ReturnType<typeof useReviewMutation>;
export type ReviewMutationResult = Apollo.MutationResult<ReviewMutation>;
export type ReviewMutationOptions = Apollo.BaseMutationOptions<
  ReviewMutation,
  ReviewMutationVariables
>;
export const MapSearchDocument = gql`
  query MapSearch(
    $filter: FilterInput
    $zoom: Float
    $onlyMatches: Boolean = false
    $page: Int = 1
    $pageSize: Int = 100
    $sortField: String
    $sortDirection: SortDirection
    $cluster: Boolean = false
    $includeUsfsBoundaries: Boolean = false
    $includeBlmBoundaries: Boolean = false
  ) {
    search {
      mapSearch(
        input: {
          filter: $filter
          zoom: $zoom
          onlyMatches: $onlyMatches
          pagination: {page: $page, pageSize: $pageSize}
          sort: {field: $sortField, direction: $sortDirection}
          cluster: $cluster
          includeUsfsBoundaries: $includeUsfsBoundaries
          includeBlmBoundaries: $includeBlmBoundaries
        }
      ) {
        results {
          ... on SearchResult {
            type
            id
            slug
            name
            location {
              latitude
              longitude
            }
            geometryUrl
            organizationId
            isMatch
            isShared
            isWatched
            updatedAt
          }
          ... on MapCluster {
            name
            count
            location {
              latitude
              longitude
            }
            isMatch
            isShared
            isWatched
            expansionZoom
          }
        }
        pagination {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFieldsFragmentDoc}
`;

/**
 * __useMapSearchQuery__
 *
 * To run a query within a React component, call `useMapSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      zoom: // value for 'zoom'
 *      onlyMatches: // value for 'onlyMatches'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      cluster: // value for 'cluster'
 *      includeUsfsBoundaries: // value for 'includeUsfsBoundaries'
 *      includeBlmBoundaries: // value for 'includeBlmBoundaries'
 *   },
 * });
 */
export function useMapSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<MapSearchQuery, MapSearchQueryVariables>
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<MapSearchQuery, MapSearchQueryVariables>(
    MapSearchDocument,
    options
  );
}
export function useMapSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MapSearchQuery,
    MapSearchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<MapSearchQuery, MapSearchQueryVariables>(
    MapSearchDocument,
    options
  );
}
export function useMapSearchSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<MapSearchQuery, MapSearchQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<MapSearchQuery, MapSearchQueryVariables>(
    MapSearchDocument,
    options
  );
}
export type MapSearchQueryHookResult = ReturnType<typeof useMapSearchQuery>;
export type MapSearchLazyQueryHookResult = ReturnType<
  typeof useMapSearchLazyQuery
>;
export type MapSearchSuspenseQueryHookResult = ReturnType<
  typeof useMapSearchSuspenseQuery
>;
export type MapSearchQueryResult = Apollo.QueryResult<
  MapSearchQuery,
  MapSearchQueryVariables
>;
export const PickerSearchDocument = gql`
  query PickerSearch($term: String, $filter: FilterInput) {
    search {
      mapSearch(
        input: {
          term: $term
          filter: $filter
          onlyMatches: false
          pagination: {page: 1, pageSize: 10}
          cluster: false
        }
      ) {
        results {
          ... on SearchResult {
            type
            subtype
            id
            slug
            name
            context
            location {
              latitude
              longitude
            }
            result {
              ... on Facility {
                park {
                  name
                }
              }
              ... on Unit {
                facility {
                  name
                }
              }
            }
            isMatch
          }
        }
      }
    }
  }
`;

/**
 * __usePickerSearchQuery__
 *
 * To run a query within a React component, call `usePickerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickerSearchQuery({
 *   variables: {
 *      term: // value for 'term'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePickerSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PickerSearchQuery,
    PickerSearchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<PickerSearchQuery, PickerSearchQueryVariables>(
    PickerSearchDocument,
    options
  );
}
export function usePickerSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PickerSearchQuery,
    PickerSearchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<PickerSearchQuery, PickerSearchQueryVariables>(
    PickerSearchDocument,
    options
  );
}
export function usePickerSearchSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PickerSearchQuery,
        PickerSearchQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<PickerSearchQuery, PickerSearchQueryVariables>(
    PickerSearchDocument,
    options
  );
}
export type PickerSearchQueryHookResult = ReturnType<
  typeof usePickerSearchQuery
>;
export type PickerSearchLazyQueryHookResult = ReturnType<
  typeof usePickerSearchLazyQuery
>;
export type PickerSearchSuspenseQueryHookResult = ReturnType<
  typeof usePickerSearchSuspenseQuery
>;
export type PickerSearchQueryResult = Apollo.QueryResult<
  PickerSearchQuery,
  PickerSearchQueryVariables
>;
export const TextSearchDocument = gql`
  query TextSearch(
    $term: String!
    $filter: FilterInput
    $near: LocationInput
    $correlationKey: String
    $page: Int
    $pageSize: Int
  ) {
    search {
      textSearch(
        input: {
          term: $term
          filter: $filter
          near: $near
          correlationKey: $correlationKey
          pagination: {page: $page, pageSize: $pageSize}
        }
      ) {
        results {
          id
          slug
          type
          subtype
          name
          context
        }
        correlationKey
      }
    }
  }
`;

/**
 * __useTextSearchQuery__
 *
 * To run a query within a React component, call `useTextSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextSearchQuery({
 *   variables: {
 *      term: // value for 'term'
 *      filter: // value for 'filter'
 *      near: // value for 'near'
 *      correlationKey: // value for 'correlationKey'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useTextSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    TextSearchQuery,
    TextSearchQueryVariables
  > &
    ({variables: TextSearchQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<TextSearchQuery, TextSearchQueryVariables>(
    TextSearchDocument,
    options
  );
}
export function useTextSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TextSearchQuery,
    TextSearchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<TextSearchQuery, TextSearchQueryVariables>(
    TextSearchDocument,
    options
  );
}
export function useTextSearchSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<TextSearchQuery, TextSearchQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<TextSearchQuery, TextSearchQueryVariables>(
    TextSearchDocument,
    options
  );
}
export type TextSearchQueryHookResult = ReturnType<typeof useTextSearchQuery>;
export type TextSearchLazyQueryHookResult = ReturnType<
  typeof useTextSearchLazyQuery
>;
export type TextSearchSuspenseQueryHookResult = ReturnType<
  typeof useTextSearchSuspenseQuery
>;
export type TextSearchQueryResult = Apollo.QueryResult<
  TextSearchQuery,
  TextSearchQueryVariables
>;
export const TextSearchResultLocationDocument = gql`
  query TextSearchResultLocation(
    $mapableType: String!
    $mapableId: String!
    $correlationKey: String
  ) {
    search {
      textSearchResultLocation(
        mapableType: $mapableType
        mapableId: $mapableId
        correlationKey: $correlationKey
      ) {
        latitude
        longitude
      }
    }
  }
`;

/**
 * __useTextSearchResultLocationQuery__
 *
 * To run a query within a React component, call `useTextSearchResultLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextSearchResultLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextSearchResultLocationQuery({
 *   variables: {
 *      mapableType: // value for 'mapableType'
 *      mapableId: // value for 'mapableId'
 *      correlationKey: // value for 'correlationKey'
 *   },
 * });
 */
export function useTextSearchResultLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    TextSearchResultLocationQuery,
    TextSearchResultLocationQueryVariables
  > &
    (
      | {variables: TextSearchResultLocationQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    TextSearchResultLocationQuery,
    TextSearchResultLocationQueryVariables
  >(TextSearchResultLocationDocument, options);
}
export function useTextSearchResultLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TextSearchResultLocationQuery,
    TextSearchResultLocationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    TextSearchResultLocationQuery,
    TextSearchResultLocationQueryVariables
  >(TextSearchResultLocationDocument, options);
}
export function useTextSearchResultLocationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        TextSearchResultLocationQuery,
        TextSearchResultLocationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    TextSearchResultLocationQuery,
    TextSearchResultLocationQueryVariables
  >(TextSearchResultLocationDocument, options);
}
export type TextSearchResultLocationQueryHookResult = ReturnType<
  typeof useTextSearchResultLocationQuery
>;
export type TextSearchResultLocationLazyQueryHookResult = ReturnType<
  typeof useTextSearchResultLocationLazyQuery
>;
export type TextSearchResultLocationSuspenseQueryHookResult = ReturnType<
  typeof useTextSearchResultLocationSuspenseQuery
>;
export type TextSearchResultLocationQueryResult = Apollo.QueryResult<
  TextSearchResultLocationQuery,
  TextSearchResultLocationQueryVariables
>;
export const GetOrganizationDetailsDocument = gql`
  query GetOrganizationDetails($id: Int!) {
    entities {
      organization(organizationId: $id) {
        ...OrganizationFields
      }
    }
  }
  ${OrganizationFieldsFragmentDoc}
`;

/**
 * __useGetOrganizationDetailsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  > &
    (
      | {variables: GetOrganizationDetailsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  >(GetOrganizationDetailsDocument, options);
}
export function useGetOrganizationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  >(GetOrganizationDetailsDocument, options);
}
export function useGetOrganizationDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetOrganizationDetailsQuery,
        GetOrganizationDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetOrganizationDetailsQuery,
    GetOrganizationDetailsQueryVariables
  >(GetOrganizationDetailsDocument, options);
}
export type GetOrganizationDetailsQueryHookResult = ReturnType<
  typeof useGetOrganizationDetailsQuery
>;
export type GetOrganizationDetailsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationDetailsLazyQuery
>;
export type GetOrganizationDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetOrganizationDetailsSuspenseQuery
>;
export type GetOrganizationDetailsQueryResult = Apollo.QueryResult<
  GetOrganizationDetailsQuery,
  GetOrganizationDetailsQueryVariables
>;
export const GetParkDetailsDocument = gql`
  query GetParkDetails($id: Int, $slug: String) {
    entities {
      park(park: {id: $id, slug: $slug}) {
        ...ParkFields
      }
    }
  }
  ${ParkFieldsFragmentDoc}
`;

/**
 * __useGetParkDetailsQuery__
 *
 * To run a query within a React component, call `useGetParkDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParkDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParkDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetParkDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetParkDetailsQuery,
    GetParkDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetParkDetailsQuery, GetParkDetailsQueryVariables>(
    GetParkDetailsDocument,
    options
  );
}
export function useGetParkDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParkDetailsQuery,
    GetParkDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetParkDetailsQuery, GetParkDetailsQueryVariables>(
    GetParkDetailsDocument,
    options
  );
}
export function useGetParkDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetParkDetailsQuery,
        GetParkDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetParkDetailsQuery,
    GetParkDetailsQueryVariables
  >(GetParkDetailsDocument, options);
}
export type GetParkDetailsQueryHookResult = ReturnType<
  typeof useGetParkDetailsQuery
>;
export type GetParkDetailsLazyQueryHookResult = ReturnType<
  typeof useGetParkDetailsLazyQuery
>;
export type GetParkDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetParkDetailsSuspenseQuery
>;
export type GetParkDetailsQueryResult = Apollo.QueryResult<
  GetParkDetailsQuery,
  GetParkDetailsQueryVariables
>;
export const GetParkDetailsPageDocument = gql`
  query GetParkDetailsPage($id: Int, $slug: String, $reviewPage: Int) {
    entities {
      park(park: {id: $id, slug: $slug}) {
        ...ParkFields
        nearbyParks {
          park {
            ...ParkFields
          }
          distanceMiles
        }
        reviews(pagination: {page: $reviewPage}) {
          reviews {
            ...ReviewFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    }
  }
  ${ParkFieldsFragmentDoc}
  ${ReviewFieldsFragmentDoc}
  ${PaginationFieldsFragmentDoc}
`;

/**
 * __useGetParkDetailsPageQuery__
 *
 * To run a query within a React component, call `useGetParkDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParkDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParkDetailsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      reviewPage: // value for 'reviewPage'
 *   },
 * });
 */
export function useGetParkDetailsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetParkDetailsPageQuery,
    GetParkDetailsPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetParkDetailsPageQuery,
    GetParkDetailsPageQueryVariables
  >(GetParkDetailsPageDocument, options);
}
export function useGetParkDetailsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParkDetailsPageQuery,
    GetParkDetailsPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetParkDetailsPageQuery,
    GetParkDetailsPageQueryVariables
  >(GetParkDetailsPageDocument, options);
}
export function useGetParkDetailsPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetParkDetailsPageQuery,
        GetParkDetailsPageQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetParkDetailsPageQuery,
    GetParkDetailsPageQueryVariables
  >(GetParkDetailsPageDocument, options);
}
export type GetParkDetailsPageQueryHookResult = ReturnType<
  typeof useGetParkDetailsPageQuery
>;
export type GetParkDetailsPageLazyQueryHookResult = ReturnType<
  typeof useGetParkDetailsPageLazyQuery
>;
export type GetParkDetailsPageSuspenseQueryHookResult = ReturnType<
  typeof useGetParkDetailsPageSuspenseQuery
>;
export type GetParkDetailsPageQueryResult = Apollo.QueryResult<
  GetParkDetailsPageQuery,
  GetParkDetailsPageQueryVariables
>;
export const GetFacilityDetailsDocument = gql`
  query GetFacilityDetails($id: Int, $slug: String, $reviewPage: Int) {
    entities {
      facility(facility: {id: $id, slug: $slug}) {
        ...FacilityFields
        park {
          id
          slug
          name
          description
          descriptionHtml
        }
        reviews(pagination: {page: $reviewPage}) {
          reviews {
            ...ReviewFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    }
  }
  ${FacilityFieldsFragmentDoc}
  ${ReviewFieldsFragmentDoc}
  ${PaginationFieldsFragmentDoc}
`;

/**
 * __useGetFacilityDetailsQuery__
 *
 * To run a query within a React component, call `useGetFacilityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      reviewPage: // value for 'reviewPage'
 *   },
 * });
 */
export function useGetFacilityDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  >(GetFacilityDetailsDocument, options);
}
export function useGetFacilityDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  >(GetFacilityDetailsDocument, options);
}
export function useGetFacilityDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFacilityDetailsQuery,
        GetFacilityDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetFacilityDetailsQuery,
    GetFacilityDetailsQueryVariables
  >(GetFacilityDetailsDocument, options);
}
export type GetFacilityDetailsQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsQuery
>;
export type GetFacilityDetailsLazyQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsLazyQuery
>;
export type GetFacilityDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsSuspenseQuery
>;
export type GetFacilityDetailsQueryResult = Apollo.QueryResult<
  GetFacilityDetailsQuery,
  GetFacilityDetailsQueryVariables
>;
export const GetFacilityPopoverDocument = gql`
  query GetFacilityPopover(
    $id: Int
    $slug: String
    $filter: FilterInput
    $onlyMatches: Boolean = true
    $page: Int = 1
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
  ) {
    entities {
      facility(facility: {id: $id, slug: $slug}) {
        ...FacilityCommonFields
        park {
          id
          slug
          name
          description
          descriptionHtml
        }
        units(
          input: {
            filter: $filter
            onlyMatches: $onlyMatches
            pagination: {page: $page, pageSize: $pageSize}
            sort: {field: $sortField, direction: $sortDirection}
          }
        ) {
          ...UnitFields
          slots {
            ...SlotFields
          }
        }
      }
    }
  }
  ${FacilityCommonFieldsFragmentDoc}
  ${UnitFieldsFragmentDoc}
  ${SlotFieldsFragmentDoc}
`;

/**
 * __useGetFacilityPopoverQuery__
 *
 * To run a query within a React component, call `useGetFacilityPopoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityPopoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityPopoverQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      filter: // value for 'filter'
 *      onlyMatches: // value for 'onlyMatches'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetFacilityPopoverQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFacilityPopoverQuery,
    GetFacilityPopoverQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetFacilityPopoverQuery,
    GetFacilityPopoverQueryVariables
  >(GetFacilityPopoverDocument, options);
}
export function useGetFacilityPopoverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFacilityPopoverQuery,
    GetFacilityPopoverQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetFacilityPopoverQuery,
    GetFacilityPopoverQueryVariables
  >(GetFacilityPopoverDocument, options);
}
export function useGetFacilityPopoverSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFacilityPopoverQuery,
        GetFacilityPopoverQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetFacilityPopoverQuery,
    GetFacilityPopoverQueryVariables
  >(GetFacilityPopoverDocument, options);
}
export type GetFacilityPopoverQueryHookResult = ReturnType<
  typeof useGetFacilityPopoverQuery
>;
export type GetFacilityPopoverLazyQueryHookResult = ReturnType<
  typeof useGetFacilityPopoverLazyQuery
>;
export type GetFacilityPopoverSuspenseQueryHookResult = ReturnType<
  typeof useGetFacilityPopoverSuspenseQuery
>;
export type GetFacilityPopoverQueryResult = Apollo.QueryResult<
  GetFacilityPopoverQuery,
  GetFacilityPopoverQueryVariables
>;
export const GetFacilityDetailsPageDocument = gql`
  query GetFacilityDetailsPage(
    $id: Int
    $slug: String
    $filter: FilterInput
    $onlyMatches: Boolean = true
    $page: Int = 1
    $pageSize: Int
    $sortField: String
    $sortDirection: SortDirection
    $reviewPage: Int
  ) {
    entities {
      facility(facility: {id: $id, slug: $slug}) {
        ...FacilityCommonFields
        park {
          id
          slug
          name
          description
          descriptionHtml
        }
        nearbyFacilities {
          facility {
            ...FacilityCommonFields
          }
          distanceMiles
        }
        units(
          input: {
            filter: $filter
            onlyMatches: $onlyMatches
            pagination: {page: $page, pageSize: $pageSize}
            sort: {field: $sortField, direction: $sortDirection}
          }
        ) {
          ...UnitFields
          slots {
            ...SlotFields
          }
        }
        reviews(pagination: {page: $reviewPage}) {
          reviews {
            ...ReviewFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    }
  }
  ${FacilityCommonFieldsFragmentDoc}
  ${UnitFieldsFragmentDoc}
  ${SlotFieldsFragmentDoc}
  ${ReviewFieldsFragmentDoc}
  ${PaginationFieldsFragmentDoc}
`;

/**
 * __useGetFacilityDetailsPageQuery__
 *
 * To run a query within a React component, call `useGetFacilityDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFacilityDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFacilityDetailsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      filter: // value for 'filter'
 *      onlyMatches: // value for 'onlyMatches'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      sortField: // value for 'sortField'
 *      sortDirection: // value for 'sortDirection'
 *      reviewPage: // value for 'reviewPage'
 *   },
 * });
 */
export function useGetFacilityDetailsPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFacilityDetailsPageQuery,
    GetFacilityDetailsPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetFacilityDetailsPageQuery,
    GetFacilityDetailsPageQueryVariables
  >(GetFacilityDetailsPageDocument, options);
}
export function useGetFacilityDetailsPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFacilityDetailsPageQuery,
    GetFacilityDetailsPageQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetFacilityDetailsPageQuery,
    GetFacilityDetailsPageQueryVariables
  >(GetFacilityDetailsPageDocument, options);
}
export function useGetFacilityDetailsPageSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFacilityDetailsPageQuery,
        GetFacilityDetailsPageQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetFacilityDetailsPageQuery,
    GetFacilityDetailsPageQueryVariables
  >(GetFacilityDetailsPageDocument, options);
}
export type GetFacilityDetailsPageQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsPageQuery
>;
export type GetFacilityDetailsPageLazyQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsPageLazyQuery
>;
export type GetFacilityDetailsPageSuspenseQueryHookResult = ReturnType<
  typeof useGetFacilityDetailsPageSuspenseQuery
>;
export type GetFacilityDetailsPageQueryResult = Apollo.QueryResult<
  GetFacilityDetailsPageQuery,
  GetFacilityDetailsPageQueryVariables
>;
export const GetUnitDetailsDocument = gql`
  query GetUnitDetails($id: Int, $slug: String, $reviewPage: Int) {
    entities {
      unit(unit: {id: $id, slug: $slug}) {
        ...UnitFields
        facility {
          id
          slug
          name
          description
          descriptionHtml
          park {
            id
            slug
            name
            description
            descriptionHtml
          }
        }
        reviews(pagination: {page: $reviewPage}) {
          reviews {
            ...ReviewFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    }
  }
  ${UnitFieldsFragmentDoc}
  ${ReviewFieldsFragmentDoc}
  ${PaginationFieldsFragmentDoc}
`;

/**
 * __useGetUnitDetailsQuery__
 *
 * To run a query within a React component, call `useGetUnitDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      reviewPage: // value for 'reviewPage'
 *   },
 * });
 */
export function useGetUnitDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnitDetailsQuery,
    GetUnitDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetUnitDetailsQuery, GetUnitDetailsQueryVariables>(
    GetUnitDetailsDocument,
    options
  );
}
export function useGetUnitDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnitDetailsQuery,
    GetUnitDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetUnitDetailsQuery, GetUnitDetailsQueryVariables>(
    GetUnitDetailsDocument,
    options
  );
}
export function useGetUnitDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUnitDetailsQuery,
        GetUnitDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetUnitDetailsQuery,
    GetUnitDetailsQueryVariables
  >(GetUnitDetailsDocument, options);
}
export type GetUnitDetailsQueryHookResult = ReturnType<
  typeof useGetUnitDetailsQuery
>;
export type GetUnitDetailsLazyQueryHookResult = ReturnType<
  typeof useGetUnitDetailsLazyQuery
>;
export type GetUnitDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetUnitDetailsSuspenseQuery
>;
export type GetUnitDetailsQueryResult = Apollo.QueryResult<
  GetUnitDetailsQuery,
  GetUnitDetailsQueryVariables
>;
export const GetUnitDetailsWithSlotsDocument = gql`
  query GetUnitDetailsWithSlots(
    $id: Int
    $slug: String
    $beginDate: Date!
    $endDate: Date!
    $reviewPage: Int
  ) {
    entities {
      unit(unit: {id: $id, slug: $slug}) {
        ...UnitFields
        slots(input: {beginDate: $beginDate, endDate: $endDate}) {
          ...SlotFields
        }
        facility {
          id
          slug
          name
          description
          descriptionHtml
          park {
            id
            slug
            name
            description
            descriptionHtml
          }
        }
        reviews(pagination: {page: $reviewPage}) {
          reviews {
            ...ReviewFields
          }
          pagination {
            ...PaginationFields
          }
        }
      }
    }
  }
  ${UnitFieldsFragmentDoc}
  ${SlotFieldsFragmentDoc}
  ${ReviewFieldsFragmentDoc}
  ${PaginationFieldsFragmentDoc}
`;

/**
 * __useGetUnitDetailsWithSlotsQuery__
 *
 * To run a query within a React component, call `useGetUnitDetailsWithSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnitDetailsWithSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnitDetailsWithSlotsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *      reviewPage: // value for 'reviewPage'
 *   },
 * });
 */
export function useGetUnitDetailsWithSlotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  > &
    (
      | {variables: GetUnitDetailsWithSlotsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  >(GetUnitDetailsWithSlotsDocument, options);
}
export function useGetUnitDetailsWithSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  >(GetUnitDetailsWithSlotsDocument, options);
}
export function useGetUnitDetailsWithSlotsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetUnitDetailsWithSlotsQuery,
        GetUnitDetailsWithSlotsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetUnitDetailsWithSlotsQuery,
    GetUnitDetailsWithSlotsQueryVariables
  >(GetUnitDetailsWithSlotsDocument, options);
}
export type GetUnitDetailsWithSlotsQueryHookResult = ReturnType<
  typeof useGetUnitDetailsWithSlotsQuery
>;
export type GetUnitDetailsWithSlotsLazyQueryHookResult = ReturnType<
  typeof useGetUnitDetailsWithSlotsLazyQuery
>;
export type GetUnitDetailsWithSlotsSuspenseQueryHookResult = ReturnType<
  typeof useGetUnitDetailsWithSlotsSuspenseQuery
>;
export type GetUnitDetailsWithSlotsQueryResult = Apollo.QueryResult<
  GetUnitDetailsWithSlotsQuery,
  GetUnitDetailsWithSlotsQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    users {
      currentUser {
        ...AuthenticatedUserFields
      }
    }
  }
  ${AuthenticatedUserFieldsFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export function useGetCurrentUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCurrentUserQuery,
        GetCurrentUserQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GetCurrentUserDocument, options);
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useGetCurrentUserSuspenseQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const GetMaxDateDocument = gql`
  query GetMaxDate {
    search {
      maxDate
    }
  }
`;

/**
 * __useGetMaxDateQuery__
 *
 * To run a query within a React component, call `useGetMaxDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaxDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaxDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMaxDateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMaxDateQuery,
    GetMaxDateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetMaxDateQuery, GetMaxDateQueryVariables>(
    GetMaxDateDocument,
    options
  );
}
export function useGetMaxDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMaxDateQuery,
    GetMaxDateQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetMaxDateQuery, GetMaxDateQueryVariables>(
    GetMaxDateDocument,
    options
  );
}
export function useGetMaxDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetMaxDateQuery, GetMaxDateQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<GetMaxDateQuery, GetMaxDateQueryVariables>(
    GetMaxDateDocument,
    options
  );
}
export type GetMaxDateQueryHookResult = ReturnType<typeof useGetMaxDateQuery>;
export type GetMaxDateLazyQueryHookResult = ReturnType<
  typeof useGetMaxDateLazyQuery
>;
export type GetMaxDateSuspenseQueryHookResult = ReturnType<
  typeof useGetMaxDateSuspenseQuery
>;
export type GetMaxDateQueryResult = Apollo.QueryResult<
  GetMaxDateQuery,
  GetMaxDateQueryVariables
>;
export const GetLocationDocument = gql`
  query GetLocation {
    users {
      location {
        latitude
        longitude
      }
    }
  }
`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  );
}
export function useGetLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationQuery,
    GetLocationQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  );
}
export function useGetLocationSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetLocationQuery,
        GetLocationQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  );
}
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<
  typeof useGetLocationLazyQuery
>;
export type GetLocationSuspenseQueryHookResult = ReturnType<
  typeof useGetLocationSuspenseQuery
>;
export type GetLocationQueryResult = Apollo.QueryResult<
  GetLocationQuery,
  GetLocationQueryVariables
>;
export const GetFeatureFlagDocument = gql`
  query GetFeatureFlag($flag: String!) {
    features {
      isEnabled(flag: $flag)
    }
  }
`;

/**
 * __useGetFeatureFlagQuery__
 *
 * To run a query within a React component, call `useGetFeatureFlagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureFlagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureFlagQuery({
 *   variables: {
 *      flag: // value for 'flag'
 *   },
 * });
 */
export function useGetFeatureFlagQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFeatureFlagQuery,
    GetFeatureFlagQueryVariables
  > &
    (
      | {variables: GetFeatureFlagQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(
    GetFeatureFlagDocument,
    options
  );
}
export function useGetFeatureFlagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeatureFlagQuery,
    GetFeatureFlagQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetFeatureFlagQuery, GetFeatureFlagQueryVariables>(
    GetFeatureFlagDocument,
    options
  );
}
export function useGetFeatureFlagSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetFeatureFlagQuery,
        GetFeatureFlagQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetFeatureFlagQuery,
    GetFeatureFlagQueryVariables
  >(GetFeatureFlagDocument, options);
}
export type GetFeatureFlagQueryHookResult = ReturnType<
  typeof useGetFeatureFlagQuery
>;
export type GetFeatureFlagLazyQueryHookResult = ReturnType<
  typeof useGetFeatureFlagLazyQuery
>;
export type GetFeatureFlagSuspenseQueryHookResult = ReturnType<
  typeof useGetFeatureFlagSuspenseQuery
>;
export type GetFeatureFlagQueryResult = Apollo.QueryResult<
  GetFeatureFlagQuery,
  GetFeatureFlagQueryVariables
>;
export const GetCheckoutConfigDocument = gql`
  query GetCheckoutConfig {
    subscriptions {
      checkoutConfig {
        publishableKey
        plans {
          id
          name
          description
          amount
          currency
          interval
        }
      }
    }
  }
`;

/**
 * __useGetCheckoutConfigQuery__
 *
 * To run a query within a React component, call `useGetCheckoutConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCheckoutConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >(GetCheckoutConfigDocument, options);
}
export function useGetCheckoutConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >(GetCheckoutConfigDocument, options);
}
export function useGetCheckoutConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetCheckoutConfigQuery,
        GetCheckoutConfigQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetCheckoutConfigQuery,
    GetCheckoutConfigQueryVariables
  >(GetCheckoutConfigDocument, options);
}
export type GetCheckoutConfigQueryHookResult = ReturnType<
  typeof useGetCheckoutConfigQuery
>;
export type GetCheckoutConfigLazyQueryHookResult = ReturnType<
  typeof useGetCheckoutConfigLazyQuery
>;
export type GetCheckoutConfigSuspenseQueryHookResult = ReturnType<
  typeof useGetCheckoutConfigSuspenseQuery
>;
export type GetCheckoutConfigQueryResult = Apollo.QueryResult<
  GetCheckoutConfigQuery,
  GetCheckoutConfigQueryVariables
>;
export const GetWatchListDocument = gql`
  query GetWatchList($status: WatchStatus) {
    watches {
      watchList(status: $status) {
        ...WatchFields
      }
    }
  }
  ${WatchFieldsFragmentDoc}
`;

/**
 * __useGetWatchListQuery__
 *
 * To run a query within a React component, call `useGetWatchListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchListQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetWatchListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWatchListQuery,
    GetWatchListQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetWatchListQuery, GetWatchListQueryVariables>(
    GetWatchListDocument,
    options
  );
}
export function useGetWatchListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWatchListQuery,
    GetWatchListQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetWatchListQuery, GetWatchListQueryVariables>(
    GetWatchListDocument,
    options
  );
}
export function useGetWatchListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetWatchListQuery,
        GetWatchListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<GetWatchListQuery, GetWatchListQueryVariables>(
    GetWatchListDocument,
    options
  );
}
export type GetWatchListQueryHookResult = ReturnType<
  typeof useGetWatchListQuery
>;
export type GetWatchListLazyQueryHookResult = ReturnType<
  typeof useGetWatchListLazyQuery
>;
export type GetWatchListSuspenseQueryHookResult = ReturnType<
  typeof useGetWatchListSuspenseQuery
>;
export type GetWatchListQueryResult = Apollo.QueryResult<
  GetWatchListQuery,
  GetWatchListQueryVariables
>;
export const GetWatchDocument = gql`
  query GetWatch($id: Int!) {
    watches {
      watch(watchId: $id) {
        ...WatchFields
      }
    }
  }
  ${WatchFieldsFragmentDoc}
`;

/**
 * __useGetWatchQuery__
 *
 * To run a query within a React component, call `useGetWatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWatchQuery(
  baseOptions: Apollo.QueryHookOptions<GetWatchQuery, GetWatchQueryVariables> &
    ({variables: GetWatchQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetWatchQuery, GetWatchQueryVariables>(
    GetWatchDocument,
    options
  );
}
export function useGetWatchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWatchQuery,
    GetWatchQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetWatchQuery, GetWatchQueryVariables>(
    GetWatchDocument,
    options
  );
}
export function useGetWatchSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetWatchQuery, GetWatchQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<GetWatchQuery, GetWatchQueryVariables>(
    GetWatchDocument,
    options
  );
}
export type GetWatchQueryHookResult = ReturnType<typeof useGetWatchQuery>;
export type GetWatchLazyQueryHookResult = ReturnType<
  typeof useGetWatchLazyQuery
>;
export type GetWatchSuspenseQueryHookResult = ReturnType<
  typeof useGetWatchSuspenseQuery
>;
export type GetWatchQueryResult = Apollo.QueryResult<
  GetWatchQuery,
  GetWatchQueryVariables
>;
export const GetWatchFoundUnitsDocument = gql`
  query GetWatchFoundUnits($id: Int!, $beginDate: Date!, $endDate: Date!) {
    watches {
      watch(watchId: $id) {
        id
        foundUnits {
          ...UnitFields
          facility {
            ...FacilityWithoutUnitsFields
          }
          slots(input: {beginDate: $beginDate, endDate: $endDate}) {
            ...SlotFields
          }
        }
      }
    }
  }
  ${UnitFieldsFragmentDoc}
  ${FacilityWithoutUnitsFieldsFragmentDoc}
  ${SlotFieldsFragmentDoc}
`;

/**
 * __useGetWatchFoundUnitsQuery__
 *
 * To run a query within a React component, call `useGetWatchFoundUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchFoundUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchFoundUnitsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      beginDate: // value for 'beginDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetWatchFoundUnitsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWatchFoundUnitsQuery,
    GetWatchFoundUnitsQueryVariables
  > &
    (
      | {variables: GetWatchFoundUnitsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetWatchFoundUnitsQuery,
    GetWatchFoundUnitsQueryVariables
  >(GetWatchFoundUnitsDocument, options);
}
export function useGetWatchFoundUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWatchFoundUnitsQuery,
    GetWatchFoundUnitsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetWatchFoundUnitsQuery,
    GetWatchFoundUnitsQueryVariables
  >(GetWatchFoundUnitsDocument, options);
}
export function useGetWatchFoundUnitsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetWatchFoundUnitsQuery,
        GetWatchFoundUnitsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetWatchFoundUnitsQuery,
    GetWatchFoundUnitsQueryVariables
  >(GetWatchFoundUnitsDocument, options);
}
export type GetWatchFoundUnitsQueryHookResult = ReturnType<
  typeof useGetWatchFoundUnitsQuery
>;
export type GetWatchFoundUnitsLazyQueryHookResult = ReturnType<
  typeof useGetWatchFoundUnitsLazyQuery
>;
export type GetWatchFoundUnitsSuspenseQueryHookResult = ReturnType<
  typeof useGetWatchFoundUnitsSuspenseQuery
>;
export type GetWatchFoundUnitsQueryResult = Apollo.QueryResult<
  GetWatchFoundUnitsQuery,
  GetWatchFoundUnitsQueryVariables
>;
export const GetWatchNameAvailableDocument = gql`
  query GetWatchNameAvailable($name: String! = "") {
    watches {
      nameAvailable(name: $name)
    }
  }
`;

/**
 * __useGetWatchNameAvailableQuery__
 *
 * To run a query within a React component, call `useGetWatchNameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchNameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchNameAvailableQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetWatchNameAvailableQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWatchNameAvailableQuery,
    GetWatchNameAvailableQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetWatchNameAvailableQuery,
    GetWatchNameAvailableQueryVariables
  >(GetWatchNameAvailableDocument, options);
}
export function useGetWatchNameAvailableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWatchNameAvailableQuery,
    GetWatchNameAvailableQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetWatchNameAvailableQuery,
    GetWatchNameAvailableQueryVariables
  >(GetWatchNameAvailableDocument, options);
}
export function useGetWatchNameAvailableSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetWatchNameAvailableQuery,
        GetWatchNameAvailableQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetWatchNameAvailableQuery,
    GetWatchNameAvailableQueryVariables
  >(GetWatchNameAvailableDocument, options);
}
export type GetWatchNameAvailableQueryHookResult = ReturnType<
  typeof useGetWatchNameAvailableQuery
>;
export type GetWatchNameAvailableLazyQueryHookResult = ReturnType<
  typeof useGetWatchNameAvailableLazyQuery
>;
export type GetWatchNameAvailableSuspenseQueryHookResult = ReturnType<
  typeof useGetWatchNameAvailableSuspenseQuery
>;
export type GetWatchNameAvailableQueryResult = Apollo.QueryResult<
  GetWatchNameAvailableQuery,
  GetWatchNameAvailableQueryVariables
>;
export const GetRecentlyCheckedFacilitiesDocument = gql`
  query GetRecentlyCheckedFacilities {
    entities {
      recentlyCheckedFacilities {
        id
        slug
        name
        park {
          id
          slug
          name
        }
        lastCheckedAt
      }
    }
  }
`;

/**
 * __useGetRecentlyCheckedFacilitiesQuery__
 *
 * To run a query within a React component, call `useGetRecentlyCheckedFacilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentlyCheckedFacilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentlyCheckedFacilitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecentlyCheckedFacilitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >(GetRecentlyCheckedFacilitiesDocument, options);
}
export function useGetRecentlyCheckedFacilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >(GetRecentlyCheckedFacilitiesDocument, options);
}
export function useGetRecentlyCheckedFacilitiesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetRecentlyCheckedFacilitiesQuery,
        GetRecentlyCheckedFacilitiesQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetRecentlyCheckedFacilitiesQuery,
    GetRecentlyCheckedFacilitiesQueryVariables
  >(GetRecentlyCheckedFacilitiesDocument, options);
}
export type GetRecentlyCheckedFacilitiesQueryHookResult = ReturnType<
  typeof useGetRecentlyCheckedFacilitiesQuery
>;
export type GetRecentlyCheckedFacilitiesLazyQueryHookResult = ReturnType<
  typeof useGetRecentlyCheckedFacilitiesLazyQuery
>;
export type GetRecentlyCheckedFacilitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetRecentlyCheckedFacilitiesSuspenseQuery
>;
export type GetRecentlyCheckedFacilitiesQueryResult = Apollo.QueryResult<
  GetRecentlyCheckedFacilitiesQuery,
  GetRecentlyCheckedFacilitiesQueryVariables
>;
export const CalculateUsedCreditsDocument = gql`
  query CalculateUsedCredits(
    $parkIds: [Int!]!
    $facilityIds: [Int!]!
    $unitIds: [Int!]!
  ) {
    subscriptions {
      calculateUsedCredits(
        parkIds: $parkIds
        facilityIds: $facilityIds
        unitIds: $unitIds
      )
    }
  }
`;

/**
 * __useCalculateUsedCreditsQuery__
 *
 * To run a query within a React component, call `useCalculateUsedCreditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateUsedCreditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateUsedCreditsQuery({
 *   variables: {
 *      parkIds: // value for 'parkIds'
 *      facilityIds: // value for 'facilityIds'
 *      unitIds: // value for 'unitIds'
 *   },
 * });
 */
export function useCalculateUsedCreditsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  > &
    (
      | {variables: CalculateUsedCreditsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  >(CalculateUsedCreditsDocument, options);
}
export function useCalculateUsedCreditsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  >(CalculateUsedCreditsDocument, options);
}
export function useCalculateUsedCreditsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        CalculateUsedCreditsQuery,
        CalculateUsedCreditsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    CalculateUsedCreditsQuery,
    CalculateUsedCreditsQueryVariables
  >(CalculateUsedCreditsDocument, options);
}
export type CalculateUsedCreditsQueryHookResult = ReturnType<
  typeof useCalculateUsedCreditsQuery
>;
export type CalculateUsedCreditsLazyQueryHookResult = ReturnType<
  typeof useCalculateUsedCreditsLazyQuery
>;
export type CalculateUsedCreditsSuspenseQueryHookResult = ReturnType<
  typeof useCalculateUsedCreditsSuspenseQuery
>;
export type CalculateUsedCreditsQueryResult = Apollo.QueryResult<
  CalculateUsedCreditsQuery,
  CalculateUsedCreditsQueryVariables
>;
export const EmailTakenDocument = gql`
  query EmailTaken($email: String!, $captchaResponse: String!) {
    users {
      emailTaken(input: {email: $email, captchaResponse: $captchaResponse}) {
        ... on EmailTakenSuccessResult {
          email
          taken
        }
        ... on FormError {
          field
          message
        }
      }
    }
  }
`;

/**
 * __useEmailTakenQuery__
 *
 * To run a query within a React component, call `useEmailTakenQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailTakenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailTakenQuery({
 *   variables: {
 *      email: // value for 'email'
 *      captchaResponse: // value for 'captchaResponse'
 *   },
 * });
 */
export function useEmailTakenQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmailTakenQuery,
    EmailTakenQueryVariables
  > &
    ({variables: EmailTakenQueryVariables; skip?: boolean} | {skip: boolean})
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<EmailTakenQuery, EmailTakenQueryVariables>(
    EmailTakenDocument,
    options
  );
}
export function useEmailTakenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailTakenQuery,
    EmailTakenQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<EmailTakenQuery, EmailTakenQueryVariables>(
    EmailTakenDocument,
    options
  );
}
export function useEmailTakenSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<EmailTakenQuery, EmailTakenQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<EmailTakenQuery, EmailTakenQueryVariables>(
    EmailTakenDocument,
    options
  );
}
export type EmailTakenQueryHookResult = ReturnType<typeof useEmailTakenQuery>;
export type EmailTakenLazyQueryHookResult = ReturnType<
  typeof useEmailTakenLazyQuery
>;
export type EmailTakenSuspenseQueryHookResult = ReturnType<
  typeof useEmailTakenSuspenseQuery
>;
export type EmailTakenQueryResult = Apollo.QueryResult<
  EmailTakenQuery,
  EmailTakenQueryVariables
>;
export const GetContentListDocument = gql`
  query GetContentList($type: String!, $page: Int! = 1) {
    cms {
      contentList(input: {contentType: $type, pagination: {page: $page}}) {
        contentList {
          type
          slug
          title
          tagline
          updatedAt
        }
        pagination {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFieldsFragmentDoc}
`;

/**
 * __useGetContentListQuery__
 *
 * To run a query within a React component, call `useGetContentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentListQuery({
 *   variables: {
 *      type: // value for 'type'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetContentListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContentListQuery,
    GetContentListQueryVariables
  > &
    (
      | {variables: GetContentListQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<GetContentListQuery, GetContentListQueryVariables>(
    GetContentListDocument,
    options
  );
}
export function useGetContentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentListQuery,
    GetContentListQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<GetContentListQuery, GetContentListQueryVariables>(
    GetContentListDocument,
    options
  );
}
export function useGetContentListSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContentListQuery,
        GetContentListQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetContentListQuery,
    GetContentListQueryVariables
  >(GetContentListDocument, options);
}
export type GetContentListQueryHookResult = ReturnType<
  typeof useGetContentListQuery
>;
export type GetContentListLazyQueryHookResult = ReturnType<
  typeof useGetContentListLazyQuery
>;
export type GetContentListSuspenseQueryHookResult = ReturnType<
  typeof useGetContentListSuspenseQuery
>;
export type GetContentListQueryResult = Apollo.QueryResult<
  GetContentListQuery,
  GetContentListQueryVariables
>;
export const GetContentSummaryDocument = gql`
  query GetContentSummary($slug: String!, $type: String!) {
    cms {
      content(slug: $slug, contentType: $type) {
        ...ContentFields
      }
    }
  }
  ${ContentFieldsFragmentDoc}
`;

/**
 * __useGetContentSummaryQuery__
 *
 * To run a query within a React component, call `useGetContentSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentSummaryQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetContentSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContentSummaryQuery,
    GetContentSummaryQueryVariables
  > &
    (
      | {variables: GetContentSummaryQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetContentSummaryQuery,
    GetContentSummaryQueryVariables
  >(GetContentSummaryDocument, options);
}
export function useGetContentSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentSummaryQuery,
    GetContentSummaryQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetContentSummaryQuery,
    GetContentSummaryQueryVariables
  >(GetContentSummaryDocument, options);
}
export function useGetContentSummarySuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContentSummaryQuery,
        GetContentSummaryQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetContentSummaryQuery,
    GetContentSummaryQueryVariables
  >(GetContentSummaryDocument, options);
}
export type GetContentSummaryQueryHookResult = ReturnType<
  typeof useGetContentSummaryQuery
>;
export type GetContentSummaryLazyQueryHookResult = ReturnType<
  typeof useGetContentSummaryLazyQuery
>;
export type GetContentSummarySuspenseQueryHookResult = ReturnType<
  typeof useGetContentSummarySuspenseQuery
>;
export type GetContentSummaryQueryResult = Apollo.QueryResult<
  GetContentSummaryQuery,
  GetContentSummaryQueryVariables
>;
export const GetContentDetailsDocument = gql`
  query GetContentDetails($slug: String!, $type: String!) {
    cms {
      content(slug: $slug, contentType: $type) {
        ...ContentFields
        attachments {
          ...ContentAttachmentFields
        }
      }
    }
  }
  ${ContentFieldsFragmentDoc}
  ${ContentAttachmentFieldsFragmentDoc}
`;

/**
 * __useGetContentDetailsQuery__
 *
 * To run a query within a React component, call `useGetContentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentDetailsQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetContentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetContentDetailsQuery,
    GetContentDetailsQueryVariables
  > &
    (
      | {variables: GetContentDetailsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetContentDetailsQuery,
    GetContentDetailsQueryVariables
  >(GetContentDetailsDocument, options);
}
export function useGetContentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetContentDetailsQuery,
    GetContentDetailsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetContentDetailsQuery,
    GetContentDetailsQueryVariables
  >(GetContentDetailsDocument, options);
}
export function useGetContentDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetContentDetailsQuery,
        GetContentDetailsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetContentDetailsQuery,
    GetContentDetailsQueryVariables
  >(GetContentDetailsDocument, options);
}
export type GetContentDetailsQueryHookResult = ReturnType<
  typeof useGetContentDetailsQuery
>;
export type GetContentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetContentDetailsLazyQuery
>;
export type GetContentDetailsSuspenseQueryHookResult = ReturnType<
  typeof useGetContentDetailsSuspenseQuery
>;
export type GetContentDetailsQueryResult = Apollo.QueryResult<
  GetContentDetailsQuery,
  GetContentDetailsQueryVariables
>;
export const ContentSlugAvailableDocument = gql`
  query ContentSlugAvailable($slug: String!, $type: String!) {
    cms {
      slugAvailable(slug: $slug, contentType: $type)
    }
  }
`;

/**
 * __useContentSlugAvailableQuery__
 *
 * To run a query within a React component, call `useContentSlugAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentSlugAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentSlugAvailableQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useContentSlugAvailableQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContentSlugAvailableQuery,
    ContentSlugAvailableQueryVariables
  > &
    (
      | {variables: ContentSlugAvailableQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    ContentSlugAvailableQuery,
    ContentSlugAvailableQueryVariables
  >(ContentSlugAvailableDocument, options);
}
export function useContentSlugAvailableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentSlugAvailableQuery,
    ContentSlugAvailableQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    ContentSlugAvailableQuery,
    ContentSlugAvailableQueryVariables
  >(ContentSlugAvailableDocument, options);
}
export function useContentSlugAvailableSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ContentSlugAvailableQuery,
        ContentSlugAvailableQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    ContentSlugAvailableQuery,
    ContentSlugAvailableQueryVariables
  >(ContentSlugAvailableDocument, options);
}
export type ContentSlugAvailableQueryHookResult = ReturnType<
  typeof useContentSlugAvailableQuery
>;
export type ContentSlugAvailableLazyQueryHookResult = ReturnType<
  typeof useContentSlugAvailableLazyQuery
>;
export type ContentSlugAvailableSuspenseQueryHookResult = ReturnType<
  typeof useContentSlugAvailableSuspenseQuery
>;
export type ContentSlugAvailableQueryResult = Apollo.QueryResult<
  ContentSlugAvailableQuery,
  ContentSlugAvailableQueryVariables
>;
export const GetInvitationDefaultsDocument = gql`
  query GetInvitationDefaults($token: String!) {
    users {
      invitationDefaults(token: $token) {
        email
      }
    }
  }
`;

/**
 * __useGetInvitationDefaultsQuery__
 *
 * To run a query within a React component, call `useGetInvitationDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationDefaultsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetInvitationDefaultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvitationDefaultsQuery,
    GetInvitationDefaultsQueryVariables
  > &
    (
      | {variables: GetInvitationDefaultsQueryVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useQuery<
    GetInvitationDefaultsQuery,
    GetInvitationDefaultsQueryVariables
  >(GetInvitationDefaultsDocument, options);
}
export function useGetInvitationDefaultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvitationDefaultsQuery,
    GetInvitationDefaultsQueryVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useLazyQuery<
    GetInvitationDefaultsQuery,
    GetInvitationDefaultsQueryVariables
  >(GetInvitationDefaultsDocument, options);
}
export function useGetInvitationDefaultsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetInvitationDefaultsQuery,
        GetInvitationDefaultsQueryVariables
      >
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : {...defaultOptions, ...baseOptions};
  return Apollo.useSuspenseQuery<
    GetInvitationDefaultsQuery,
    GetInvitationDefaultsQueryVariables
  >(GetInvitationDefaultsDocument, options);
}
export type GetInvitationDefaultsQueryHookResult = ReturnType<
  typeof useGetInvitationDefaultsQuery
>;
export type GetInvitationDefaultsLazyQueryHookResult = ReturnType<
  typeof useGetInvitationDefaultsLazyQuery
>;
export type GetInvitationDefaultsSuspenseQueryHookResult = ReturnType<
  typeof useGetInvitationDefaultsSuspenseQuery
>;
export type GetInvitationDefaultsQueryResult = Apollo.QueryResult<
  GetInvitationDefaultsQuery,
  GetInvitationDefaultsQueryVariables
>;
export const CountDocument = gql`
  subscription Count($target: Int) {
    count(target: $target)
  }
`;

/**
 * __useCountSubscription__
 *
 * To run a query within a React component, call `useCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountSubscription({
 *   variables: {
 *      target: // value for 'target'
 *   },
 * });
 */
export function useCountSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    CountSubscription,
    CountSubscriptionVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSubscription<CountSubscription, CountSubscriptionVariables>(
    CountDocument,
    options
  );
}
export type CountSubscriptionHookResult = ReturnType<
  typeof useCountSubscription
>;
export type CountSubscriptionResult =
  Apollo.SubscriptionResult<CountSubscription>;
export const ChatMessagesDocument = gql`
  subscription ChatMessages {
    chatMessages {
      ...ChatMessageFields
    }
  }
  ${ChatMessageFieldsFragmentDoc}
`;

/**
 * __useChatMessagesSubscription__
 *
 * To run a query within a React component, call `useChatMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessagesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatMessagesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ChatMessagesSubscription,
    ChatMessagesSubscriptionVariables
  >
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSubscription<
    ChatMessagesSubscription,
    ChatMessagesSubscriptionVariables
  >(ChatMessagesDocument, options);
}
export type ChatMessagesSubscriptionHookResult = ReturnType<
  typeof useChatMessagesSubscription
>;
export type ChatMessagesSubscriptionResult =
  Apollo.SubscriptionResult<ChatMessagesSubscription>;
export const UpdatesDocument = gql`
  subscription Updates($inputs: [UpdateInput!]!) {
    updates(inputs: $inputs) {
      updatedAt
      modelClass
      modelId
      model {
        ...SearchableFields
      }
    }
  }
  ${SearchableFieldsFragmentDoc}
`;

/**
 * __useUpdatesSubscription__
 *
 * To run a query within a React component, call `useUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdatesSubscription({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdatesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    UpdatesSubscription,
    UpdatesSubscriptionVariables
  > &
    (
      | {variables: UpdatesSubscriptionVariables; skip?: boolean}
      | {skip: boolean}
    )
) {
  const options = {...defaultOptions, ...baseOptions};
  return Apollo.useSubscription<
    UpdatesSubscription,
    UpdatesSubscriptionVariables
  >(UpdatesDocument, options);
}
export type UpdatesSubscriptionHookResult = ReturnType<
  typeof useUpdatesSubscription
>;
export type UpdatesSubscriptionResult =
  Apollo.SubscriptionResult<UpdatesSubscription>;
