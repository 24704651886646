import {toTemporalInstant} from "@js-temporal/polyfill";
import {isNil, omitBy} from "lodash";

import {APP_NAME} from "../constants";
import type {Location} from "../graphql/types";

// @ts-ignore
Date.prototype.toTemporalInstant = toTemporalInstant;

/**
 * jinja2 batch function
 * Source: https://github.com/jonbretman/jinja-to-js/blob/master/jinja-to-js-runtime.js
 *
 * @param arr Array to batch
 * @param size Batch size
 * @param fillWith Optional value to pad the last batch with to ensure it's
 *   of length `size`
 * @return Batched values
 */
export function batch(arr: any, size: number, fillWith?: number): Array<any[]> {
  const batched = arr.reduce(function (result: any, value: any) {
    let curr: any = result[result.length - 1];
    if (!curr || curr.length === size) {
      result.push([]);
      curr = result[result.length - 1];
    }

    curr.push(value);
    return result;
  }, []);

  const last: any = batched[batched.length - 1];
  if (last && last.length < size && fillWith !== undefined) {
    for (let i = 0; i < size - last.length; i++) {
      last.push(fillWith);
    }
  }

  return batched;
}

export function getParamValue(param: string | string[] | void): string {
  if (Array.isArray(param)) {
    // Get first value
    return param[0] || "";
  } else {
    return param || "";
  }
}

export function getIDParamValue(param: string | string[] | void): number {
  if (isNil(param)) {
    throw new Error("id value is required");
  }

  const value = getParamValue(param);
  return parseInt(value, 10);
}

/**
 * Check if value is an integer
 *
 * @param value Number or string
 * @return Whether or not the value is an integer
 */
export function isInt(value: any): boolean {
  if (!value && value !== 0) {
    return false;
  }

  return "" + parseInt("" + value, 10) === value;
}

/**
 * Get the query string portion of a URL
 *
 * @param url URL to parse query string from
 * @return Query string portion of the URL
 */
export function parseQueryString(url: string | void): string | null {
  if (!url) {
    return null;
  }

  const queryPos = url.indexOf("?");
  if (queryPos === -1) {
    return null;
  }

  return url.substring(queryPos + 1);
}

/**
 * Format a page <meta> tag description so that it's SEO-friendly
 *
 * @param description Description to format
 * @return SEO friendly description
 */
export function formatSEODescription(description: string | void): string {
  const maxDescriptionLength = 163;

  if (!description) {
    return "";
  }

  let formatted = description.substring(0, maxDescriptionLength - 3);
  if (formatted !== description) {
    formatted += "...";
  }

  return formatted;
}

/**
 * Recursively converts values in object to null if they are blank,
 * meaning they are one of:
 *   - undefined
 *   - null
 *   - Empty string
 *
 * @param values Object to convert blanks to nulls
 * @return Copy of values object with all blank values converted to nulls
 */
export function blanksAsNulls(values: any) {
  const nextValues: any = {};

  for (const k in values) {
    if (!values.hasOwnProperty(k)) {
      continue;
    }

    const currValue = values[k];
    if (isNil(currValue)) {
      nextValues[k] = currValue;
    } else if (typeof currValue === "string" && currValue === "") {
      nextValues[k] = null;
    } else if (typeof currValue === "object") {
      nextValues[k] = blanksAsNulls(currValue);
    } else {
      nextValues[k] = currValue;
    }
  }

  return nextValues;
}

/**
 * Parse a document.cookie value
 *
 * @param str Full value of Set-Cookie header or document.cookie value
 * @return Parsed cookie object
 */
export const parseCookie = (str?: string | null): {[key: string]: string} =>
  (str || "")
    .split(";")
    .map((v: string) => v.split("="))
    .filter((pair: string[]) => pair.length === 2)
    .reduce((acc: {[key: string]: string}, pair: string[]) => {
      acc[decodeURIComponent(pair[0].trim())] = decodeURIComponent(
        pair[1].trim()
      );
      return acc;
    }, {});

/**
 * Get minimum and maximum value from a list of values
 *
 * @param values List of values to find min/max of (missing values will be ignored)
 * @return minimum, maximum
 */
export function minMax<T>(values: T[]): [T | null, T | null] {
  let minValue: T | null = null;
  let maxValue: T | null = null;

  for (const value of values) {
    // Ignore missing values
    if (isNil(value)) {
      continue;
    }

    if (!minValue || minValue > value) {
      minValue = value;
    }

    if (!maxValue || maxValue < value) {
      maxValue = value;
    }
  }

  return [minValue, maxValue];
}

/**
 * Clamp value between min and max inclusive
 *
 * @param num Number to clamp
 * @param min Minimum value
 * @param max Maximum value
 * @return Clamped value
 */
export function clamp(num: number, min: number, max: number): number {
  return Math.min(Math.max(num, min), max);
}

/**
 * Redirect to URL with a full page load
 * This differs from NextRouter.push() which just navigates within the same SPA
 *
 * @param url
 */
export function redirect(url: string): void {
  if (typeof window === "undefined") {
    // Server-side
    return;
  }

  window.location.href = url;
}

/**
 * Filter out object values that are null or undefined
 *
 * @param v Object to filter
 * @return Object with omitted keys
 */
export function omitNil(v: any): any {
  return omitBy(v, vv => isNil(vv));
}

/**
 * Is this a mobile device?
 */
export function isMobile(): boolean {
  return getMobileOS() !== "unknown";
}

export function getMobileOS(): string {
  if (typeof navigator === "undefined" || typeof window === "undefined") {
    return "unknown";
  }

  const userAgent =
    navigator.userAgent || (navigator as any).vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/\bwindows phone\b/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/\bandroid\b/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/\biPad|iPhone|iPod\b/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }

  if (/\bBlackBerry\b/i.test(userAgent)) {
    return "BlackBerry";
  }

  return "unknown";
}

/**
 * Is this an installed Progressive Web App (PWA)?
 */
export function isPWA(): boolean {
  if (typeof navigator === "undefined" || typeof window === "undefined") {
    return false;
  }

  if ((navigator as any).standalone) {
    return true;
  }

  const matchMedia: any = (window as any).matchMedia;
  if (matchMedia) {
    return matchMedia("(display-mode: standalone");
  }

  return false;
}

export function getMapUrl(location: Location): string | null {
  if (!location.latitude || !location.longitude) {
    return null;
  }

  const mobileOS = getMobileOS();

  // There's no consistent way of handling map links across mobile operating
  // systems, so different links need to be created per-OS
  if (mobileOS === "Windows Phone") {
    // Use Windows Maps app
    return `maps:${location.latitude},${location.longitude}`;
  } else if (mobileOS === "Android") {
    // Use Google Maps app
    return `geo:0,0?q=${location.latitude},${location.longitude}`;
  } else if (mobileOS === "iOS") {
    // Use Apple Maps app
    return `https://maps.apple.com/?q=${location.latitude},${location.longitude}`;
  } else if (mobileOS === "BlackBerry") {
    // Use whatever the mapp app is on BlackBerry (if people still use those)
    const mapArgs = {
      latitude: location.latitude,
      longitude: location.longitude,
    };
    return `javascript:blackberry.launch.newMap(${JSON.stringify(mapArgs)})`;
  }

  // Use Google Maps web site
  return `https://maps.google.com/?q=${location.latitude},${location.longitude}`;
}

export function suffixWithAppName(title?: string | null): string {
  if (!title) {
    return APP_NAME;
  }

  if (isPWA()) {
    return title;
  }

  return `${title} | ${APP_NAME}`;
}
